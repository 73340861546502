// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import supabase from '../../supabaseClient';
// import SearchableSelect from '../../Components/SearchableSelect';

// import { COUNTRIES, MAJOR_CITIES, INDUSTRIES } from '../../constants';

// import {
//     Building,
//     Search,
//     Users,
//     CheckCircle2,
//     X,
//     Check,
//     AlertCircle,
// } from 'lucide-react';

// // Pricing Module Component
// const PricingModule = ({ onSelectPlan }) => (
//     <div className="bg-white rounded-lg p-8 max-w-4xl w-full">
//         <div className="text-center mb-12">
//             <h2 className="text-3xl font-bold text-gray-900">Choose Your Plan</h2>
//             <p className="mt-4 text-lg text-gray-600">
//                 Start with a free trial or upgrade to unlock full features
//             </p>
//         </div>

//         <div className="grid md:grid-cols-3 gap-8">
//             {/* Trial Plan */}
//             <div className="border-2 border-gray-200 rounded-lg p-8 hover:border-blue-500 transition-all">
//                 <div className="text-center">
//                     <h3 className="text-xl font-semibold mb-2">Trial</h3>
//                     <div className="text-4xl font-bold mb-4">Free</div>
//                     <p className="text-gray-600 mb-6">Perfect for trying out Resumeably</p>
//                 </div>

//                 <ul className="space-y-4 mb-8">
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>1 resume parse per recruiter</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Basic parsing features</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Email support</span>
//                     </li>
//                 </ul>

//                 <button
//                     onClick={() => onSelectPlan('trial')}
//                     className="w-full py-2 px-4 border-2 border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
//                 >
//                     Start Trial
//                 </button>
//             </div>

//             {/* Pro Plan */}
//             <div className="border-2 border-blue-500 rounded-lg p-8 bg-blue-50 relative">
//                 <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
//                     <span className="bg-blue-500 text-white text-xs px-3 py-1 rounded-full">
//                         RECOMMENDED
//                     </span>
//                 </div>
//                 <div className="text-center">
//                     <h3 className="text-xl font-semibold mb-2">Pro</h3>
//                     <div className="text-4xl font-bold mb-4">$99</div>
//                     <p className="text-gray-600 mb-6">Per recruiter / month</p>
//                 </div>

//                 <ul className="space-y-4 mb-8">
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>500 resumes per month</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Advanced parsing features</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Priority support</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>API Access</span>
//                     </li>
//                 </ul>

//                 <button
//                     onClick={() => onSelectPlan('pro')}
//                     className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
//                 >
//                     Upgrade to Pro
//                 </button>
//             </div>

//             {/* Enterprise Plan */}
//             <div className="border-2 border-gray-200 rounded-lg p-8 hover:border-blue-500 transition-all">
//                 <div className="text-center">
//                     <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
//                     <div className="text-4xl font-bold mb-4">Custom</div>
//                     <p className="text-gray-600 mb-6">For larger organizations</p>
//                 </div>

//                 <ul className="space-y-4 mb-8">
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Unlimited resumes</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Custom features</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Dedicated support</span>
//                     </li>
//                     <li className="flex items-center">
//                         <Check className="w-5 h-5 text-green-500 mr-2" />
//                         <span>Custom integrations</span>
//                     </li>
//                 </ul>

//                 <button
//                     onClick={() => onSelectPlan('enterprise')}
//                     className="w-full py-2 px-4 border-2 border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
//                 >
//                     Contact Sales
//                 </button>
//             </div>
//         </div>

//         <div className="mt-8 bg-amber-50 border border-amber-200 rounded-lg p-4 flex items-start gap-3">
//             <AlertCircle className="w-5 h-5 text-amber-500 mt-0.5" />
//             <div className="text-sm text-amber-800">
//                 <strong>Note:</strong> Trial accounts are limited to 1 resume parse per recruiter.
//                 Join an agency or upgrade to a paid plan for unlimited access to all features.
//             </div>
//         </div>
//     </div>
// );

// const RecruiterOnboarding = () => {
//     const navigate = useNavigate();
//     const [step, setStep] = useState(1);
//     const [selectedOption, setSelectedOption] = useState(null);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [existingAgencies, setExistingAgencies] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const [selectedPlan, setSelectedPlan] = useState(null);
//     const [newAgencyData, setNewAgencyData] = useState({
//         name: '',
//         website: '',
//         size: 'small',
//         industry: '',
//         country: '',
//         city: '',
//         email: ''
//     });

//     useEffect(() => {
//         const checkAuth = async () => {
//             const { data: { session } } = await supabase.auth.getSession();
//             if (!session) {
//                 navigate('/', { replace: true });
//             }
//         };
//         checkAuth();
//     }, [navigate]);

//     useEffect(() => {
//         if (searchTerm.length >= 2) {
//             fetchAgencies();
//         } else {
//             setExistingAgencies([]);
//         }
//     }, [searchTerm]);

//     const fetchAgencies = async () => {
//         try {
//             const { data, error } = await supabase
//                 .from('agencies')
//                 .select('*')
//                 .ilike('name', `%${searchTerm}%`)
//                 .limit(5);

//             if (error) throw error;
//             setExistingAgencies(data || []);
//         } catch (err) {
//             console.error('Error fetching agencies:', err);
//             setError('Failed to load agencies. Please try again.');
//         }
//     };

//     const handlePathSelection = (path) => {
//         setSelectedOption(path);
//         if (path === 'join') {
//             setStep(2);
//         } else {
//             setStep(3);
//         }
//     };

//     const handlePlanSelection = (plan) => {
//         setSelectedPlan(plan);
//         setStep(4);
//     };

//     const handleCreateAgency = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError('');

//         try {
//             if (!selectedPlan) {
//                 throw new Error('Please select a plan first');
//             }

//             const apiKey = 'api_' + Math.random().toString(36).substr(2, 9);

//             const { data: agency, error: agencyError } = await supabase
//                 .from('agencies')
//                 .insert([{
//                     ...newAgencyData,
//                     api_key: apiKey,
//                     subscription_status: selectedPlan,
//                     trial_end_date: selectedPlan === 'trial'
//                         ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
//                         : null,
//                     resume_limit: selectedPlan === 'trial' ? 1 : null
//                 }])
//                 .select()
//                 .single();

//             if (agencyError) throw agencyError;

//             const { data: { user } } = await supabase.auth.getUser();
//             if (!user) throw new Error('No authenticated user found');

//             const { error: memberError } = await supabase
//                 .from('agency_members')
//                 .insert([{
//                     agency_id: agency.id,
//                     user_id: user.id,
//                     role: 'admin',
//                     status: 'active'
//                 }]);

//             if (memberError) throw memberError;

//             localStorage.setItem('has_agency', 'true');
//             navigate('/dashboard');

//         } catch (err) {
//             console.error('Error creating agency:', err);
//             setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleJoinAgency = async (agencyId) => {
//         setLoading(true);
//         setError('');

//         try {
//             const { data: { user } } = await supabase.auth.getUser();
//             if (!user) throw new Error('No authenticated user found');

//             const { error: memberError } = await supabase
//                 .from('agency_members')
//                 .insert([{
//                     agency_id: agencyId,
//                     user_id: user.id,
//                     role: 'member',
//                     status: 'pending'
//                 }]);

//             if (memberError) throw memberError;

//             localStorage.setItem('has_agency', 'true');
//             navigate('/dashboard');

//         } catch (err) {
//             console.error('Error joining agency:', err);
//             setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const goBack = () => setStep(prev => prev - 1);

//     const renderProgressBar = () => (
//         <div className="mb-8">
//             <div className="flex justify-between mb-2">
//                 {selectedOption === 'join'
//                     ? ['Choose Path', 'Join Agency', 'Complete'].map((label, index) => (
//                         <div
//                             key={index}
//                             className={`text-sm ${step > index ? 'text-blue-600' : 'text-gray-400'}`}
//                         >
//                             {label}
//                         </div>
//                     ))
//                     : ['Choose Path', 'Select Plan', 'Setup Agency', 'Complete'].map((label, index) => (
//                         <div
//                             key={index}
//                             className={`text-sm ${step > index ? 'text-blue-600' : 'text-gray-400'}`}
//                         >
//                             {label}
//                         </div>
//                     ))
//                 }
//             </div>
//             <div className="h-2 bg-gray-200 rounded-full">
//                 <div
//                     className="h-2 bg-blue-600 rounded-full transition-all duration-300"
//                     style={{
//                         width: `${(step / (selectedOption === 'join' ? 3 : 4)) * 100}%`
//                     }}
//                 />
//             </div>
//         </div>
//     );

//     const renderStep1 = () => (
//         <div className="space-y-6">
//             <h2 className="text-2xl font-bold text-center mb-8">Welcome to Resumeably!</h2>
//             <div className="grid md:grid-cols-2 gap-4">
//                 <button
//                     onClick={() => handlePathSelection('join')}
//                     className={`p-6 border-2 rounded-lg text-left space-y-4 hover:border-blue-500 transition-colors
//                         ${selectedOption === 'join' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}
//                 >
//                     <Users className="w-8 h-8 text-blue-600" />
//                     <h3 className="text-lg font-semibold">Join Existing Agency</h3>
//                     <p className="text-gray-600">Connect with your team and start collaborating on resumes</p>
//                 </button>

//                 <button
//                     onClick={() => handlePathSelection('create')}
//                     className={`p-6 border-2 rounded-lg text-left space-y-4 hover:border-blue-500 transition-colors
//                         ${selectedOption === 'create' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}
//                 >
//                     <Building className="w-8 h-8 text-blue-600" />
//                     <h3 className="text-lg font-semibold">Create New Agency</h3>
//                     <p className="text-gray-600">Set up your organization and invite team members</p>
//                 </button>
//             </div>
//         </div>
//     );

//     const renderJoinAgency = () => (
//         <div className="space-y-6">
//             <div className="flex items-center justify-between">
//                 <h2 className="text-2xl font-bold">Join an Agency</h2>
//                 <button
//                     onClick={goBack}
//                     className="text-gray-500 hover:text-gray-700"
//                 >
//                     <X className="w-6 h-6" />
//                 </button>
//             </div>

//             <div className="relative">
//                 <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//                 <input
//                     type="text"
//                     placeholder="Search for your agency..."
//                     // Continue from the input in renderJoinAgency...
//                     className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//             </div>

//             <div className="space-y-4">
//                 {existingAgencies.map((agency) => (
//                     <div
//                         key={agency.id}
//                         className="p-4 border rounded-lg hover:border-blue-500 transition-colors"
//                     >
//                         <div className="flex justify-between items-center">
//                             <div>
//                                 <h3 className="font-semibold">{agency.name}</h3>
//                                 <p className="text-sm text-gray-600">{agency.website || 'No website provided'}</p>
//                                 <p className="text-sm text-gray-600">{agency.city}, {agency.country}</p>
//                             </div>
//                             <button
//                                 onClick={() => handleJoinAgency(agency.id)}
//                                 className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Joining...' : 'Join'}
//                             </button>
//                         </div>
//                     </div>
//                 ))}
//                 {searchTerm.length >= 2 && existingAgencies.length === 0 && (
//                     <div className="text-center py-8 text-gray-600">
//                         <p>No agencies found matching "{searchTerm}"</p>
//                         <button
//                             onClick={() => handlePathSelection('create')}
//                             className="mt-4 text-blue-600 hover:underline"
//                         >
//                             Create a new agency instead?
//                         </button>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
//     const renderCreateAgency = () => (
//         <div className="space-y-6">
//             <div className="flex items-center justify-between">
//                 <h2 className="text-2xl font-bold">Create New Agency</h2>
//                 <button
//                     onClick={goBack}
//                     className="text-gray-500 hover:text-gray-700"
//                 >
//                     <X className="w-6 h-6" />
//                 </button>
//             </div>

//             <form onSubmit={handleCreateAgency} className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">
//                             Agency Name*
//                         </label>
//                         <input
//                             type="text"
//                             required
//                             className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//                             value={newAgencyData.name}
//                             onChange={(e) => setNewAgencyData(prev => ({
//                                 ...prev,
//                                 name: e.target.value
//                             }))}
//                         />
//                     </div>

//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">
//                             Website
//                         </label>
//                         <input
//                             type="url"
//                             className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//                             value={newAgencyData.website}
//                             onChange={(e) => setNewAgencyData(prev => ({
//                                 ...prev,
//                                 website: e.target.value
//                             }))}
//                             placeholder="https://"
//                         />
//                     </div>

//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">
//                             Agency Size
//                         </label>
//                         <select
//                             className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//                             value={newAgencyData.size}
//                             onChange={(e) => setNewAgencyData(prev => ({
//                                 ...prev,
//                                 size: e.target.value
//                             }))}
//                         >
//                             <option value="small">1-10 employees</option>
//                             <option value="medium">11-50 employees</option>
//                             <option value="large">51-200 employees</option>
//                             <option value="enterprise">201+ employees</option>
//                         </select>
//                     </div>

//                     <SearchableSelect
//                         label="Industry Focus"
//                         options={INDUSTRIES}
//                         value={newAgencyData.industry ? {
//                             value: newAgencyData.industry,
//                             label: INDUSTRIES.find(i => i.value === newAgencyData.industry)?.label || newAgencyData.industry
//                         } : null}
//                         onChange={(selected) => setNewAgencyData(prev => ({
//                             ...prev,
//                             industry: selected.value
//                         }))}
//                         placeholder="Select or type industry..."
//                         allowCustom={true}
//                     />

//                     <SearchableSelect
//                         label="Country"
//                         options={COUNTRIES}
//                         value={newAgencyData.country ? {
//                             value: newAgencyData.country,
//                             label: COUNTRIES.find(c => c.value === newAgencyData.country)?.label || newAgencyData.country
//                         } : null}
//                         onChange={(selected) => {
//                             setNewAgencyData(prev => ({
//                                 ...prev,
//                                 country: selected.value,
//                                 city: '' // Reset city when country changes
//                             }));
//                         }}
//                         placeholder="Select country..."
//                         allowCustom={true}
//                     />

//                     <SearchableSelect
//                         label="City"
//                         options={newAgencyData.country && MAJOR_CITIES[newAgencyData.country]
//                             ? MAJOR_CITIES[newAgencyData.country].map(city => ({
//                                 value: city,
//                                 label: city
//                             }))
//                             : []
//                         }
//                         value={newAgencyData.city ? {
//                             value: newAgencyData.city,
//                             label: newAgencyData.city
//                         } : null}
//                         onChange={(selected) => setNewAgencyData(prev => ({
//                             ...prev,
//                             city: selected.value
//                         }))}
//                         placeholder="Select or type city..."
//                         allowCustom={true}
//                     />
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700 mb-1">
//                         Agency Email*
//                     </label>
//                     <input
//                         type="email"
//                         required
//                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
//                         value={newAgencyData.email}
//                         onChange={(e) => setNewAgencyData(prev => ({
//                             ...prev,
//                             email: e.target.value
//                         }))}
//                     />
//                 </div>

//                 {error && (
//                     <div className="text-red-600 text-sm bg-red-50 border border-red-200 rounded-lg p-3">
//                         {error}
//                     </div>
//                 )}

//                 <div className="pt-4">
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
//                     >
//                         {loading ? 'Creating Agency...' : 'Create Agency'}
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );

//     const renderContent = () => {
//         switch (step) {
//             case 1:
//                 return renderStep1();
//             case 2:
//                 return selectedOption === 'join' ? renderJoinAgency() : renderStep1();
//             case 3:
//                 return selectedOption === 'create' ? <PricingModule onSelectPlan={handlePlanSelection} /> : renderJoinAgency();
//             case 4:
//                 return renderCreateAgency();
//             default:
//                 return renderStep1();
//         }
//     };

//     return (
//         <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
//             <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full p-8">
//                 {renderProgressBar()}
//                 {renderContent()}
//             </div>
//         </div>
//     );
// };

// export default RecruiterOnboarding;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../../supabaseClient';
import SearchableSelect from '../../Components/SearchableSelect';
import { COUNTRIES, MAJOR_CITIES, INDUSTRIES } from '../../constants';
import {
    Building,
    Search,
    Users,
    CheckCircle2,
    X,
    Check,
    AlertCircle,
    Copy,
    CheckCircle
} from 'lucide-react';
import { v5 as uuidv5 } from 'uuid';

// API Key Modal Component
const ApiKeyModal = ({ isOpen, onClose, apiKey }) => {
    const navigate = useNavigate(); // Add this line
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(apiKey);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose}></div>

                <div className="relative bg-white rounded-lg p-8 max-w-lg w-full">
                    <h3 className="text-2xl font-bold mb-6">Your Agency API Key</h3>

                    <div className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                API Key
                            </label>
                            <div className="flex">
                                <input
                                    type="text"
                                    readOnly
                                    value={apiKey}
                                    className="flex-1 px-3 py-2 border rounded-l-lg bg-gray-50"
                                />
                                <button
                                    onClick={handleCopy}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-r-lg hover:bg-blue-700 flex items-center gap-2"
                                >
                                    {copied ? (
                                        <>
                                            <CheckCircle className="w-4 h-4" />
                                            Copied!
                                        </>
                                    ) : (
                                        <>
                                            <Copy className="w-4 h-4" />
                                            Copy
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="bg-yellow-50 p-4 rounded-lg">
                            <p className="text-sm text-yellow-800">
                                <strong>Important:</strong> Save this API key in a secure location.
                                You'll need it to make API requests and share it with your team members
                                who need to access the API.
                            </p>
                        </div>

                        <button
                            onClick={() => {
                                onClose();
                                navigate('/dashboard');
                            }}
                            className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                            Got it, take me to dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Pricing Module Component
const PricingModule = ({ onSelectPlan }) => (
    <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900">Choose Your Plan</h2>
                <p className="mt-4 text-lg text-gray-600">
                    Start with a free trial or upgrade to unlock full features
                </p>
            </div>

            <div className="grid md:grid-cols-4 gap-8">
                {/* Trial Plan */}
                <div className="border rounded-lg p-8 hover:border-blue-500 transition-all">
                    <h3 className="text-xl font-semibold mb-4">Trial</h3>
                    <p className="text-4xl font-bold mb-8">Free</p>
                    <ul className="space-y-4 mb-8">
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>1 resume parse</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Basic parsing features</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Email support</span>
                        </li>
                    </ul>
                    <button
                        onClick={() => onSelectPlan('trial')}
                        className="w-full py-2 px-4 border border-blue-600 text-blue-600 rounded hover:bg-blue-50 transition-colors"
                    >
                        Start Trial
                    </button>
                </div>

                {/* Starter Plan */}
                <div className="border rounded-lg p-8 hover:border-blue-500 transition-all">
                    <h3 className="text-xl font-semibold mb-4">Starter</h3>
                    <p className="text-4xl font-bold mb-8">$159</p>
                    <ul className="space-y-4 mb-8">
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>300 resumes/month</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>5 team members</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Email support</span>
                        </li>
                    </ul>
                    <button
                        onClick={() => onSelectPlan('starter')}
                        className="w-full py-2 px-4 border border-blue-600 text-blue-600 rounded hover:bg-blue-50 transition-colors"
                    >
                        Get Started
                    </button>
                </div>

                {/* Pro Plan */}
                <div className="border-2 border-blue-500 rounded-lg p-8 bg-blue-50 relative">
                    <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-2">
                        <span className="bg-blue-500 text-white text-xs px-3 py-1 rounded-full">
                            RECOMMENDED
                        </span>
                    </div>
                    <h3 className="text-xl font-semibold mb-4">Pro</h3>
                    <p className="text-4xl font-bold mb-8">$499</p>
                    <ul className="space-y-4 mb-8">
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>1500 resumes/month</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>10 team members</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Priority support</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>API Access</span>
                        </li>
                    </ul>
                    <button
                        onClick={() => onSelectPlan('pro')}
                        className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                    >
                        Upgrade to Pro
                    </button>
                </div>

                {/* Enterprise Plan */}
                <div className="border rounded-lg p-8 hover:border-blue-500 transition-all">
                    <h3 className="text-xl font-semibold mb-4">Enterprise</h3>
                    <p className="text-4xl font-bold mb-8">Custom</p>
                    <ul className="space-y-4 mb-8">
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Unlimited resumes</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Custom features</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Dedicated support</span>
                        </li>
                        <li className="flex items-center">
                            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                            <span>Custom integrations</span>
                        </li>
                    </ul>
                    <button
                        onClick={() => onSelectPlan('enterprise')}
                        className="w-full py-2 px-4 border border-blue-600 text-blue-600 rounded hover:bg-blue-50 transition-colors"
                    >
                        Contact Sales
                    </button>
                </div>
            </div>

            <div className="mt-8 bg-amber-50 border border-amber-200 rounded-lg p-4 flex items-start gap-3">
                <AlertCircle className="w-5 h-5 text-amber-500 mt-0.5" />
                <div className="text-sm text-amber-800">
                    <strong>Note:</strong> Trial accounts are limited to 1 resume parse per recruiter.
                    Join an agency or upgrade to a paid plan for unlimited ai access to all features.
                </div>
            </div>
        </div>
    </section>
);


const RecruiterOnboarding = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [existingAgencies, setExistingAgencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showApiModal, setShowApiModal] = useState(false);
    const [apiKey, setApiKey] = useState(null);
    const [apiKeyInput, setApiKeyInput] = useState('');
    const [verifying, setVerifying] = useState(false);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [adminInfo, setAdminInfo] = useState(null);

    const [newAgencyData, setNewAgencyData] = useState({
        name: '',
        website: '',
        size: 'small',
        industry: '',
        country: '',
        city: '',
        email: '',
        first_name: '',  // Add this
        last_name: ''    // Add this
    });

    // Auth check on mount
    useEffect(() => {
        const checkAuth = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                navigate('/', { replace: true });
            }
        };
        checkAuth();
    }, [navigate]);

    useEffect(() => {
        const fetchAdminInfo = async () => {
            try {
                // Fetch the admin info for the selected agency
                const { data: admin, error } = await supabase
                    .from('agency_members')
                    .select(`
                        first_name,
                        last_name,
                        auth_user:user_id (
                            email
                        )
                    `)
                    .eq('agency_id', selectedAgency.id)
                    .eq('role', 'admin')
                    .single();

                if (error) throw error;

                setAdminInfo({
                    name: `${admin.first_name} ${admin.last_name}`,
                    email: admin.auth_user.email
                });
            } catch (err) {
                console.error('Error fetching admin info:', err);
                setError('Could not fetch admin information');
            }
        };

        if (selectedAgency?.id) {
            fetchAdminInfo();
        }
    }, [selectedAgency?.id]);
    // Agency search effect
    useEffect(() => {
        if (searchTerm.length >= 2) {
            fetchAgencies();
        } else {
            setExistingAgencies([]);
        }
    }, [searchTerm]);

    // Fetch agencies method
    const fetchAgencies = async () => {
        try {
            const { data, error } = await supabase
                .from('agencies')
                .select('*')
                .ilike('name', `%${searchTerm}%`)
                .limit(5);

            if (error) throw error;
            setExistingAgencies(data || []);
        } catch (err) {
            console.error('Error fetching agencies:', err);
            setError('Failed to load agencies. Please try again.');
        }
    };

    // Handle step changes
    const handlePathSelection = (path) => {
        setSelectedOption(path);
        if (path === 'join') {
            setStep(2);
        } else {
            setStep(3);
        }
    };

    const handlePlanSelection = (plan) => {
        setSelectedPlan(plan);
        setStep(4);
    };

    // Handle agency creation
    const handleCreateAgency = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            if (!selectedPlan) {
                throw new Error('Please select a plan first');
            }

            const { data: { user } } = await supabase.auth.getUser();
            if (!user) throw new Error('No authenticated user found');

            // Generate API key
            const apiKey = 'rbl_' + Math.random().toString(36).substr(2, 24);

            // Create agency
            const { data: agency, error: agencyError } = await supabase
                .from('agencies')
                .insert([{
                    ...newAgencyData,
                    api_key: apiKey,
                    subscription_status: selectedPlan,
                    trial_end_date: selectedPlan === 'trial'
                        ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
                        : null,
                    resume_limit: selectedPlan === 'trial' ? 1 : null
                }])
                .select()
                .single();

            if (agencyError) throw agencyError;

            // Create agency member record
            const { error: memberError } = await supabase
                .from('agency_members')
                .insert([{
                    agency_id: agency.id,
                    user_id: user.id,
                    role: 'admin',
                    status: 'active',
                    first_name: newAgencyData.first_name,
                    last_name: newAgencyData.last_name
                }]);

            if (memberError) throw memberError;

            // Initialize usage tracking
            const { error: usageError } = await supabase
                .from('agency_usage')
                .insert([{
                    agency_id: agency.id,
                    total_resumes: 0,
                    monthly_parsed: 0
                }]);

            if (usageError) throw usageError;

            // Show API key modal
            setApiKey(apiKey);
            setShowApiModal(true);
            localStorage.setItem('has_agency', 'true');
            localStorage.setItem('agency_role', 'admin');

        } catch (err) {
            console.error('Error creating agency:', err);
            setError(err.message);
            setLoading(false);
        }
    };

    // Handle joining existing agency


    const goBack = () => setStep(prev => prev - 1);

    const verifyApiKey = async (agencyId, apiKey) => {
        setVerifying(true);
        setError('');

        try {
            // Verify API key matches the agency
            const { data: agency, error: verifyError } = await supabase
                .from('agencies')
                .select('*')
                .eq('id', agencyId)
                .eq('api_key', apiKey)
                .single();

            if (verifyError || !agency) {
                throw new Error('Invalid API key');
            }

            // Fetch admin information
            const { data: admin, error: adminError } = await supabase
                .from('agency_members')
                .select(`
                    first_name,
                    last_name,
                    user_id,
                    auth.users!inner(email)
                `)
                .eq('agency_id', agencyId)
                .eq('role', 'admin')
                .single();

            if (adminError) throw new Error('Could not fetch admin information');

            setAdminInfo({
                name: `${admin.first_name} ${admin.last_name}`,
                email: admin.users.email
            });

            return true;
        } catch (err) {
            console.error('Error verifying API key:', err);
            setError(err.message);
            return false;
        } finally {
            setVerifying(false);
        }
    };

    // Modify handleJoinAgency to include verification
    const handleJoinAgency = async (agency) => {
        if (!newAgencyData.first_name || !newAgencyData.last_name) {
            setError('Please enter your first and last name');
            return;
        }

        setSelectedAgency(agency);
        setStep(2.5); // New step for API key verification
    };

    const handleUUIDValidation = () => {
        if (!apiKeyInput.trim()) {
            setError('API Key cannot be empty.');
            return;
        }

        const isValidUUID = (input) => {
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            return uuidRegex.test(input);
        };

        if (!isValidUUID(apiKeyInput)) {
            setError('Invalid API Key format.');
        } else {
            setError(null); // Clear error if input is valid
            // Proceed with backend submission
            console.log('Valid API Key:', apiKeyInput);
        }
    };


    // Add new method for final join submission
    const handleFinalJoin = async () => {
        if (!selectedAgency?.id) {
            setError('No agency selected. Please go back and select an agency.');
            return;
        }

        if (!apiKeyInput || apiKeyInput.trim() === '') {
            setError('Please enter the API key');
            return;
        }

        setVerifying(true);
        setError(null); // Clear previous errors

        try {
            console.log('Selected Agency ID:', selectedAgency.id);
            console.log('API Key Input:', apiKeyInput);

            // Verify the API key matches the selected agency
            const { data: agency, error: agencyError } = await supabase
                .from('agencies')
                .select('id, name, api_key')
                .eq('id', selectedAgency.id)
                .eq('api_key', apiKeyInput)
                .maybeSingle(); // Allows 0 rows without throwing an error

            if (agencyError) {
                console.error('Agency verification error:', agencyError);
                setError('Failed to verify the agency. Please try again.');
                return;
            }

            if (!agency) {
                setError('Invalid API Key for the selected agency.');
                return;
            }

            console.log('Agency verification successful:', agency);

            // Get the authenticated user
            const { data: userSession, error: userError } = await supabase.auth.getUser();
            if (userError || !userSession) {
                throw new Error('No authenticated user found');
            }

            console.log('Authenticated User:', userSession.user);

            // Insert the user into the agency members table
            const { error: memberError } = await supabase
                .from('agency_members')
                .insert([{
                    agency_id: selectedAgency.id,
                    user_id: userSession.user.id,
                    role: 'member',
                    status: 'pending',
                }]);

            if (memberError) {
                console.error('Error adding member to agency:', memberError);
                throw memberError;
            }

            console.log('User successfully added to agency members.');

            // Store agency information locally if needed
            localStorage.setItem('has_agency', 'true');
            localStorage.setItem('agency_role', 'member');

            // Navigate to dashboard or success screen
            navigate('/dashboard');
        } catch (err) {
            console.error('Error joining agency:', err);
            setError(err.message || 'Failed to join the agency. Please try again.');
        } finally {
            setVerifying(false);
        }
    };




    const renderApiKeyVerification = () => (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold">Join {selectedAgency.name}</h2>
                <button
                    onClick={() => setStep(2)}
                    className="text-gray-500 hover:text-gray-700"
                >
                    <X className="w-6 h-6" />
                </button>
            </div>

            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                <p className="text-sm text-blue-800">
                    To join this agency, please enter the API key provided by your agency admin.
                    Contact them if you don't have this information.
                </p>
            </div>

            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Agency API Key*
                    </label>
                    <input
                        type="text"
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        value={apiKeyInput}
                        onChange={(e) => setApiKeyInput(e.target.value)}
                        placeholder="Enter API key"
                    />
                    {error && <div className="text-red-600 text-sm mt-2">{error}</div>}
                </div>

                {adminInfo && (
                    <div className="bg-gray-50 border border-gray-200 rounded-lg p-4">
                        <h3 className="font-medium text-gray-900 mb-2">Agency Admin Information</h3>
                        <p className="text-sm text-gray-600">Name: {adminInfo.name}</p>
                        <p className="text-sm text-gray-600">Email: {adminInfo.email}</p>
                    </div>
                )}

                <button
                    onClick={handleFinalJoin}
                    disabled={verifying || loading}
                    className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                >
                    {verifying ? 'Verifying...' : loading ? 'Joining...' : 'Join Agency'}
                </button>
            </div>
        </div>
    );

    // Progress bar render method
    const renderProgressBar = () => (
        <div className="mb-8">
            <div className="flex justify-between mb-2">
                {selectedOption === 'join'
                    ? ['Choose Path', 'Join Agency', 'Complete'].map((label, index) => (
                        <div
                            key={index}
                            className={`text-sm ${step > index ? 'text-blue-600' : 'text-gray-400'}`}
                        >
                            {label}
                        </div>
                    ))
                    : ['Choose Path', 'Select Plan', 'Setup Agency', 'Complete'].map((label, index) => (
                        <div
                            key={index}
                            className={`text-sm ${step > index ? 'text-blue-600' : 'text-gray-400'}`}
                        >
                            {label}
                        </div>
                    ))
                }
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
                <div
                    className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                    style={{
                        width: `${(step / (selectedOption === 'join' ? 3 : 4)) * 100}%`
                    }}
                />
            </div>
        </div>
    );

    // Step 1 render method
    const renderStep1 = () => (
        <div className="space-y-6">
            <h2 className="text-2xl font-bold text-center mb-8">Welcome to Resumeably!</h2>
            <div className="grid md:grid-cols-2 gap-4">
                <button
                    onClick={() => handlePathSelection('join')}
                    className={`p-6 border-2 rounded-lg text-left space-y-4 hover:border-blue-500 transition-colors
                        ${selectedOption === 'join' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}
                >
                    <Users className="w-8 h-8 text-blue-600" />
                    <h3 className="text-lg font-semibold">Join Existing Agency</h3>
                    <p className="text-gray-600">Connect with your team and start collaborating on resumes</p>
                </button>

                <button
                    onClick={() => handlePathSelection('create')}
                    className={`p-6 border-2 rounded-lg text-left space-y-4 hover:border-blue-500 transition-colors
                        ${selectedOption === 'create' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}
                >
                    <Building className="w-8 h-8 text-blue-600" />
                    <h3 className="text-lg font-semibold">Create New Agency</h3>
                    <p className="text-gray-600">Set up your organization and invite team members</p>
                </button>
            </div>
        </div>
    );

    // Join agency render method
    // Update the renderJoinAgency method to include name fields
    const renderJoinAgency = () => (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold">Join an Agency</h2>
                <button
                    onClick={goBack}
                    className="text-gray-500 hover:text-gray-700"
                >
                    <X className="w-6 h-6" />
                </button>
            </div>

            {/* Add name fields here */}
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        First Name*
                    </label>
                    <input
                        type="text"
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        value={newAgencyData.first_name}
                        onChange={(e) => setNewAgencyData(prev => ({
                            ...prev,
                            first_name: e.target.value
                        }))}
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Last Name*
                    </label>
                    <input
                        type="text"
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        value={newAgencyData.last_name}
                        onChange={(e) => setNewAgencyData(prev => ({
                            ...prev,
                            last_name: e.target.value
                        }))}
                    />
                </div>
            </div>

            {/* Error message */}
            {error && (
                <div className="text-red-600 text-sm bg-red-50 border border-red-200 rounded-lg p-3">
                    {error}
                </div>
            )}

            <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                    type="text"
                    placeholder="Search for your agency..."
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {/* Rest of the existing code remains the same */}
            <div className="space-y-4">
                {existingAgencies.map((agency) => (
                    <div
                        key={agency.id}
                        className="p-4 border rounded-lg hover:border-blue-500 transition-colors"
                    >
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="font-semibold">{agency.name}</h3>
                                <p className="text-sm text-gray-600">{agency.website || 'No website provided'}</p>
                                <p className="text-sm text-gray-600">{agency.city}, {agency.country}</p>
                            </div>
                            <button
                                onClick={() => handleJoinAgency(agency.id)}
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                                disabled={loading}
                            >
                                {loading ? 'Joining...' : 'Join'}
                            </button>
                        </div>
                    </div>
                ))}
                {searchTerm.length >= 2 && existingAgencies.length === 0 && (
                    <div className="text-center py-8 text-gray-600">
                        <p>No agencies found matching "{searchTerm}"</p>
                        <button
                            onClick={() => handlePathSelection('create')}
                            className="mt-4 text-blue-600 hover:underline"
                        >
                            Create a new agency instead?
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    // Create agency render method
    const renderCreateAgency = () => (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold">Create New Agency</h2>
                <button
                    onClick={goBack}
                    className="text-gray-500 hover:text-gray-700"
                >
                    <X className="w-6 h-6" />
                </button>
            </div>

            <form onSubmit={handleCreateAgency} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Agency Name*
                        </label>
                        <input
                            type="text"
                            required
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                            value={newAgencyData.name}
                            onChange={(e) => setNewAgencyData(prev => ({
                                ...prev,
                                name: e.target.value
                            }))}
                        />
                    </div>
                    <div className="col-span-2 grid grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                First Name*
                            </label>
                            <input
                                type="text"
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                value={newAgencyData.first_name}
                                onChange={(e) => setNewAgencyData(prev => ({
                                    ...prev,
                                    first_name: e.target.value
                                }))}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Last Name*
                            </label>
                            <input
                                type="text"
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                value={newAgencyData.last_name}
                                onChange={(e) => setNewAgencyData(prev => ({
                                    ...prev,
                                    last_name: e.target.value
                                }))}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Website
                        </label>
                        <input
                            type="url"
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                            value={newAgencyData.website}
                            onChange={(e) => setNewAgencyData(prev => ({
                                ...prev,
                                website: e.target.value
                            }))}
                            placeholder="https://"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Agency Size
                        </label>
                        <select
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                            value={newAgencyData.size}
                            onChange={(e) => setNewAgencyData(prev => ({
                                ...prev,
                                size: e.target.value
                            }))}
                        >
                            <option value="small">1-10 employees</option>
                            <option value="medium">11-50 employees</option>
                            <option value="large">51-200 employees</option>
                            <option value="enterprise">201+ employees</option>
                        </select>
                    </div>

                    <SearchableSelect
                        label="Industry Focus"
                        options={INDUSTRIES}
                        value={newAgencyData.industry ? {
                            value: newAgencyData.industry,
                            label: INDUSTRIES.find(i => i.value === newAgencyData.industry)?.label || newAgencyData.industry
                        } : null}
                        onChange={(selected) => setNewAgencyData(prev => ({
                            ...prev,
                            industry: selected.value
                        }))}
                        placeholder="Select or type industry..."
                        allowCustom={true}
                    />

                    <SearchableSelect
                        label="Country"
                        options={COUNTRIES}
                        value={newAgencyData.country ? {
                            value: newAgencyData.country,
                            label: COUNTRIES.find(c => c.value === newAgencyData.country)?.label || newAgencyData.country
                        } : null}
                        onChange={(selected) => {
                            setNewAgencyData(prev => ({
                                ...prev,
                                country: selected.value,
                                city: '' // Reset city when country changes
                            }));
                        }}
                        placeholder="Select country..."
                        allowCustom={true}
                    />

                    <SearchableSelect
                        label="City"
                        options={newAgencyData.country && MAJOR_CITIES[newAgencyData.country]
                            ? MAJOR_CITIES[newAgencyData.country].map(city => ({
                                value: city,
                                label: city
                            }))
                            : []
                        }
                        value={newAgencyData.city ? {
                            value: newAgencyData.city,
                            label: newAgencyData.city
                        } : null}
                        onChange={(selected) => setNewAgencyData(prev => ({
                            ...prev,
                            city: selected.value
                        }))}
                        placeholder="Select or type city..."
                        allowCustom={true}
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Agency Email*
                    </label>
                    <input
                        type="email"
                        required
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        value={newAgencyData.email}
                        onChange={(e) => setNewAgencyData(prev => ({
                            ...prev,
                            email: e.target.value
                        }))}
                    />
                </div>

                {error && (
                    <div className="text-red-600 text-sm bg-red-50 border border-red-200 rounded-lg p-3">
                        {error}
                    </div>
                )}

                <div className="pt-4">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                    >
                        {loading ? 'Creating Agency...' : 'Create Agency'}
                    </button>
                </div>
            </form>
        </div>
    );

    // Content renderer
    const renderContent = () => {
        switch (step) {
            case 1:
                return renderStep1();
            case 2:
                return selectedOption === 'join' ? renderJoinAgency() : renderStep1();
            case 2.5: // New step for API key verification
                return renderApiKeyVerification();
            case 3:
                return selectedOption === 'create' ? <PricingModule onSelectPlan={handlePlanSelection} /> : renderJoinAgency();
            case 4:
                return renderCreateAgency();
            default:
                return renderStep1();
        }
    };

    // Main component render
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg max-w-6xl w-full p-8">
                {renderProgressBar()}
                {renderContent()}
            </div>
            <ApiKeyModal
                isOpen={showApiModal}
                onClose={() => {
                    setShowApiModal(false);
                    navigate('/dashboard');
                }}
                apiKey={apiKey}
            />
        </div>
    );
};

export default RecruiterOnboarding;