import React, { useState, useEffect } from 'react';
import { Eye, X, Loader2 } from 'lucide-react';

const PdfPreview = ({ formData, selectedTheme, API_BASE, API_ENDPOINTS }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handlePreview = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const exportData = {
                resume_data: {
                    work: formData.work.map(work => ({
                        url: work.url || "",
                        name: work.company || "",
                        endDate: work.endDate || null,
                        position: work.title || "",
                        startDate: work.startDate || "",
                        highlights: typeof work.description === 'string'
                            ? work.description.split('\n').filter(Boolean)
                            : []
                    })),
                    basics: {
                        url: "",
                        name: formData.basics.name || "",
                        email: formData.basics.email || "",
                        image: "",
                        label: formData.basics.label || "",
                        phone: formData.basics.phone || "",
                        summary: formData.basics.summary || "",
                        location: {
                            city: formData.basics.location?.city || "",
                            region: formData.basics.location?.region || "",
                            address: formData.basics.location?.address || "",
                            postalCode: formData.basics.location?.postalCode || "",
                            countryCode: formData.basics.location?.countryCode || "US"
                        },
                        profiles: formData.basics.profiles || []
                    },
                    projects: formData.projects.map(project => ({
                        url: project.url || "",
                        name: project.name || "",
                        endDate: project.endDate || null,
                        summary: project.description || "",
                        startDate: project.startDate || "",
                        highlights: project.description ? project.description.split('\n') : []
                    })),
                    education: formData.education.map(edu => ({
                        gpa: edu.gpa || "",
                        area: "",
                        courses: [],
                        endDate: edu.endDate || "",
                        startDate: edu.startDate || "",
                        studyType: (edu.degree || "").split(' ')[0] || "",
                        institution: edu.institution || ""
                    }))
                },
                theme_name: selectedTheme
            };

            const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                body: JSON.stringify(exportData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Export API error:', errorText);
                throw new Error(`Export failed: ${errorText}`);
            }

            const blob = await response.blob();
            if (blob.size === 0) {
                throw new Error('Generated PDF is empty');
            }

            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setShowPreview(true);
        } catch (err) {
            setError(err.message);
            console.error('Preview generation error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    return (
        <>
            <button
                onClick={handlePreview}
                disabled={isLoading}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2 disabled:opacity-50"
            >
                {isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                    <Eye className="w-5 h-5" />
                )}
                {isLoading ? 'Loading...' : 'Preview Resume'}
            </button>

            {showPreview && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-end">
                    <div
                        className={`
                            bg-white dark:bg-gray-800 w-full max-w-4xl h-full relative flex flex-col
                            transform transition-transform duration-300 ease-out
                            ${showPreview ? 'translate-x-0' : 'translate-x-[200%]'}
                        `}
                    >
                        <div className="absolute top-4 right-4">
                            <button
                                onClick={() => {
                                    setShowPreview(false);
                                    if (pdfUrl) {
                                        URL.revokeObjectURL(pdfUrl);
                                        setPdfUrl(null);
                                    }
                                }}
                                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-400"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="flex-1 overflow-auto p-4">
                            {error ? (
                                <div className="text-red-500 p-4">
                                    Error generating resume: {error}
                                </div>
                            ) : (
                                <>
                                    {isLoading && (
                                        <div className="flex items-center justify-center h-full">
                                            <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                                        </div>
                                    )}
                                    {!isLoading && pdfUrl && (
                                        <iframe
                                            title="Resume Preview"
                                            src={pdfUrl}
                                            className="w-full h-full border-0"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PdfPreview;
