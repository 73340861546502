import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit2, Loader2, Eye, X, ExternalLink } from 'lucide-react';
import supabase from '../supabaseClient';

const ResumeActionButton = ({ resume }) => {
    const navigate = useNavigate();
    const [showParseModal, setShowParseModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    const handleEditClick = () => {
        if (resume.formatted) {
            navigate(`/dashboard/editor/${resume.id}`);
        } else {
            setShowParseModal(true);
        }
    };

    const handleParseConfirm = async () => {
        try {
            setIsProcessing(true);
            setError(null);

            // Get user and agency info
            const { data: { user } } = await supabase.auth.getUser();
            const { data: membership } = await supabase
                .from('agency_members')
                .select('*, agency:agencies(*)')
                .eq('user_id', user.id)
                .single();

            // Update initial status
            await supabase
                .from('resume_uploads')
                .update({
                    status: 'parsing',
                    parse_requested: true,
                    parse_status: 'pending',
                })
                .eq('id', resume.id);

            // Download the file from storage
            const { data: fileData, error: downloadError } = await supabase.storage
                .from('resume_uploads')
                .download(resume.file_path);

            if (downloadError) throw new Error('Failed to download file');

            const file = new File([fileData], resume.file_name, { type: resume.file_type });

            // Create form data for parsing
            const formData = new FormData();
            formData.append('file', file);
            formData.append('record_id', resume.id);
            formData.append('user_id', user.id);
            formData.append('agency_id', membership.agency.id);
            formData.append('parse', 'true');
            formData.append('mode', 'new');
            if (resume.role) formData.append('role', resume.role);
            if (resume.company) formData.append('company', resume.company);
            if (resume.notes) formData.append('notes', resume.notes);

            // Start parsing in background
            fetch('https://8217-45-130-83-55.ngrok-free.app/api/v1/resume/upload_resumeably', {
                method: 'POST',
                body: formData
            }).then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.detail || 'Parse failed');
                }
                const parseData = await response.json();

                // Update resume with parse results
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'completed',
                        parse_status: 'completed',
                        formatted: true,
                        resume_json: parseData.resume_json,
                        resume_text: parseData.resume_text
                    })
                    .eq('id', resume.id);
            }).catch(async error => {
                console.error('Background parsing error:', error);

                // Update resume with error status
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'failed',
                        parse_status: 'failed',
                        error_message: error.message
                    })
                    .eq('id', resume.id);
            });

            // Close modal and let user continue
            setShowParseModal(false);
            navigate('/dashboard');

        } catch (error) {
            console.error('Setup error:', error);
            setError('Failed to start parsing');

            // Update resume with error status
            await supabase
                .from('resume_uploads')
                .update({
                    status: 'failed',
                    parse_status: 'failed',
                    error_message: error.message
                })
                .eq('id', resume.id);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            <div className="flex items-center gap-2">
                <button
                    onClick={() => setShowPreview(true)}
                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                    title="View Resume"
                >
                    <Eye className="w-5 h-5" />
                </button>
                <button
                    onClick={handleEditClick}
                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                    title="Edit Resume"
                >
                    <Edit2 className="w-5 h-5" />
                </button>
            </div>

            {showParseModal && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 max-w-md w-full">
                        <h3 className="text-xl font-semibold mb-4">
                            Parse Resume First
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 mb-6">
                            This resume needs to be parsed before it can be edited. Would you like to parse it now?
                        </p>

                        {error && (
                            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                                {error}
                            </div>
                        )}

                        <div className="flex justify-end gap-3">
                            <button
                                onClick={() => setShowParseModal(false)}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                disabled={isProcessing}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleParseConfirm}
                                disabled={isProcessing}
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                                         disabled:opacity-50 flex items-center gap-2"
                            >
                                {isProcessing ? (
                                    <>
                                        <Loader2 className="w-4 h-4 animate-spin" />
                                        Starting Parse...
                                    </>
                                ) : (
                                    'Parse & Edit'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showPreview && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-3xl h-[95vh] flex flex-col">
                        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                {resume.candidate_name || 'Resume Preview'}
                            </h3>
                            <div className="flex items-center gap-2">
                                <a
                                    href={resume.file_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                                    title="Open in new tab"
                                >
                                    <ExternalLink className="w-5 h-5" />
                                </a>
                                <button
                                    onClick={() => setShowPreview(false)}
                                    className="p-2 text-gray-600 hover:text-red-600 transition-colors"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                        <div className="flex-1 overflow-hidden bg-gray-50 dark:bg-gray-900">
                            <iframe
                                src={resume.file_url}
                                className="w-full h-full border-0"
                                title="Resume Preview"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResumeActionButton;