import React, { useEffect, useState } from 'react';
import { CheckCircle, Loader2 } from 'lucide-react';
import supabase from '../../supabaseClient';

const ResumeSelectionModal = ({ isVisible, onClose, onSelectResume }) => {
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResumes = async () => {
            if (!isVisible) return;

            try {
                setLoading(true);
                const { data: { user } } = await supabase.auth.getUser();
                if (!user) throw new Error('Not authenticated');

                const { data: membership } = await supabase
                    .from('agency_members')
                    .select('agency_id')
                    .eq('user_id', user.id)
                    .single();

                const { data: resumeData, error: resumeError } = await supabase
                    .from('resume_uploads')
                    .select('*')
                    .eq('agency_id', membership.agency_id)
                    .eq('formatted', true)
                    .order('created_at', { ascending: false });

                if (resumeError) throw resumeError;
                setResumes(resumeData);
            } catch (error) {
                console.error('Error fetching resumes:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResumes();
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[80vh] overflow-hidden">
                <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                        Select Resume to Edit
                    </h2>
                </div>

                <div className="overflow-y-auto max-h-[60vh]">
                    {loading ? (
                        <div className="flex items-center justify-center p-8">
                            <Loader2 className="animate-spin h-8 w-8 text-blue-500" />
                        </div>
                    ) : error ? (
                        <div className="p-8 text-center text-red-500">{error}</div>
                    ) : (
                        <div className="divide-y divide-gray-200 dark:divide-gray-700">
                            {resumes.map((resume) => (
                                <ResumeCard
                                    key={resume.id}
                                    resume={resume}
                                    onClick={() => onSelectResume(resume)}
                                />
                            ))}
                        </div>
                    )}
                </div>

                <div className="p-6 border-t border-gray-200 dark:border-gray-700">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const ResumeCard = ({ resume, onClick }) => (
    <button
        onClick={onClick}
        className="w-full p-6 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors text-left"
    >
        <div className="flex items-center justify-between">
            <div>
                <h3 className="text-lg font-medium">
                    {resume.candidate_name || 'Unnamed Resume'}
                </h3>
                <p className="text-sm text-gray-500">
                    {new Date(resume.created_at).toLocaleDateString()}
                </p>
            </div>
            <div className="flex items-center gap-2">
                {resume.parse_status === 'completed' ? (
                    <span className="text-green-500">
                        <CheckCircle className="w-5 h-5" />
                    </span>
                ) : (
                    <span className="text-yellow-500">
                        <Loader2 className="w-5 h-5 animate-spin" />
                    </span>
                )}
            </div>
        </div>
    </button>
);

export default ResumeSelectionModal;