// Month mapping and conversion utilities
const MONTH_MAPPINGS = {
    // Full to Short
    'january': 'Jan',
    'february': 'Feb',
    'march': 'Mar',
    'april': 'Apr',
    'may': 'May',
    'june': 'Jun',
    'july': 'Jul',
    'august': 'Aug',
    'september': 'Sep',
    'october': 'Oct',
    'november': 'Nov',
    'december': 'Dec',

    // Short to Full
    'jan': 'January',
    'feb': 'February',
    'mar': 'March',
    'apr': 'April',
    'may': 'May',
    'jun': 'June',
    'jul': 'July',
    'aug': 'August',
    'sep': 'September',
    'sept': 'September',
    'oct': 'October',
    'nov': 'November',
    'dec': 'December',

    // Numbers to Short
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep'
};

const DateConverter = {
    // Convert any month format to short form (Mar)
    toShortMonth: (input) => {
        if (!input) return '';
        const lowered = input.toLowerCase().trim();
        return MONTH_MAPPINGS[lowered] || input;
    },

    // Convert any month format to long form (March)
    toLongMonth: (input) => {
        if (!input) return '';
        const lowered = input.toLowerCase().trim();
        const shortForm = MONTH_MAPPINGS[lowered] || input;
        return MONTH_MAPPINGS[shortForm.toLowerCase()] || input;
    },

    // Parse date string into parts
    parseDateString: (dateStr) => {
        if (!dateStr) return null;
        if (dateStr.toLowerCase() === 'present' || dateStr.toLowerCase() === 'current') {
            return { original: dateStr, month: '', year: '', isPresent: true };
        }

        // Handle various date formats
        const patterns = [
            // Mar 2024, March 2024
            /^([A-Za-z]{3,})\s*(\d{4})$/,
            // 03/2024, 3/2024
            /^(\d{1,2})[/-](\d{4})$/,
            // 2024-03, 2024-3
            /^(\d{4})[/-](\d{1,2})$/,
            // 03-15-2024, 3/15/2024
            /^(\d{1,2})[/-]\d{1,2}[/-](\d{4})$/,
            // 2024-03-15
            /^(\d{4})[/-](\d{1,2})[/-]\d{1,2}$/
        ];

        for (const pattern of patterns) {
            const match = dateStr.match(pattern);
            if (match) {
                const [_, first, second] = match;
                // Determine if first group is month or year
                const isMonthFirst = pattern.source.includes('^(\\d{1,2})') || /^[A-Za-z]/.test(first);
                const month = isMonthFirst ? first : second;
                const year = isMonthFirst ? second : first;
                return { original: dateStr, month, year, isPresent: false };
            }
        }

        return null;
    },

    // Format date to specified format
    formatDate: (dateStr, format = 'short') => {
        const parsed = DateConverter.parseDateString(dateStr);
        if (!parsed) return dateStr;
        if (parsed.isPresent) return 'Present';

        let month = parsed.month;
        if (/^\d+$/.test(month)) {
            month = MONTH_MAPPINGS[month] || month;
        }

        if (format === 'short') {
            month = DateConverter.toShortMonth(month);
        } else {
            month = DateConverter.toLongMonth(month);
        }

        return `${month} ${parsed.year}`;
    },

    // Validate date string
    isValidDate: (dateStr) => {
        if (!dateStr) return false;
        if (dateStr.toLowerCase() === 'present' || dateStr.toLowerCase() === 'current') return true;

        const parsed = DateConverter.parseDateString(dateStr);
        if (!parsed) return false;

        // Validate year
        const year = parseInt(parsed.year);
        if (isNaN(year) || year < 1900 || year > 2100) return false;

        // Validate month
        const month = parsed.month.toLowerCase();
        if (/^\d+$/.test(month)) {
            const monthNum = parseInt(month);
            if (monthNum < 1 || monthNum > 12) return false;
        } else if (!Object.keys(MONTH_MAPPINGS).includes(month)) {
            return false;
        }

        return true;
    }
};

export default DateConverter;