export const TEXT_PRESETS = [
    // Original presets
    { label: 'Condense', value: 'condense' },
    { label: 'Expand', value: 'expand' },
    { label: 'Fix Word Issues', value: 'fix_words' },
    { label: 'Bullet Points', value: 'bullet_points' },


    { label: 'First Person', value: '1st_person' },
    { label: '3rd Person', value: '3rd_person' },
    { label: 'Anonymize', value: 'anonymize' },
    { label: 'Tone - Chatty', value: 'tone_chatty' },
    { label: 'Tone - Relaxed', value: 'tone_relaxed' },
    { label: 'Tone - Professional', value: 'tone_professional' },

    { label: 'Past Tense', value: 'past_tense' },
    { label: 'Bold Subheadings', value: 'bold_subheadings' },
    { label: 'Sentence Case', value: 'sentence_case' },

    // Enhanced presets
    { label: 'Make Technical', value: 'technical' },
    { label: 'Make Creative', value: 'creative' },
    { label: 'Add Examples', value: 'examples' },
    { label: 'Format Email', value: 'email_format' },
    { label: 'Meeting Summary', value: 'meeting_summary' },
    { label: 'Bullet Points', value: 'bullet_points' },
    { label: 'Number List', value: 'number_list' },
    { label: 'Fix Grammar', value: 'fix_grammar' },
    { label: 'Improve Flow', value: 'improve_flow' },
    { label: 'Add Details', value: 'add_details' },
    { label: 'Remove Fluff', value: 'remove_fluff' },
    { label: 'Active Voice', value: 'active_voice' },
    { label: 'Passive Voice', value: 'passive_voice' },
    { label: 'Academic Style', value: 'academic_style' },
    { label: 'Business Style', value: 'business_style' },
    { label: 'Casual Style', value: 'casual_style' }
];

export const CASE_STYLES = [
    { label: 'Sentence case', value: 'sentence' },
    { label: 'UPPERCASE', value: 'uppercase' },
    { label: 'Title Case', value: 'title' },
    { label: 'lowercase', value: 'lowercase' },
    { label: 'camelCase', value: 'camel' },
    { label: 'PascalCase', value: 'pascal' },
    { label: 'snake_case', value: 'snake' },
    { label: 'kebab-case', value: 'kebab' }
];

export const FORMAT_STYLES = [
    { label: 'Add Line Breaks', value: 'line_breaks' },
    { label: 'Remove Extra Spaces', value: 'remove_spaces' },
    { label: 'Fix Punctuation', value: 'fix_punctuation' },
    { label: 'Format Quotes', value: 'format_quotes' },
    { label: 'Indent Text', value: 'indent' },
    { label: 'Remove Duplicates', value: 'remove_duplicates' }
];

export const LIST_STYLES = [
    { label: 'Bullet Points (•)', value: 'bullet' },
    { label: 'Numbered List (1.)', value: 'numbered' },
    { label: 'Dash List (-)', value: 'dash' },
    { label: 'Star List (*)', value: 'star' },
    { label: 'Checkbox List (☐)', value: 'checkbox' },
    { label: 'Arrow List (→)', value: 'arrow' }
];
export const DATE_FORMATS = [
    { label: 'Month/Year', value: 'MM/YYYY' },
    { label: 'Full Date', value: 'MM/DD/YYYY' },
    { label: 'Year Only', value: 'YYYY' }
];
export const STYLE_UI_CONFIG = {
    "TEXT_PRESETS": [
        { "label": "👤 First Person", "value": "1st_person" },
        { "label": "👥 Third Person", "value": "3rd_person" },
        { "label": "🎭 Anonymize", "value": "anonymize" },
        { "label": "💬 Chatty Tone", "value": "tone_chatty" },
        { "label": "😌 Relaxed Tone", "value": "tone_relaxed" },
        { "label": "👔 Professional Tone", "value": "tone_professional" },
        { "label": "📈 Expand", "value": "expand" },
        { "label": "📉 Condense", "value": "condense" },
        { "label": "🔧 Fix Words", "value": "fix_words" },
        { "label": "⏮️ Past Tense", "value": "past_tense" },
        { "label": "💻 Technical", "value": "technical" },
        { "label": "🎨 Creative", "value": "creative" },
        { "label": "📚 Add Examples", "value": "examples" },
        { "label": "✉️ Email Format", "value": "email_format" },
        { "label": "📝 Meeting Summary", "value": "meeting_summary" },
        { "label": "✍️ Fix Grammar", "value": "fix_grammar" },
        { "label": "➡️ Improve Flow", "value": "improve_flow" },
        { "label": "📋 Add Details", "value": "add_details" },
        { "label": "✂️ Remove Fluff", "value": "remove_fluff" },
        { "label": "🗣️ Active Voice", "value": "active_voice" },
        { "label": "🤫 Passive Voice", "value": "passive_voice" },
        { "label": "🎓 Academic Style", "value": "academic_style" },
        { "label": "💼 Business Style", "value": "business_style" },
        { "label": "😊 Casual Style", "value": "casual_style" }
    ],

    "CASE_STYLES": [
        { "label": "📝 Sentence case", "value": "sentence" },
        { "label": "⬆️ UPPERCASE", "value": "uppercase" },
        { "label": "🔠 Title Case", "value": "title" },
        { "label": "⬇️ lowercase", "value": "lowercase" },
        { "label": "🐪 camelCase", "value": "camel" },
        { "label": "🐫 PascalCase", "value": "pascal" },
        { "label": "🐍 snake_case", "value": "snake" },
        { "label": "🍖 kebab-case", "value": "kebab" }
    ],

    "LIST_STYLES": [
        { "label": "• Bullet Points", "value": "bullet" },
        { "label": "1️⃣ Numbered List", "value": "numbered" },
        { "label": "➖ Dash List", "value": "dash" },
        { "label": "⭐ Star List", "value": "star" },
        { "label": "☐ Checkbox List", "value": "checkbox" },
        { "label": "➡️ Arrow List", "value": "arrow" }
    ],

    "FORMAT_STYLES": [
        { "label": "↩️ Add Line Breaks", "value": "line_breaks" },
        { "label": "🧹 Remove Spaces", "value": "remove_spaces" },
        { "label": "✏️ Fix Punctuation", "value": "fix_punctuation" },
        { "label": "💬 Format Quotes", "value": "format_quotes" },
        { "label": "↦ Indent Text", "value": "indent" },
        { "label": "🗑️ Remove Duplicates", "value": "remove_duplicates" }
    ]
}
// constants/locations.js
export const COUNTRIES = [
    { value: 'US', label: '🇺🇸 United States' },
    { value: 'GB', label: '🇬🇧 United Kingdom' },
    { value: 'CA', label: '🇨🇦 Canada' },
    { value: 'AU', label: '🇦🇺 Australia' },
    { value: 'DE', label: '🇩🇪 Germany' },
    { value: 'FR', label: '🇫🇷 France' },
    { value: 'IN', label: '🇮🇳 India' },
    { value: 'SG', label: '🇸🇬 Singapore' },
    { value: 'BR', label: '🇧🇷 Brazil' },
    { value: 'MX', label: '🇲🇽 Mexico' },
    { value: 'JP', label: '🇯🇵 Japan' },
    { value: 'CN', label: '🇨🇳 China' },
    { value: 'IT', label: '🇮🇹 Italy' },
    { value: 'ES', label: '🇪🇸 Spain' },
    { value: 'ZA', label: '🇿🇦 South Africa' },
    { value: 'KR', label: '🇰🇷 South Korea' },
    { value: 'RU', label: '🇷🇺 Russia' },
    { value: 'NL', label: '🇳🇱 Netherlands' },
    { value: 'BE', label: '🇧🇪 Belgium' },
    { value: 'SE', label: '🇸🇪 Sweden' },
    { value: 'NO', label: '🇳🇴 Norway' },
    { value: 'DK', label: '🇩🇰 Denmark' },
    { value: 'FI', label: '🇫🇮 Finland' },
    { value: 'PL', label: '🇵🇱 Poland' },
    { value: 'CH', label: '🇨🇭 Switzerland' },
    { value: 'AT', label: '🇦🇹 Austria' },
    { value: 'IL', label: '🇮🇱 Israel' },
    { value: 'AE', label: '🇦🇪 United Arab Emirates' },
    { value: 'TH', label: '🇹🇭 Thailand' },
    { value: 'PH', label: '🇵🇭 Philippines' },
    { value: 'NG', label: '🇳🇬 Nigeria' },
    { value: 'AR', label: '🇦🇷 Argentina' },
    { value: 'KR', label: '🇰🇷 South Korea' },
    // Add more as needed
];

export const MAJOR_CITIES = {
    US: [
        'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix',
        'Philadelphia', 'San Antonio', 'San Diego', 'Dallas', 'San Jose',
        'Austin', 'Detroit', 'San Francisco', 'Columbus', 'Indianapolis',
        'Fort Worth', 'Charlotte', 'Seattle', 'Denver', 'El Paso'
    ],
    GB: [
        'London', 'Manchester', 'Birmingham', 'Leeds', 'Glasgow',
        'Liverpool', 'Bristol', 'Sheffield', 'Edinburgh', 'Cardiff',
        'Belfast', 'Nottingham', 'Southampton', 'Leicester', 'Stoke-on-Trent',
        'Wolverhampton', 'Derby', 'Reading', 'Luton', 'Dundee'
    ],
    CA: [
        'Toronto', 'Vancouver', 'Montreal', 'Calgary', 'Ottawa',
        'Edmonton', 'Quebec City', 'Winnipeg', 'Hamilton', 'Kitchener',
        'London', 'Halifax', 'St. John’s', 'Victoria', 'Oshawa',
        'Brampton', 'Markham', 'Richmond Hill', 'Surrey', 'Burnaby'
    ],
    AU: [
        'Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Adelaide',
        'Canberra', 'Hobart', 'Darwin', 'Gold Coast', 'Newcastle',
        'Wollongong', 'Geelong', 'Cairns', 'Townsville', 'Ballarat',
        'Bendigo', 'Mackay', 'Launceston', 'Rockhampton', 'Tamworth'
    ],
    DE: [
        'Berlin', 'Munich', 'Hamburg', 'Cologne', 'Frankfurt',
        'Stuttgart', 'Düsseldorf', 'Dortmund', 'Essen', 'Leipzig',
        'Bremen', 'Dresden', 'Hanover', 'Nuremberg', 'Rostock',
        'Mannheim', 'Aachen', 'Kiel', 'Karlsruhe', 'Wiesbaden'
    ],
    FR: [
        'Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice',
        'Nantes', 'Montpellier', 'Strasbourg', 'Bordeaux', 'Lille',
        'Rennes', 'Le Havre', 'Reims', 'Saint-Étienne', 'Toulon',
        'Le Mans', 'Aix-en-Provence', 'Clermont-Ferrand', 'Angers', 'Nîmes'
    ],
    IN: [
        'New Delhi', 'Mumbai', 'Bangalore', 'Kolkata', 'Chennai',
        'Hyderabad', 'Ahmedabad', 'Pune', 'Surat', 'Jaipur',
        'Lucknow', 'Kanpur', 'Nagpur', 'Indore', 'Patna',
        'Vadodara', 'Agra', 'Varanasi', 'Madurai', 'Rajkot'
    ],
    SG: [
        'Singapore'
    ],
    BR: [
        'São Paulo', 'Rio de Janeiro', 'Salvador', 'Brasília', 'Fortaleza',
        'Belo Horizonte', 'Manaus', 'Curitiba', 'Recife', 'Porto Alegre',
        'Belém', 'Goiânia', 'Guarulhos', 'Campinas', 'São Bernardo do Campo'
    ],
    MX: [
        'Mexico City', 'Guadalajara', 'Monterrey', 'Puebla', 'Cancún',
        'Mérida', 'Tijuana', 'León', 'Juárez', 'Zapopan',
        'Culiacán Rosales', 'Aguascalientes', 'Querétaro', 'Chihuahua', 'Mazatlán'
    ],
    JP: [
        'Tokyo', 'Osaka', 'Yokohama', 'Nagoya', 'Sapporo',
        'Kobe', 'Fukuoka', 'Kawasaki', 'Saitama', 'Hiroshima',
        'Sendai', 'Chiba', 'Kinki', 'Kochi', 'Okinawa'
    ],
    CN: [
        'Beijing', 'Shanghai', 'Guangzhou', 'Shenzhen', 'Tianjin',
        'Chengdu', 'Hong Kong', 'Nanjing', 'Hangzhou', 'Xi’an',
        'Suzhou', 'Wuhan', 'Shenyang', 'Chongqing', 'Harbin'
    ],
    // Add more countries and their cities
};

export const INDUSTRIES = [
    { value: 'tech', label: '💻 Technology', subCategories: ['Software', 'Hardware', 'AI/ML', 'Cybersecurity', 'Blockchain', 'Cloud Computing'] },
    { value: 'healthcare', label: '🏥 Healthcare', subCategories: ['Hospitals', 'Biotech', 'Medical Devices', 'Pharmaceuticals', 'Telemedicine'] },
    { value: 'finance', label: '💰 Finance', subCategories: ['Banking', 'Investment', 'Insurance', 'FinTech', 'Cryptocurrency'] },
    { value: 'education', label: '📚 Education', subCategories: ['K-12', 'Higher Ed', 'EdTech', 'Online Learning', 'Corporate Training'] },
    { value: 'retail', label: '🛍️ Retail', subCategories: ['E-commerce', 'Brick & Mortar', 'Fashion', 'Luxury Goods', 'Home Goods'] },
    { value: 'manufacturing', label: '🏭 Manufacturing', subCategories: ['Automotive', 'Electronics', 'Consumer Goods', 'Industrial Machinery', 'Aerospace'] },
    { value: 'media', label: '📱 Media & Entertainment', subCategories: ['Digital Media', 'Gaming', 'Streaming', 'Film', 'Music', 'Publishing'] },
    { value: 'hospitality', label: '🏨 Hospitality', subCategories: ['Hotels', 'Restaurants', 'Tourism', 'Resorts', 'Event Management'] },
    { value: 'construction', label: '🏗️ Construction', subCategories: ['Residential', 'Commercial', 'Infrastructure', 'Green Building', 'Urban Planning'] },
    { value: 'energy', label: '⚡ Energy', subCategories: ['Renewable', 'Oil & Gas', 'Utilities', 'Nuclear', 'Smart Grid'] },
    { value: 'agriculture', label: '🌾 Agriculture', subCategories: ['Farming', 'AgTech', 'Food Production', 'Agribusiness', 'Horticulture'] },
    { value: 'transportation', label: '🚗 Transportation', subCategories: ['Logistics', 'Aviation', 'Automotive', 'Public Transport', 'Shipping'] },
    { value: 'consulting', label: '💼 Consulting', subCategories: ['Management', 'IT', 'HR', 'Marketing', 'Strategy'] },
    { value: 'legal', label: '⚖️ Legal Services', subCategories: ['Law Firms', 'Legal Tech', 'Compliance', 'Corporate Law', 'Intellectual Property'] },
    { value: 'nonprofit', label: '🤝 Non-Profit', subCategories: ['Charities', 'NGOs', 'Foundations', 'Social Enterprises', 'Volunteer Organizations'] },
    { value: 'real_estate', label: '🏠 Real Estate', subCategories: ['Residential', 'Commercial', 'Property Management', 'Real Estate Investment'] },
    { value: 'telecommunications', label: '📞 Telecommunications', subCategories: ['Mobile Networks', 'Satellite', 'Broadband', 'Telecom Infrastructure'] },
    { value: 'space', label: '🌌 Space', subCategories: ['Space Exploration', 'Satellite Technology', 'Astronomy', 'Astrobiology'] },
    { value: 'automotive', label: '🚗 Automotive', subCategories: ['Electric Vehicles', 'Autonomous Vehicles', 'Automotive Parts', 'Car Dealerships'] },
    { value: 'technology', label: '💡 Emerging Technologies', subCategories: ['Quantum Computing', 'IoT', 'Robotics', 'Wearables', 'Augmented Reality'] },
];

