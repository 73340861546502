import React, { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import ResumePreview from './ResumePreview';


import {
    Download,
    Star,
    CheckCircle,
    GripVertical,
    Plus,
    X,
    Eye
} from 'lucide-react';

// Import components
import ErrorMessage from '../../Components/ErrorMessage';
import ThemeSelector from '../../Components/ThemeSelector';
import LoadingSpinner from '../../Components/LoadingSpinner';
import TextEditor from '../../Components/TextEditor';
import WorkExperienceItem from '../../Components/WorkExperienceItem';
import EducationItem from '../../Components/EducationItem';
import SkillsSection from '../../Components/SkillsSection';
import ProjectItem from '../../Components/ProjectItem';
import AwardItem from '../../Components/AwardItem';
import ResumePreviewPanel from './ResumePreviewPanel';

const API_BASE = process.env.REACT_APP_API_BASE || 'https://e0a0-74-88-7-3.ngrok-free.app';
const API_ENDPOINTS = {
    THEMES: '/api/themes',
    PREVIEW: '/api/preview',
    EXPORT: '/api/export-pdf'
};

const TABS = [
    { id: 'resume', label: 'Resume Editor' },
    { id: 'cover', label: 'Cover Letter' },
    { id: 'pitch', label: 'Candidate Pitch' }
];

const SortableSection = ({ id, title, isExpanded, onToggle, children }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <div ref={setNodeRef} style={style}>
            <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                <div
                    {...attributes}
                    {...listeners}
                    className="cursor-grab active:cursor-grabbing mr-2"
                >
                    <GripVertical className="w-5 h-5" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 capitalize">
                    {title}
                </h3>
                <button
                    onClick={onToggle}
                    className="ml-auto text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                >
                    {isExpanded ? '−' : '+'}
                </button>
            </div>
            {children}
        </div>
    );
};

const ResumeEditorPanel = ({
    error,
    resumeScore,
    selectedTheme,
    onThemeChange,
    onExport,
    isProcessing,
    sections,
    expandedSections,
    onSectionToggle,
    onDragEnd,
    onSave,
    sensors,
    formData,
    onFormUpdate,
    coverLetter = '',
    onCoverLetterChange,
    candidatePitch = '',
    onCandidatePitchChange,
    onSaveCoverLetter,
    onSavePitch
}) => {
    const [activeTab, setActiveTab] = useState('resume');
    const [showPreview, setShowPreview] = useState(false);
    const [previewHtml, setPreviewHtml] = useState('');
    const [localError, setError] = useState(null);

    // Incorporate the updatePreview function
    const updatePreview = async (data = formData) => {
        if (!data?.basics?.name) return;

        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({
                    resume_data: data,
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) throw new Error('Preview generation failed');
            const html = await response.text();
            setPreviewHtml(html);
        } catch (error) {
            console.error('Error updating preview:', error);
            setError('Failed to update preview');
        }
    };

    const handlePreview = async () => {
        await updatePreview();
        setShowPreview(true);
    };

    const renderSectionContent = (section) => {
        if (!formData) return null;

        switch (section) {
            case 'basics':
                return (
                    <div className="space-y-4">
                        <input
                            type="text"
                            value={formData.basics?.name || ''}
                            onChange={(e) => onFormUpdate('basics', 'name', e.target.value)}
                            placeholder="Full Name"
                            className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        />
                        <input
                            type="text"
                            value={formData.basics?.label || ''}
                            onChange={(e) => onFormUpdate('basics', 'label', e.target.value)}
                            placeholder="Professional Title (e.g., Software Engineer)"
                            className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        />
                        <input
                            type="text"
                            value={formData.basics?.url || ''}
                            onChange={(e) => onFormUpdate('basics', 'url', e.target.value)}
                            placeholder="LinkedIn or Personal Website URL"
                            className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        />

                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="email"
                                value={formData.basics?.email || ''}
                                onChange={(e) => onFormUpdate('basics', 'email', e.target.value)}
                                placeholder="Email"
                                className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                            />
                            <input
                                type="tel"
                                value={formData.basics?.phone || ''}
                                onChange={(e) => onFormUpdate('basics', 'phone', e.target.value)}
                                placeholder="Phone"
                                className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="text"
                                value={formData.basics?.location?.city || ''}
                                onChange={(e) => onFormUpdate('basics', 'location', { ...formData.basics.location, city: e.target.value })}
                                placeholder="City"
                                className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                            />
                            <input
                                type="text"
                                value={formData.basics?.location?.region || ''}
                                onChange={(e) => onFormUpdate('basics', 'location', { ...formData.basics.location, region: e.target.value })}
                                placeholder="Region/State"
                                className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                            />
                        </div>

                        <TextEditor
                            content={formData.basics?.summary || ''}
                            onChange={(value) => onFormUpdate('basics', 'summary', value)}
                        />
                    </div>
                );


            case 'work':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('work', null, [
                                ...(formData.work || []),
                                {
                                    id: Date.now().toString(),
                                    company: '',
                                    title: '',
                                    location: '',
                                    startDate: '',
                                    endDate: '',
                                    description: ''
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Work Experience
                        </button>
                        {(formData.work || []).map((experience, index) => (
                            <WorkExperienceItem
                                key={experience.id || index}
                                experience={experience}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.work || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('work', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('work', null,
                                        formData.work.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            case 'education':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('education', null, [
                                ...(formData.education || []),
                                {
                                    id: Date.now().toString(),
                                    institution: '',
                                    degree: '',
                                    location: '',
                                    startDate: '',
                                    endDate: '',
                                    gpa: '',
                                    description: ''
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Education
                        </button>
                        {(formData.education || []).map((education, index) => (
                            <EducationItem
                                key={education.id || index}
                                education={education}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.education || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('education', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('education', null,
                                        formData.education.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            case 'skills':
                return (
                    <SkillsSection
                        skills={formData.skills}
                        onUpdate={(newSkills) => onFormUpdate('skills', null, newSkills)}
                    />
                );

            case 'projects':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('projects', null, [
                                ...(formData.projects || []),
                                {
                                    id: Date.now().toString(),
                                    name: '',
                                    url: '',
                                    technologies: [],
                                    description: ''
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Project
                        </button>
                        {(formData.projects || []).map((project, index) => (
                            <ProjectItem
                                key={project.id || index}
                                project={project}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.projects || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('projects', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('projects', null,
                                        formData.projects.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            default:
                return null;
        }
    };


    const renderTabContent = () => {
        switch (activeTab) {
            case 'resume':
                return (
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={onDragEnd}
                        modifiers={[restrictToVerticalAxis]}
                    >
                        <SortableContext items={sections} strategy={verticalListSortingStrategy}>
                            <div className="space-y-4">
                                {sections.map((section) => (
                                    <div
                                        key={section}
                                        className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700"
                                    >
                                        <SortableSection
                                            id={section}
                                            title={section}
                                            isExpanded={expandedSections[section]}
                                            onToggle={() => onSectionToggle(section)}
                                        >
                                            {expandedSections[section] && (
                                                <div className="p-4">
                                                    {renderSectionContent(section)}
                                                </div>
                                            )}
                                        </SortableSection>
                                    </div>
                                ))}
                            </div>
                        </SortableContext>
                    </DndContext>
                );

            case 'cover':
                return (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Cover Letter</h2>
                            <button
                                onClick={onSaveCoverLetter}
                                disabled={isProcessing}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                            >
                                {isProcessing ? 'Saving...' : 'Save Cover Letter'}
                            </button>
                        </div>
                        <textarea
                            value={coverLetter}
                            onChange={(e) => onCoverLetterChange(e.target.value)}
                            className="w-full h-[calc(100vh-300px)] p-4 border rounded bg-white dark:bg-gray-800 dark:text-white resize-none"
                            placeholder="Write or generate a cover letter..."
                            disabled={isProcessing}
                        />
                    </div>
                );

            case 'pitch':
                return (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Candidate Pitch</h2>
                            <button
                                onClick={onSavePitch}
                                disabled={isProcessing}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                            >
                                {isProcessing ? 'Saving...' : 'Save Pitch'}
                            </button>
                        </div>
                        <textarea
                            value={candidatePitch}
                            onChange={(e) => onCandidatePitchChange(e.target.value)}
                            className="w-full h-[calc(100vh-300px)] p-4 border rounded bg-white dark:bg-gray-800 dark:text-white resize-none"
                            placeholder="Write a compelling pitch for this candidate..."
                            disabled={isProcessing}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="w-full bg-white dark:bg-gray-800 p-4 overflow-auto">
            {(error || localError) && <ErrorMessage message={error || localError} />}

            {showPreview && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white dark:bg-gray-800 w-11/12 h-5/6 rounded-lg p-4 relative">
                        <button
                            onClick={() => setShowPreview(false)}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400"
                        >
                            <X size={24} />
                        </button>
                        <div className="h-full overflow-auto">
                            <ResumePreviewPanel previewHtml={previewHtml} />
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-col space-y-4 mb-4">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                            Edit Resume
                        </h3>
                        <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
                            <Star className="w-4 h-4 text-blue-500" />
                            <span className="text-blue-600 font-medium">
                                {resumeScore}%
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <ThemeSelector
                            selectedTheme={selectedTheme}
                            onThemeChange={onThemeChange}
                        />
                        <ResumePreview
                            formData={formData}
                            selectedTheme={selectedTheme}
                            API_BASE={API_BASE}
                            API_ENDPOINTS={API_ENDPOINTS}
                        />
                        <button
                            onClick={onExport}
                            disabled={isProcessing}
                            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 flex items-center gap-2 disabled:opacity-50"
                        >
                            {isProcessing ? (
                                <LoadingSpinner />
                            ) : (
                                <Download className="w-5 h-5" />
                            )}
                            Export PDF
                        </button>
                    </div>
                </div>

                <div className="border-b border-gray-200 dark:border-gray-700">
                    <nav className="flex space-x-8" aria-label="Tabs">
                        {TABS.map(tab => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm
                                    ${activeTab === tab.id
                                        ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 dark:text-gray-400'
                                    }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>

            <div className="mt-6">
                {renderTabContent()}
            </div>

            {activeTab === 'resume' && (
                <div className="sticky bottom-0 bg-white dark:bg-gray-800 p-4 border-t border-gray-200 dark:border-gray-700 mt-4">
                    <button
                        onClick={onSave}
                        disabled={isProcessing}
                        className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center justify-center gap-2"
                    >
                        {isProcessing ? (
                            <>
                                <LoadingSpinner />
                                Saving...
                            </>
                        ) : (
                            <>
                                <CheckCircle className="w-5 h-5" />
                                Save Changes
                            </>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResumeEditorPanel;
