function adjustColorOpacity(hexColor, opacity) {
    if (!hexColor.startsWith('#')) {
        return hexColor;
    }

    const red = parseInt(hexColor.slice(1, 3), 16);
    const green = parseInt(hexColor.slice(3, 5), 16);
    const blue = parseInt(hexColor.slice(5, 7), 16);

    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
}

export function generateCompleteTheme({
    agencyName = 'Default Agency Name',
    primaryColor = '#4F46E5',
    secondaryColor = '#7C3AED',
    logoPath = null,
    fontFamily = 'Inter, system-ui, sans-serif'
}) {
    return {
        agencyInformation: {
            fullName: agencyName,
            logoPath: logoPath,
            branding: {
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
                fontFamily: fontFamily
            }
        },
        colorPalette: {
            primary: primaryColor,
            secondary: secondaryColor,
            textColors: {
                primary: '#111827',
                secondary: '#6B7280',
                tertiary: '#9CA3AF',
                inverse: '#FFFFFF'
            },
            backgroundColors: {
                primary: '#FFFFFF',
                secondary: '#F3F4F6',
                tertiary: '#E5E7EB',
                dark: '#111827'
            },
            statusColors: {
                success: '#10B981',
                warning: '#F59E0B',
                error: '#EF4444',
                info: '#3B82F6'
            },
            borderColors: {
                light: '#E5E7EB',
                medium: '#D1D5DB',
                dark: '#9CA3AF'
            }
        },
        gradientConfigurations: {
            sidebarGradient: `linear-gradient(to bottom, ${primaryColor}, ${secondaryColor})`,
            contentGradient: `linear-gradient(to bottom right, #FFFFFF, ${adjustColorOpacity(primaryColor, 0.1)}, #FFFFFF)`,
            buttonGradients: {
                primary: `linear-gradient(to right, ${primaryColor}, ${adjustColorOpacity(primaryColor, 0.8)})`,
                secondary: `linear-gradient(to right, ${secondaryColor}, ${adjustColorOpacity(secondaryColor, 0.8)})`
            }
        }
    };
}