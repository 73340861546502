// components/SearchableSelect.js
import React, { useState, useRef, useEffect } from 'react';
import { Search, ChevronDown, X } from 'lucide-react';

const SearchableSelect = ({
    options,
    value,
    onChange,
    placeholder,
    allowCustom = true,
    label,
    className = ""
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const wrapperRef = useRef(null);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = (option) => {
        onChange(option);
        setIsOpen(false);
        setSearchTerm('');
    };

    const handleCustomInput = (e) => {
        if (allowCustom) {
            onChange({ value: e.target.value, label: e.target.value });
        }
        setSearchTerm(e.target.value);
    };

    return (
        <div className="relative" ref={wrapperRef}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
            </label>
            <div className="relative">
                <div
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 cursor-pointer flex items-center justify-between"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className={value ? '' : 'text-gray-400'}>
                        {value?.label || placeholder}
                    </span>
                    <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
                </div>

                {isOpen && (
                    <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg">
                        <div className="p-2">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                                <input
                                    type="text"
                                    className="w-full pl-9 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={handleCustomInput}
                                />
                            </div>
                        </div>
                        <div className="max-h-60 overflow-y-auto">
                            {filteredOptions.map((option) => (
                                <div
                                    key={option.value}
                                    className={`px-4 py-2 cursor-pointer hover:bg-blue-50 ${value?.value === option.value ? 'bg-blue-50' : ''
                                        }`}
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.label}
                                </div>
                            ))}
                            {allowCustom && searchTerm && !filteredOptions.length && (
                                <div
                                    className="px-4 py-2 cursor-pointer hover:bg-blue-50 text-blue-600"
                                    onClick={() => handleSelect({ value: searchTerm, label: searchTerm })}
                                >
                                    + Add "{searchTerm}"
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchableSelect;