import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import supabase from '../../supabaseClient';

// Import subcomponents
import ResumeSelectionModal from './ResumeSelectionModal';
import ResumePreviewPanel from './ResumePreviewPanel';
import ResumeEditorPanel from './ResumeEditorPanel';
import JobDescriptionModal from './JobDescriptionModal';
import ThemeSelector from '../../Components/ThemeSelector';

const API_BASE = process.env.REACT_APP_API_BASE || 'https://e0a0-74-88-7-3.ngrok-free.app';
const API_ENDPOINTS = {
    THEMES: '/api/themes',
    PREVIEW: '/api/preview',
    EXPORT: '/api/export-pdf'
};

const parseResumeData = (resumeJson) => {
    // Handle skills
    let skillsData = { tools: [], skills: [] };
    if (resumeJson.skills) {
        const input = resumeJson.skills;
        // If there's a keywords array, put them into `skills`
        if (Array.isArray(input.keywords)) {
            skillsData = {
                tools: [],
                skills: input.keywords
            };
        } else if (Array.isArray(input.tools) && Array.isArray(input.skills)) {
            // Already in the desired format
            skillsData = input;
        }
    }

    return {
        basics: {
            name: resumeJson.basics?.name || '',
            email: resumeJson.basics?.email || '',
            phone: resumeJson.basics?.phone || '',
            location: resumeJson.basics?.location || {},
            summary: resumeJson.basics?.summary || '',
            label: resumeJson.basics?.label || '',
            url: resumeJson.basics?.url || ''
        },
        work: (resumeJson.work || []).map(work => ({
            id: work.id || Date.now().toString(),
            company: work.name || '',
            title: work.position || '',
            location: work.location || '',
            startDate: work.startDate || '',
            endDate: work.endDate || '',
            description: (work.highlights || []).join('\n')
        })),
        education: (resumeJson.education || []).map(edu => ({
            id: edu.id || Date.now().toString(),
            institution: edu.institution || '',
            degree: `${edu.studyType || ''} ${edu.area || ''}`.trim(),
            location: edu.location || '',
            startDate: edu.startDate || '',
            endDate: edu.endDate || '',
            gpa: edu.gpa || '',
            description: edu.description || ''
        })),
        skills: skillsData,
        projects: (resumeJson.projects || []).map(project => ({
            id: project.id || Date.now().toString(),
            name: project.name || '',
            description: project.summary || '',
            startDate: project.startDate || '',
            endDate: project.endDate || '',
            url: project.url || '',
            technologies: project.technologies || []
        })),
        awards: (resumeJson.awards || []).map(award => ({
            id: award.id || Date.now().toString(),
            title: award.title,
            issuer: award.issuer,
            dateReceived: award.date,
            description: award.description || ''
        }))
    };
};

const ResumeEditor = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('resume');
    const [showPreview, setShowPreview] = useState(false);
    const [coverLetter, setCoverLetter] = useState('');
    const [candidatePitch, setCandidatePitch] = useState('');
    const [showSelectionModal, setShowSelectionModal] = useState(!id);
    const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [resumeScore, setResumeScore] = useState(85);
    const [conversionId, setConversionId] = useState(null);

    const [previewHtml, setPreviewHtml] = useState('');
    const [selectedTheme, setSelectedTheme] = useState('stackoverflow');
    const [themes, setThemes] = useState([]);
    const [originalFileUrl, setOriginalFileUrl] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [sections, setSectionsState] = useState([
        'basics', 'work', 'education', 'skills', 'projects', 'awards'
    ]);
    const [expandedSections, setExpandedSections] = useState({
        basics: true,
        work: true,
        education: true,
        skills: true,
        projects: true,
        awards: true
    });
    const [formData, setFormData] = useState({
        basics: {
            url: "",
            name: "",
            email: "",
            image: "",
            label: "",
            phone: "",
            summary: "",
            location: {
                city: "",
                region: "",
                address: "",
                postalCode: "",
                countryCode: ""
            },
            profiles: []
        },
        work: [],
        education: [],
        skills: {
            tools: [],
            skills: []
        },
        projects: []
    });

    const [jobDescription, setJobDescription] = useState({
        companyName: '',
        roleTitle: '',
        department: '',
        location: '',
        salaryRange: '',
        status: 'ACTIVE'
    });

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const initializeResumeConversion = async (originalResumeId) => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('Not authenticated');

            const { data: membership } = await supabase
                .from('agency_members')
                .select('*')
                .eq('user_id', session.user.id)
                .single();

            if (!membership) throw new Error('No agency membership found');

            const { data: originalResume } = await supabase
                .from('resume_uploads')
                .select('*')
                .eq('id', originalResumeId)
                .single();

            if (!originalResume) throw new Error('Original resume not found');

            const parsedData = originalResume.resume_json || {};
            const locationData = parsedData.basics?.location || {};
            const currentWork = parsedData.work?.[0] || {};

            const { data: conversion, error } = await supabase
                .from('resume_conversions')
                .insert({
                    original_resume_id: originalResumeId,
                    agency_id: membership.agency_id,
                    user_id: session.user.id,
                    candidate_name: parsedData.basics?.name || '',
                    company: originalResume.company || '',
                    file_url: originalResume.file_url,
                    file_type: originalResume.file_type,
                    file_name: originalResume.file_name,
                    file_path: originalResume.file_path,
                    name: parsedData.basics?.name || '',
                    email: parsedData.basics?.email || '',
                    phone: parsedData.basics?.phone || '',
                    label: parsedData.basics?.label || '',
                    summary: parsedData.basics?.summary || '',
                    city: locationData.city || '',
                    region: locationData.region || '',
                    country_code: locationData.countryCode || '',
                    current_position: currentWork.position || '',
                    current_company: currentWork.name || '',
                    current_start_date: currentWork.startDate || '',
                    highest_education: parsedData.education?.[0]?.studyType || '',
                    education_institution: parsedData.education?.[0]?.institution || '',
                    education_graduation_date: parsedData.education?.[0]?.endDate || '',
                    work_experience: JSON.stringify(parsedData.work || []),
                    education_history: JSON.stringify(parsedData.education || []),
                    projects: JSON.stringify(parsedData.projects || []),
                    volunteer: JSON.stringify(parsedData.volunteer || []),
                    skills: JSON.stringify(parsedData.skills || { tools: [], skills: [] }),
                    certifications: JSON.stringify(parsedData.certifications || []),
                    languages: JSON.stringify(parsedData.languages || []),
                    hobbies: JSON.stringify(parsedData.hobbies || []),
                    selected_theme: 'elegant',
                    custom_styling: null,
                    version: 1,
                    is_latest: true,
                    status: 'draft',
                    resume_json: parsedData,
                    created_by: session.user.id,
                    last_modified_by: session.user.id,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                })
                .select()
                .single();

            if (error) throw error;
            return conversion;
        } catch (error) {
            console.error('Error initializing resume conversion:', error);
            throw error;
        }
    };

    const updateResumeConversion = async (conversionId, updates) => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('Not authenticated');

            const { data: conversion, error } = await supabase
                .from('resume_conversions')
                .update({
                    ...updates,
                    last_modified_by: session.user.id
                })
                .eq('id', conversionId)
                .select()
                .single();

            if (error) throw error;
            return conversion;
        } catch (error) {
            console.error('Error updating resume conversion:', error);
            throw error;
        }
    };

    const handleJobDescriptionSave = async (descriptionData) => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('Not authenticated');

            const { data: jobDescription, error } = await supabase
                .from('open_roles')
                .insert({
                    id: supabase.fn.uuid_generate_v4(),
                    recruiter_id: session.user.id,
                    company_name: descriptionData.companyName,
                    role_title: descriptionData.roleTitle,
                    department: descriptionData.department,
                    location: descriptionData.location,
                    salary_range: descriptionData.salaryRange,
                    status: 'ACTIVE',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                })
                .select()
                .single();

            if (error) throw error;
            setJobDescription(jobDescription);
        } catch (error) {
            console.error('Error saving job description:', error);
            setError('Failed to save job description');
        }
    };

    const handleResumeSelect = async (resume) => {
        try {
            setLoading(true);
            setShowSelectionModal(false);

            const conversion = await initializeResumeConversion(resume.id);
            setConversionId(conversion.id);

            setOriginalFileUrl(resume.file_url);
            setFileType(resume.file_type);

            if (resume.resume_json) {
                setFormData(parseResumeData(resume.resume_json));
            }

            window.history.pushState({}, '', `/dashboard/editor/${resume.id}`);
            await updatePreview();
            setShowJobDescriptionModal(true);
        } catch (error) {
            console.error('Error loading resume:', error);
            setError('Failed to load resume data');
        } finally {
            setLoading(false);
        }
    };

    const handleSectionToggle = (sectionId) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = sections.indexOf(active.id);
            const newIndex = sections.indexOf(over.id);
            setSectionsState(prev => arrayMove(prev, oldIndex, newIndex));
        }
    };

    const handleFormUpdate = (section, field, value) => {
        // Store changes directly as objects
        setFormData(prev => {
            return {
                ...prev,
                [section]: field ? {
                    ...prev[section],
                    [field]: value
                } : value
            };
        });
    };

    const handleSave = async () => {
        try {
            setIsProcessing(true);
            await updateResumeConversion(conversionId, {
                resume_json: formData,
                updated_at: new Date().toISOString()
            });
            await updatePreview();
        } catch (error) {
            console.error('Error saving resume:', error);
            setError('Failed to save changes');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleExport = async () => {
        try {
            setIsProcessing(true);

            const exportData = {
                resume_data: {
                    work: formData.work.map(work => ({
                        url: work.url || "",
                        name: work.company || "",
                        endDate: work.endDate || null,
                        position: work.title || "",
                        startDate: work.startDate || "",
                        highlights: typeof work.description === 'string'
                            ? work.description.split('\n').filter(Boolean)
                            : []
                    })),
                    basics: {
                        url: "",
                        name: formData.basics.name || "",
                        email: formData.basics.email || "",
                        image: "",
                        label: formData.basics.label || "",
                        phone: formData.basics.phone || "",
                        summary: formData.basics.summary || "",
                        location: {
                            city: formData.basics.location?.city || "",
                            region: formData.basics.location?.region || "",
                            address: formData.basics.location?.address || "",
                            postalCode: formData.basics.location?.postalCode || "",
                            countryCode: formData.basics.location?.countryCode || "US"
                        },
                        profiles: formData.basics.profiles || []
                    },
                    projects: formData.projects.map(project => ({
                        url: project.url || "",
                        name: project.name || "",
                        endDate: project.endDate || null,
                        summary: project.description || "",
                        startDate: project.startDate || "",
                        highlights: project.description ? project.description.split('\n') : []
                    })),
                    education: formData.education.map(edu => ({
                        gpa: edu.gpa || "",
                        area: "",
                        courses: [],
                        endDate: edu.endDate || "",
                        startDate: edu.startDate || "",
                        studyType: (edu.degree || "").split(' ')[0] || "",
                        institution: edu.institution || ""
                    }))
                },
                theme_name: selectedTheme
            };

            const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                body: JSON.stringify(exportData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Export API error:', errorText);
                throw new Error(`Export failed: ${errorText}`);
            }

            const blob = await response.blob();
            if (blob.size === 0) {
                throw new Error('Generated PDF is empty');
            }

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${formData.basics.name || 'resume'}_${selectedTheme}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Export error:', error);
            setError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };


    useEffect(() => {
        if (id) {
            handleResumeSelect({ id });
        }
    }, [id]);

    useEffect(() => {
        const previewTimeout = setTimeout(() => {
            updatePreview();
        }, 500);
        return () => clearTimeout(previewTimeout);
    }, [formData, selectedTheme]);

    const updatePreview = async () => {
        if (!formData.basics.name) return;
        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    resume_data: formData,
                    theme_name: selectedTheme
                })
            });

            const html = await response.text();
            setPreviewHtml(html);
        } catch (error) {
            console.error('Error updating preview:', error);
            setError('Failed to update preview');
        }
    };

    return (
        <>
            <ResumeSelectionModal
                isVisible={showSelectionModal}
                onClose={() => navigate('/dashboard')}
                onSelectResume={handleResumeSelect}
            />

            <div className="flex h-screen bg-slate-50 dark:bg-gray-900">
                <div className="w-1/2 border-r border-gray-200 dark:border-gray-700 overflow-auto">
                    <ResumePreviewPanel
                        fileUrl={originalFileUrl}
                        fileType={fileType}
                        previewHtml={previewHtml}
                    />
                </div>
                <div className="w-1/2 overflow-auto">
                    <ResumeEditorPanel
                        error={error}
                        resumeScore={resumeScore}
                        selectedTheme={selectedTheme}
                        onThemeChange={setSelectedTheme}
                        onExport={handleExport}
                        isProcessing={isProcessing}
                        sections={sections}
                        expandedSections={expandedSections}
                        onSectionToggle={handleSectionToggle}
                        onDragEnd={handleDragEnd}
                        onSave={handleSave}
                        sensors={sensors}
                        formData={formData}
                        onFormUpdate={handleFormUpdate}
                        previewHtml={previewHtml}
                        fileUrl={originalFileUrl}
                        fileType={fileType}
                        coverLetter={coverLetter}
                        onCoverLetterChange={setCoverLetter}
                        candidatePitch={candidatePitch}
                        onCandidatePitchChange={setCandidatePitch}
                        onSaveCoverLetter={() => { }}
                        onSavePitch={() => { }}
                    />
                </div>
            </div>
        </>
    );
};

export default ResumeEditor;
