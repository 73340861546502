import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingSpinner = () => (
    <div className="flex items-center justify-center p-4">
        <Loader2 className="animate-spin" size={24} />
    </div>
);

export default LoadingSpinner;
