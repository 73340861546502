// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import {
//     Bold, Italic, Underline, List, RotateCcw, RotateCw,
//     Loader2, Sparkles, Type, Layout,
//     Hash, ArrowDown, RefreshCw
// } from 'lucide-react';
// import { STYLE_UI_CONFIG } from '../constants';

// const NGROK_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

// const RECRUITER_ACTIONS = [
//     { icon: "📝", label: "Condense", action: "condense", description: "Make content more concise" },
//     { icon: "👤", label: "3rd Person", action: "third-person", description: "Convert to third person voice" },
//     { icon: "✨", label: "Expand", action: "expand", description: "Add more detail" },
//     { icon: "🔧", label: "Fix Words", action: "fix", description: "Improve word choice" },
//     { icon: "⭐", label: "Custom", action: "custom", description: "Custom transformation" }
// ];

// const ControlGroup = ({ title, children }) => (
//     <div className="flex items-center gap-2">
//         <span className="text-sm text-gray-500 dark:text-gray-400 font-medium">{title}</span>
//         <div className="flex items-center gap-1 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//             {children}
//         </div>
//     </div>
// );

// const TextEditor = ({ content, onChange }) => {
//     const [selectedFormat, setSelectedFormat] = useState(null);
//     const [history, setHistory] = useState([content]);
//     const [historyIndex, setHistoryIndex] = useState(0);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [activeTab, setActiveTab] = useState('text');
//     const [autoBulletEnabled, setAutoBulletEnabled] = useState(true);
//     const [originalText, setOriginalText] = useState(content);
//     const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
//     const [textFormat, setTextFormat] = useState({
//         bold: false,
//         italic: false,
//         underline: false
//     });

//     const editorRef = useRef(null);

//     // Enhanced auto-format with double validation
//     // useEffect(() => {
//     //     if (content && content.trim().length > 0) {
//     //         // First bullet formatting
//     //         handleAIApply('bullet', 'list');
//     //         // Second pass after a delay
//     //         setTimeout(() => {
//     //             handleAIApply('bullet', 'list');
//     //         }, 500);
//     //     }
//     // }, []);

//     const handleFormat = (type) => {
//         setTextFormat(prev => ({
//             ...prev,
//             [type]: !prev[type]
//         }));

//         const selection = window.getSelection();
//         const selectedText = selection?.toString();
//         if (selectedText) {
//             const newText = content.replace(selectedText, formatText(selectedText, type));
//             onChange(newText);
//             addToHistory(newText);
//         }
//     };

//     const formatText = (text, type) => {
//         switch (type) {
//             case 'bold':
//                 return `**${text}**`;
//             case 'italic':
//                 return `*${text}*`;
//             case 'underline':
//                 return `_${text}_`;
//             default:
//                 return text;
//         }
//     };

//     const handleQuickAction = async (action) => {
//         setIsProcessing(true);
//         try {
//             const response = await fetch(`${NGROK_BASE_URL}/api/transform/${action}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ text: content })
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 onChange(result.transformed_text);
//                 addToHistory(result.transformed_text);
//             }
//         } catch (err) {
//             console.error('Quick action failed:', err);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     // History management
//     const addToHistory = useCallback((newContent) => {
//         setHistory(prev => {
//             const newHistory = prev.slice(0, historyIndex + 1);
//             newHistory.push(newContent);
//             return newHistory;
//         });
//         setHistoryIndex(prev => prev + 1);
//     }, [historyIndex]);

//     const handleUndo = useCallback(() => {
//         if (historyIndex > 0) {
//             setHistoryIndex(prev => prev - 1);
//             onChange(history[historyIndex - 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const handleRedo = useCallback(() => {
//         if (historyIndex < history.length - 1) {
//             setHistoryIndex(prev => prev + 1);
//             onChange(history[historyIndex + 1]);
//         }
//     }, [history, historyIndex, onChange]);

//     const restoreOriginal = useCallback(() => {
//         onChange(originalText);
//         addToHistory(originalText);
//     }, [originalText, onChange, addToHistory]);

//     // Auto bullet handling
//     const handleKeyDown = useCallback((e) => {
//         if (autoBulletEnabled && e.key === 'Enter') {
//             e.preventDefault();
//             const lines = content.split('\n');
//             const lastLine = lines[lines.length - 1];
//             const bulletMatch = lastLine.match(/^(\s*)([-•*])\s/);

//             if (bulletMatch) {
//                 const [, spacing, bullet] = bulletMatch;
//                 const isEmpty = lastLine.trim() === bullet.trim();

//                 if (isEmpty) {
//                     lines.pop();
//                 } else {
//                     lines.push(`${spacing}${bullet} `);
//                 }

//                 const newContent = lines.join('\n');
//                 onChange(newContent);
//                 addToHistory(newContent);
//             }
//         }
//     }, [autoBulletEnabled, content, onChange, addToHistory]);

//     const handleAIApply = async (preset, type = 'transform') => {
//         if (!preset) return;
//         setIsProcessing(true);

//         try {
//             const selection = window.getSelection();
//             const selectedText = selection?.toString() || '';
//             const textToProcess = selectedText || content;

//             let endpoint;
//             switch (type) {
//                 case 'case':
//                     endpoint = `/api/case-text/${preset}`;
//                     break;
//                 case 'format':
//                     endpoint = `/api/format-text/${preset}`;
//                     break;
//                 case 'list':
//                     endpoint = `/api/list-text/${preset}`;
//                     break;
//                 default:
//                     endpoint = `/api/transform/${preset}`;
//             }

//             const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     text: textToProcess,
//                     style: preset
//                 })
//             });

//             if (!response.ok) {
//                 const error = await response.json();
//                 throw new Error(error.detail || 'AI processing failed');
//             }

//             const result = await response.json();
//             const newContent = selectedText
//                 ? content.replace(selectedText, result.transformed_text)
//                 : result.transformed_text;

//             onChange(newContent);
//             addToHistory(newContent);
//         } catch (err) {
//             console.error('AI processing failed:', err);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const renderStyleSelector = () => {
//         const options = {
//             text: STYLE_UI_CONFIG.TEXT_PRESETS,
//             case: STYLE_UI_CONFIG.CASE_STYLES,
//             format: STYLE_UI_CONFIG.FORMAT_STYLES,
//             list: STYLE_UI_CONFIG.LIST_STYLES
//         }[activeTab] || [];

//         return (
//             <select
//                 className="px-3 py-1 border rounded min-w-[200px] bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                 onChange={(e) => handleAIApply(e.target.value, activeTab)}
//                 value={selectedFormat || ''}
//             >
//                 <option value="">Select Style</option>
//                 {options.map(option => (
//                     <option key={option.value} value={option.value}>
//                         {option.label}
//                     </option>
//                 ))}
//             </select>
//         );
//     };

//     return (
//         <div className="border rounded-lg bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 shadow-sm">
//             <div className="border-b dark:border-gray-700 p-2 space-y-2">
//                 <div className="flex items-center gap-4">
//                     <ControlGroup title="Format">
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.bold ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('bold')}
//                             title="Bold"
//                         >
//                             <Bold size={18} className={`${textFormat.bold ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.italic ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('italic')}
//                             title="Italic"
//                         >
//                             <Italic size={18} className={`${textFormat.italic ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                         <button
//                             className={`p-1 rounded tooltip ${textFormat.underline ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => handleFormat('underline')}
//                             title="Underline"
//                         >
//                             <Underline size={18} className={`${textFormat.underline ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="Auto-Bullet">
//                         <button
//                             className={`p-1 rounded tooltip ${autoBulletEnabled ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
//                             onClick={() => setAutoBulletEnabled(!autoBulletEnabled)}
//                             title="Toggle Auto-Bullet"
//                         >
//                             <List size={18} className={`${autoBulletEnabled ? '' : 'text-gray-700 dark:text-gray-200'}`} />
//                         </button>
//                     </ControlGroup>

//                     <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

//                     <ControlGroup title="History">
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleUndo}
//                             disabled={historyIndex === 0}
//                             title="Undo"
//                         >
//                             <RotateCcw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
//                             onClick={handleRedo}
//                             disabled={historyIndex === history.length - 1}
//                             title="Redo"
//                         >
//                             <RotateCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                         <button
//                             className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip"
//                             onClick={restoreOriginal}
//                             title="Restore Original"
//                         >
//                             <RefreshCw size={18} className="text-gray-700 dark:text-gray-200" />
//                         </button>
//                     </ControlGroup>
//                 </div>

//                 <div className="flex items-center gap-4">
//                     <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'text' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('text')}
//                         >
//                             <Type size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Text</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'case' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('case')}
//                         >
//                             <Hash size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Case</span>
//                         </button>
//                         <button
//                             className={`p-1 rounded flex items-center gap-1 ${activeTab === 'format' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
//                             onClick={() => setActiveTab('format')}
//                         >
//                             <Layout size={18} className="text-gray-700 dark:text-gray-200" />
//                             <span className="text-sm text-gray-700 dark:text-gray-200">Format</span>
//                         </button>
//                     </div>

//                     {renderStyleSelector()}

//                     <button
//                         onClick={() => selectedFormat && handleAIApply(selectedFormat)}
//                         disabled={isProcessing || !selectedFormat}
//                         className={`
//                             px-3 py-1 rounded flex items-center gap-2
//                             ${isProcessing || !selectedFormat
//                                 ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-400'
//                                 : 'bg-blue-500 hover:bg-blue-600 text-white'
//                             }
//                         `}
//                     >
//                         {isProcessing ? (
//                             <>
//                                 <Loader2 className="w-4 h-4 animate-spin" />
//                                 <span>Processing...</span>
//                             </>
//                         ) : (
//                             <>
//                                 <Sparkles className="w-4 h-4" />
//                                 <span>Apply Transform</span>
//                             </>
//                         )}
//                     </button>
//                 </div>
//             </div>

//             <div className="p-2 bg-white dark:bg-gray-800">
//                 <textarea
//                     ref={editorRef}
//                     value={content}
//                     onChange={(e) => {
//                         onChange(e.target.value);
//                         addToHistory(e.target.value);
//                     }}
//                     onKeyDown={handleKeyDown}
//                     className="w-full min-h-[200px] p-2 border rounded resize-y focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
//                     placeholder="Enter text to transform..."
//                 />
//             </div>

//             {/* Recruiter Quick Actions */}
//             <div className="border-t border-gray-200 dark:border-gray-700 p-4">
//                 <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
//                     Quick Actions for Resumes
//                 </h3>
//                 <div className="grid grid-cols-5 gap-3">
//                     {RECRUITER_ACTIONS.map(({ icon, label, action, description }) => (
//                         <button
//                             key={action}
//                             onClick={() => handleQuickAction(action)}
//                             disabled={isProcessing}
//                             className={`
//                                 flex flex-col items-center justify-center p-3 rounded-lg
//                                 border border-gray-200 dark:border-gray-600
//                                 ${isProcessing
//                                     ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
//                                     : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
//                                 }
//                                 transition-all duration-200
//                             `}
//                             title={description}
//                         >
//                             <span className="text-2xl mb-2">{icon}</span>
//                             <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
//                                 {label}
//                             </span>
//                         </button>
//                     ))}
//                 </div>
//             </div>

//             {/* Advanced Settings */}
//             <div className="p-2 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 flex items-center justify-between">
//                 <span className="text-gray-700 dark:text-gray-200 font-medium">Advanced Settings</span>
//                 <button
//                     onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
//                     className="px-2 py-1 text-sm bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
//                 >
//                     {showAdvancedSettings ? 'Hide' : 'Show'}
//                 </button>
//             </div>

//             {showAdvancedSettings && (
//                 <div className="p-2 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-700 space-y-2">
//                     <div className="flex items-center justify-between">
//                         <label className="text-gray-700 dark:text-gray-200">Line Height</label>
//                         <input
//                             type="number"
//                             className="w-16 p-1 border border-gray-300 dark:border-gray-500 rounded bg-white dark:bg-gray-600 text-gray-900 dark:text-gray-100"
//                             defaultValue={1.5}
//                             step={0.1}
//                         />
//                     </div>
//                     <div className="flex items-center justify-between">
//                         <label className="text-gray-700 dark:text-gray-200">Font Size</label>
//                         <input
//                             type="number"
//                             className="w-16 p-1 border border-gray-300 dark:border-gray-500 rounded bg-white dark:bg-gray-600 text-gray-900 dark:text-gray-100"
//                             defaultValue={14}
//                         />
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default TextEditor;

import React, { useState, useCallback, useRef } from 'react';
import {
    Bold, Italic, Underline, List, RotateCcw, RotateCw,
    Loader2, Sparkles, Type, Layout,
    Hash, RefreshCw
} from 'lucide-react';
import { STYLE_UI_CONFIG } from '../constants';

const NGROK_BASE_URL = 'https://e0a0-74-88-7-3.ngrok-free.app';

const RECRUITER_ACTIONS = [
    { icon: "📝", label: "Condense", action: "condense", description: "Make content more concise" },
    { icon: "👤", label: "3rd Person", action: "third-person", description: "Convert to third person voice" },
    { icon: "✨", label: "Expand", action: "expand", description: "Add more detail" },
    { icon: "🔧", label: "Fix Words", action: "fix_words", description: "Improve word choice" },
    { icon: "📋", label: "Bullets", action: "list", description: "Convert to bullet points" }
];

const ControlGroup = ({ title, children }) => (
    <div className="flex items-center gap-2">
        <span className="text-sm text-gray-500 dark:text-gray-400 font-medium">{title}</span>
        <div className="flex items-center gap-1 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
            {children}
        </div>
    </div>
);

const TextEditor = ({ content, onChange }) => {
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [history, setHistory] = useState([content]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [activeTab, setActiveTab] = useState('text');
    const [autoBulletEnabled, setAutoBulletEnabled] = useState(true);
    const [originalText, setOriginalText] = useState(content);
    const [textFormat, setTextFormat] = useState({
        bold: false,
        italic: false,
        underline: false
    });

    const editorRef = useRef(null);

    const handleFormat = (type) => {
        setTextFormat(prev => ({
            ...prev,
            [type]: !prev[type]
        }));

        const selection = window.getSelection();
        const selectedText = selection?.toString();
        if (selectedText) {
            const newText = content.replace(selectedText, formatText(selectedText, type));
            onChange(newText);
            addToHistory(newText);
        }
    };

    const formatText = (text, type) => {
        switch (type) {
            case 'bold':
                return `**${text}**`;
            case 'italic':
                return `*${text}*`;
            case 'underline':
                return `_${text}_`;
            default:
                return text;
        }
    };

    const handleQuickAction = async (action) => {
        setIsProcessing(true);
        try {
            let endpoint;
            switch (action) {
                case 'condense':
                case '3rd_person':
                case 'expand':
                case 'fix_words':
                    endpoint = `/api/transform/${action}`;
                    break;
                case 'list':
                    endpoint = `/api/list-text/bullet`;
                    break;
                default:
                    endpoint = `/api/transform/${action}`;
            }

            const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text: content })
            });

            if (response.ok) {
                const result = await response.json();
                onChange(result.transformed_text);
                addToHistory(result.transformed_text);
            } else {
                // Log error details if the request fails
                const errorDetails = await response.text();
                console.error('Quick action failed:', errorDetails);
            }
        } catch (err) {
            console.error('Quick action error:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    const addToHistory = useCallback((newContent) => {
        setHistory(prev => {
            const newHistory = prev.slice(0, historyIndex + 1);
            newHistory.push(newContent);
            return newHistory;
        });
        setHistoryIndex(prev => prev + 1);
    }, [historyIndex]);

    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            setHistoryIndex(prev => prev - 1);
            onChange(history[historyIndex - 1]);
        }
    }, [history, historyIndex, onChange]);

    const handleRedo = useCallback(() => {
        if (historyIndex < history.length - 1) {
            setHistoryIndex(prev => prev + 1);
            onChange(history[historyIndex + 1]);
        }
    }, [history, historyIndex, onChange]);

    const restoreOriginal = useCallback(() => {
        onChange(originalText);
        addToHistory(originalText);
    }, [originalText, onChange, addToHistory]);

    const handleKeyDown = useCallback((e) => {
        if (autoBulletEnabled && e.key === 'Enter') {
            e.preventDefault();
            const lines = content.split('\n');
            const lastLine = lines[lines.length - 1];
            const bulletMatch = lastLine.match(/^(\s*)([-•*])\s/);

            if (bulletMatch) {
                const [, spacing, bullet] = bulletMatch;
                const isEmpty = lastLine.trim() === bullet.trim();

                if (isEmpty) {
                    lines.pop();
                } else {
                    lines.push(`${spacing}${bullet} `);
                }

                const newContent = lines.join('\n');
                onChange(newContent);
                addToHistory(newContent);
            }
        }
    }, [autoBulletEnabled, content, onChange, addToHistory]);

    const handleAIApply = async (preset, type = 'transform') => {
        if (!preset) return;
        setIsProcessing(true);

        try {
            const selection = window.getSelection();
            const selectedText = selection?.toString() || '';
            const textToProcess = selectedText || content;

            let endpoint;
            switch (type) {
                case 'case':
                    endpoint = `/api/case-text/${preset}`;
                    break;
                case 'format':
                    endpoint = `/api/format-text/${preset}`;
                    break;
                case 'list':
                    endpoint = `/api/list-text/${preset}`;
                    break;
                default:
                    endpoint = `/api/transform/${preset}`;
            }

            const response = await fetch(`${NGROK_BASE_URL}${endpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    text: textToProcess,
                    style: preset
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || 'AI processing failed');
            }

            const result = await response.json();
            const newContent = selectedText
                ? content.replace(selectedText, result.transformed_text)
                : result.transformed_text;

            onChange(newContent);
            addToHistory(newContent);
        } catch (err) {
            console.error('AI processing failed:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    const renderStyleSelector = () => {
        const options = {
            text: STYLE_UI_CONFIG.TEXT_PRESETS,
            case: STYLE_UI_CONFIG.CASE_STYLES,
            format: STYLE_UI_CONFIG.FORMAT_STYLES,
            list: STYLE_UI_CONFIG.LIST_STYLES
        }[activeTab] || [];

        return (
            <select
                className="px-3 py-1 border rounded min-w-[200px] bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
                onChange={(e) => handleAIApply(e.target.value, activeTab)}
                value={selectedFormat || ''}
            >
                <option value="">Select Style</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    };

    return (
        <div className="border rounded-lg bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 shadow-sm">
            <div className="border-b dark:border-gray-700 p-2 space-y-2">
                <div className="flex items-center gap-4">
                    <ControlGroup title="Format">
                        <button
                            className={`p-1 rounded tooltip ${textFormat.bold ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('bold')}
                            title="Bold"
                        >
                            <Bold size={18} className={`${textFormat.bold ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                        <button
                            className={`p-1 rounded tooltip ${textFormat.italic ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('italic')}
                            title="Italic"
                        >
                            <Italic size={18} className={`${textFormat.italic ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                        <button
                            className={`p-1 rounded tooltip ${textFormat.underline ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => handleFormat('underline')}
                            title="Underline"
                        >
                            <Underline size={18} className={`${textFormat.underline ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                    </ControlGroup>

                    <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

                    <ControlGroup title="Auto-Bullet">
                        <button
                            className={`p-1 rounded tooltip ${autoBulletEnabled ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300' : 'hover:bg-gray-200 dark:hover:bg-gray-700'}`}
                            onClick={() => setAutoBulletEnabled(!autoBulletEnabled)}
                            title="Toggle Auto-Bullet"
                        >
                            <List size={18} className={`${autoBulletEnabled ? '' : 'text-gray-700 dark:text-gray-200'}`} />
                        </button>
                    </ControlGroup>

                    <div className="h-full w-px bg-gray-200 dark:bg-gray-700" />

                    <ControlGroup title="History">
                        <button
                            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
                            onClick={handleUndo}
                            disabled={historyIndex === 0}
                            title="Undo"
                        >
                            <RotateCcw size={18} className="text-gray-700 dark:text-gray-200" />
                        </button>
                        <button
                            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip disabled:opacity-50"
                            onClick={handleRedo}
                            disabled={historyIndex === history.length - 1}
                            title="Redo"
                        >
                            <RotateCw size={18} className="text-gray-700 dark:text-gray-200" />
                        </button>
                        <button
                            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded tooltip"
                            onClick={restoreOriginal}
                            title="Restore Original"
                        >
                            <RefreshCw size={18} className="text-gray-700 dark:text-gray-200" />
                        </button>
                    </ControlGroup>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 p-1 rounded-md">
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'text' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('text')}
                        >
                            <Type size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Text</span>
                        </button>
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'case' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('case')}
                        >
                            <Hash size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Case</span>
                        </button>
                        <button
                            className={`p-1 rounded flex items-center gap-1 ${activeTab === 'format' ? 'bg-white dark:bg-gray-600 shadow' : 'hover:bg-gray-200 dark:hover:bg-gray-600'}`}
                            onClick={() => setActiveTab('format')}
                        >
                            <Layout size={18} className="text-gray-700 dark:text-gray-200" />
                            <span className="text-sm text-gray-700 dark:text-gray-200">Format</span>
                        </button>
                    </div>

                    {renderStyleSelector()}

                    <button
                        onClick={() => selectedFormat && handleAIApply(selectedFormat)}
                        disabled={isProcessing || !selectedFormat}
                        className={`
                            px-3 py-1 rounded flex items-center gap-2
                            ${isProcessing || !selectedFormat
                                ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-400'
                                : 'bg-blue-500 hover:bg-blue-600 text-white'
                            }
                        `}
                    >
                        {isProcessing ? (
                            <>
                                <Loader2 className="w-4 h-4 animate-spin" />
                                <span>Processing...</span>
                            </>
                        ) : (
                            <>
                                <Sparkles className="w-4 h-4" />
                                <span>Apply Transform</span>
                            </>
                        )}
                    </button>
                </div>
            </div>

            <div className="p-2 bg-white dark:bg-gray-800">
                <textarea
                    ref={editorRef}
                    value={content}
                    onChange={(e) => {
                        onChange(e.target.value);
                        addToHistory(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    className="w-full min-h-[200px] p-2 border rounded resize-y focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
                    placeholder="Enter text to transform..."
                />
            </div>

            {/* Recruiter Quick Actions */}
            <div className="border-t border-gray-200 dark:border-gray-700 p-4">
                <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                    Quick Actions for Resumes
                </h3>
                <div className="grid grid-cols-5 gap-3">
                    {RECRUITER_ACTIONS.map(({ icon, label, action, description }) => (
                        <button
                            key={action}
                            onClick={() => handleQuickAction(action)}
                            disabled={isProcessing}
                            className={`
                                flex flex-col items-center justify-center p-3 rounded-lg
                                border border-gray-200 dark:border-gray-600
                                ${isProcessing
                                    ? 'bg-gray-50 dark:bg-gray-700 cursor-not-allowed'
                                    : 'hover:bg-blue-50 dark:hover:bg-blue-900/30 hover:border-blue-300 dark:hover:border-blue-500'
                                }
                                transition-all duration-200
                            `}
                            title={description}
                        >
                            <span className="text-2xl mb-2">{icon}</span>
                            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                {label}
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TextEditor;