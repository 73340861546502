import React from 'react';

const ResumePreviewPanel = ({ fileUrl, fileType }) => {
    return (
        <div className="border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 p-4 h-full flex flex-col">
            <div className="flex space-x-2 mb-4">
                <button className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">
                    Original Resume Preview
                </button>
            </div>

            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 flex-1 overflow-auto">
                <div
                    className="scale-container"
                    style={{ transform: 'scale(1)', transformOrigin: 'top left', width: '100%', height: '100%' }}
                >
                    {fileType?.includes('pdf') ? (
                        <iframe
                            src={fileUrl}
                            className="w-full h-full"
                            title="Original Resume"
                        />
                    ) : (
                        <img
                            src={fileUrl}
                            alt="Original Resume"
                            className="w-full h-auto"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResumePreviewPanel;
