// import React, { useState, useEffect } from 'react';
// import {
//     Settings,
//     Building,
//     CreditCard,
//     Users,
//     Key,
//     Clock,
//     DollarSign,
//     Shield,
//     AlertCircle,
//     Phone,
//     FileText,
//     CheckCircle,
//     Download as DownloadIcon,
//     RefreshCw
// } from 'lucide-react';

// // Sample organization data
// const orgData = {
//     id: "org_12345",
//     name: "Healthcare Solutions Inc.",
//     license_id: "lic_789012",
//     plan: "Enterprise",
//     billing_cycle: "Monthly",
//     created_at: "2023-09-15",
//     api_key: "sk_live_abc123xyz789...",
//     subscription: {
//         status: "active",
//         next_billing_date: "2024-04-01",
//         amount: 499.99,
//         features: ["Unlimited Calls", "Custom Voice Training", "Priority Support"]
//     },
//     billing_history: [
//         {
//             id: "inv_123",
//             date: "2024-03-01",
//             amount: 499.99,
//             status: "paid",
//             items: ["Monthly Subscription", "Additional Minutes (1000)"]
//         },
//         {
//             id: "inv_122",
//             date: "2024-02-01",
//             amount: 449.99,
//             status: "paid",
//             items: ["Monthly Subscription"]
//         }
//     ],
//     usage_statistics: {
//         total_calls: 15780,
//         total_minutes: 47340,
//         active_numbers: 12,
//         month_to_date_cost: 1247.50
//     },
//     team_members: [
//         {
//             id: "usr_123",
//             name: "John Smith",
//             email: "john@healthcare-solutions.com",
//             role: "Admin",
//             last_active: "2024-03-15T14:30:00Z"
//         },
//         {
//             id: "usr_124",
//             name: "Sarah Johnson",
//             email: "sarah@healthcare-solutions.com",
//             role: "Manager",
//             last_active: "2024-03-15T13:15:00Z"
//         }
//     ]
// };

// const SettingsDashboard = () => {
//     const [activeSection, setActiveSection] = useState('organization');
//     const [isLoading, setIsLoading] = useState(false);

//     const sections = [
//         { id: 'organization', label: 'Organization', icon: Building },
//         { id: 'billing', label: 'Billing & Usage', icon: CreditCard },
//         { id: 'team', label: 'Team Members', icon: Users },
//         { id: 'security', label: 'Security', icon: Shield },
//         { id: 'api', label: 'API Settings', icon: Key }
//     ];

//     return (
//         <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-black dark:text-gray-200 p-6">
//             <div className="max-w-7xl mx-auto">
//                 {/* Header */}
//                 <div className="mb-8">
//                     <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">Settings</h1>
//                     <p className="text-gray-600 dark:text-gray-300 mt-1">Manage your organization and account settings</p>
//                 </div>

//                 <div className="flex gap-6">
//                     {/* Sidebar */}
//                     <div className="w-64">
//                         <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
//                             <div className="p-4 border-b border-gray-200 dark:border-gray-700">
//                                 <div className="flex items-center space-x-2">
//                                     <Building className="w-5 h-5 text-gray-400 dark:text-gray-300" />
//                                     <span className="font-medium text-gray-900 dark:text-gray-100">{orgData.name}</span>
//                                 </div>
//                                 <div className="mt-1 text-sm text-gray-500 dark:text-gray-400">
//                                     ID: {orgData.id}
//                                 </div>
//                             </div>
//                             <nav className="p-2 space-y-1">
//                                 {sections.map(item => (
//                                     <button
//                                         key={item.id}
//                                         onClick={() => setActiveSection(item.id)}
//                                         className={`w-full flex items-center space-x-2 px-3 py-2 rounded-lg text-sm transition-colors
//                                             ${activeSection === item.id
//                                                 ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-l-4 border-blue-500'
//                                                 : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'}`}
//                                     >
//                                         <item.icon className="w-4 h-4" />
//                                         <span>{item.label}</span>
//                                     </button>
//                                 ))}
//                             </nav>
//                         </div>

//                         {/* Plan Information */}
//                         <div className="mt-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4">
//                             <div className="flex justify-between items-center mb-2">
//                                 <span className="text-sm font-medium text-gray-900 dark:text-gray-100">Current Plan</span>
//                                 <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 dark:bg-blue-900/40 text-blue-800 dark:text-blue-200">
//                                     {orgData.plan}
//                                 </span>
//                             </div>
//                             <div className="text-sm text-gray-500 dark:text-gray-400 mb-3">
//                                 Next Billing: {orgData.subscription.next_billing_date}
//                             </div>
//                             <button className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
//                                 Manage Subscription
//                             </button>
//                         </div>
//                     </div>

//                     {/* Main Content */}
//                     <div className="flex-1 space-y-6">
//                         {/* Organization Details */}
//                         {activeSection === 'organization' && (
//                             <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
//                                 <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
//                                     <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Organization Details</h2>
//                                 </div>
//                                 <div className="p-6 space-y-6">
//                                     <div className="grid grid-cols-2 gap-6">
//                                         <div>
//                                             <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
//                                                 Organization Name
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 value={orgData.name}
//                                                 className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-gray-200"
//                                                 readOnly
//                                             />
//                                         </div>
//                                         <div>
//                                             <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
//                                                 License ID
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 value={orgData.license_id}
//                                                 className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-gray-200"
//                                                 readOnly
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Billing & Usage */}
//                         {activeSection === 'billing' && (
//                             <div className="space-y-6">
//                                 {/* Usage Statistics */}
//                                 <div className="grid grid-cols-4 gap-4">
//                                     {[
//                                         { label: "Total Calls", value: orgData.usage_statistics.total_calls, icon: Phone },
//                                         { label: "Total Minutes", value: orgData.usage_statistics.total_minutes, icon: Clock },
//                                         { label: "Active Numbers", value: orgData.usage_statistics.active_numbers, icon: Phone },
//                                         { label: "MTD Cost", value: `$${orgData.usage_statistics.month_to_date_cost}`, icon: DollarSign }
//                                     ].map((stat, index) => (
//                                         <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4">
//                                             <div className="flex items-center justify-between">
//                                                 <stat.icon className="w-5 h-5 text-gray-400 dark:text-gray-300" />
//                                                 <span className="text-sm text-gray-500 dark:text-gray-400">{stat.label}</span>
//                                             </div>
//                                             <div className="mt-2 text-2xl font-semibold text-gray-900 dark:text-gray-100">
//                                                 {stat.value}
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>

//                                 {/* Billing History */}
//                                 <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
//                                     <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
//                                         <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Billing History</h2>
//                                     </div>
//                                     <div className="overflow-x-auto">
//                                         <table className="w-full text-black dark:text-gray-200">
//                                             <thead>
//                                                 <tr className="bg-gray-50 dark:bg-gray-700">
//                                                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Date</th>
//                                                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Invoice</th>
//                                                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Amount</th>
//                                                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Status</th>
//                                                     <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Action</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
//                                                 {orgData.billing_history.map(invoice => (
//                                                     <tr key={invoice.id} className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors">
//                                                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
//                                                             {invoice.date}
//                                                         </td>
//                                                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
//                                                             {invoice.id}
//                                                         </td>
//                                                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
//                                                             ${invoice.amount}
//                                                         </td>
//                                                         <td className="px-6 py-4 whitespace-nowrap">
//                                                             <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900/40 text-green-800 dark:text-green-200">
//                                                                 {invoice.status}
//                                                             </span>
//                                                         </td>
//                                                         <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
//                                                             <button className="text-blue-600 dark:text-blue-400 hover:text-blue-900 dark:hover:text-blue-200 transition-colors">
//                                                                 Download
//                                                             </button>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Team Members */}
//                         {activeSection === 'team' && (
//                             <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
//                                 <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
//                                     <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Team Members</h2>
//                                     <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors">
//                                         Add Member
//                                     </button>
//                                 </div>
//                                 <div className="overflow-x-auto">
//                                     <table className="w-full text-black dark:text-gray-200">
//                                         <thead>
//                                             <tr className="bg-gray-50 dark:bg-gray-700">
//                                                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Name</th>
//                                                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Email</th>
//                                                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Role</th>
//                                                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Last Active</th>
//                                                 <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Actions</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
//                                             {orgData.team_members.map(member => (
//                                                 <tr key={member.id} className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors">
//                                                     <td className="px-6 py-4 whitespace-nowrap">
//                                                         <div className="flex items-center">
//                                                             <div className="h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
//                                                                 <span className="text-sm font-medium text-gray-600 dark:text-gray-200">
//                                                                     {member.name.split(' ').map(n => n[0]).join('')}
//                                                                 </span>
//                                                             </div>
//                                                             <div className="ml-4">
//                                                                 <div className="text-sm font-medium text-gray-900 dark:text-gray-100">{member.name}</div>
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
//                                                         {member.email}
//                                                     </td>
//                                                     <td className="px-6 py-4 whitespace-nowrap">
//                                                         <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200">
//                                                             {member.role}
//                                                         </span>
//                                                     </td>
//                                                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
//                                                         {new Date(member.last_active).toLocaleString()}
//                                                     </td>
//                                                     <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
//                                                         <button className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 transition-colors">
//                                                             Edit
//                                                         </button>
//                                                     </td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Security */}
//                         {activeSection === 'security' && (
//                             <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
//                                 <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Security Settings</h2>
//                                 <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
//                                     Configure 2FA, password policies, and other security measures.
//                                 </p>
//                                 {/* Add your security-related fields here */}
//                             </div>
//                         )}

//                         {/* API Settings */}
//                         {activeSection === 'api' && (
//                             <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
//                                 <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">API Settings</h2>
//                                 <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
//                                     Manage API keys and configurations for your integration.
//                                 </p>
//                                 {/* Add your API settings here */}
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SettingsDashboard;




import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../../supabaseClient';
import {
    Settings,
    Building,
    CreditCard,
    Users,
    Key,
    Clock,
    DollarSign,
    Shield,
    AlertCircle,
    Phone,
    FileText,
    CheckCircle,
    Download as DownloadIcon,
    RefreshCw,
    Copy,
    CheckCircle2
} from 'lucide-react';

// API Key Modal Component
const ApiKeyModal = ({ isOpen, onClose, apiKey }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(apiKey);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose}></div>

                <div className="relative bg-white rounded-lg p-8 max-w-lg w-full">
                    <h3 className="text-xl font-semibold mb-4">API Key</h3>
                    <div className="flex items-center gap-2 mb-4">
                        <input
                            type="text"
                            value={apiKey}
                            readOnly
                            className="flex-1 p-2 border rounded bg-gray-50"
                        />
                        <button
                            onClick={handleCopy}
                            className="px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2"
                        >
                            {copied ? (
                                <>
                                    <CheckCircle2 className="w-4 h-4" />
                                    <span>Copied!</span>
                                </>
                            ) : (
                                <>
                                    <Copy className="w-4 h-4" />
                                    <span>Copy</span>
                                </>
                            )}
                        </button>
                    </div>
                    <button
                        onClick={onClose}
                        className="w-full mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

// Add this component before the main SettingsDashboard component
const InviteModal = ({ isOpen, onClose, onInvite, email, setEmail }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose}></div>

                <div className="relative bg-white dark:bg-gray-800 rounded-lg p-8 max-w-lg w-full">
                    <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
                        Invite Team Member
                    </h3>
                    <form onSubmit={onInvite}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                Email Address
                            </label>
                            <input
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg"
                                placeholder="Enter email address"
                            />
                        </div>
                        <div className="flex gap-2">
                            <button
                                type="submit"
                                className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                            >
                                Send Invite
                            </button>
                            <button
                                type="button"
                                onClick={onClose}
                                className="flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const SettingsDashboard = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('organization');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showApiModal, setShowApiModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    // State for organization data
    const [orgData, setOrgData] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [billingHistory, setBillingHistory] = useState([]);
    const [usageStats, setUsageStats] = useState(null);

    const sections = [
        { id: 'organization', label: 'Organization', icon: Building },
        { id: 'billing', label: 'Billing & Usage', icon: CreditCard },
        { id: 'team', label: 'Team Members', icon: Users },
        { id: 'security', label: 'Security', icon: Shield },
        { id: 'api', label: 'API Settings', icon: Key }
    ];


    const handleRemoveMember = async (memberId) => {
        try {
            const { error } = await supabase
                .from('agency_members')
                .delete()
                .eq('id', memberId);

            if (error) throw error;
            await fetchOrganizationData(); // Refresh data
        } catch (err) {
            console.error('Error removing member:', err);
            setError(err.message);
        }
    };


    useEffect(() => {
        fetchOrganizationData();
    }, []);

    const fetchOrganizationData = async () => {
        try {
            setIsLoading(true);
            const { data: { user } } = await supabase.auth.getUser();

            if (!user) {
                navigate('/');
                return;
            }

            // Get user's agency
            const { data: membership, error: membershipError } = await supabase
                .from('agency_members')
                .select(`
                    *,
                    agency:agencies(*)
                `)
                .eq('user_id', user.id)
                .single();

            if (membershipError) throw membershipError;

            const agency = membership.agency;
            setOrgData({
                id: agency.id,
                name: agency.name,
                api_key: agency.api_key,
                plan: agency.subscription_status,
                created_at: agency.created_at,
                trial_end_date: agency.trial_end_date,
                resume_limit: agency.resume_limit
            });

            // Fetch team members
            const { data: members, error: membersError } = await supabase
                .from('agency_members')
                .select('*')  // Simplified query to get all fields from agency_members
                .eq('agency_id', agency.id);

            if (membersError) throw membersError;
            setTeamMembers(members);

            // Fetch usage statistics
            const { data: usage, error: usageError } = await supabase
                .from('agency_usage')
                .select('*')
                .eq('agency_id', agency.id)
                .single();

            if (!usageError && usage) {
                setUsageStats({
                    total_resumes: usage.total_resumes || 0,
                    monthly_parsed: usage.monthly_parsed || 0,
                    active_users: members.length,
                    remaining_credits: agency.resume_limit
                        ? agency.resume_limit - (usage.monthly_parsed || 0)
                        : 'Unlimited'
                });
            }

            // Fetch billing history
            const { data: billing, error: billingError } = await supabase
                .from('agency_billing')
                .select('*')
                .eq('agency_id', agency.id)
                .order('created_at', { ascending: false })
                .limit(10);

            if (!billingError && billing) {
                setBillingHistory(billing);
            }

        } catch (err) {
            console.error('Error fetching organization data:', err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowApiKey = () => {
        setShowApiModal(true);
    };

    const handleInviteMember = async (email) => {
        try {
            const { error } = await supabase
                .from('agency_members')
                .insert([{
                    agency_id: orgData.id,
                    email,
                    role: 'member',
                    status: 'pending'
                }]);

            if (error) throw error;
            await fetchOrganizationData();
        } catch (err) {
            console.error('Error inviting member:', err);
            setError(err.message);
        }
    };

    const handleUpdateMemberRole = async (memberId, newRole) => {
        try {
            const { error } = await supabase
                .from('agency_members')
                .update({ role: newRole })
                .eq('id', memberId);

            if (error) throw error;
            await fetchOrganizationData();
        } catch (err) {
            console.error('Error updating member role:', err);
            setError(err.message);
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
                    <p className="mt-4 text-gray-600 dark:text-gray-300">Loading settings...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 max-w-md w-full">
                    <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-center mb-2">Error Loading Settings</h3>
                    <p className="text-gray-600 dark:text-gray-300 text-center mb-4">{error}</p>
                    <button
                        onClick={fetchOrganizationData}
                        className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-black dark:text-gray-200 p-6">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">Settings</h1>
                    <p className="text-gray-600 dark:text-gray-300 mt-1">Manage your organization and account settings</p>
                </div>

                <div className="flex gap-6">
                    {/* Sidebar */}
                    <div className="w-64">
                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                                <div className="flex items-center space-x-2">
                                    <Building className="w-5 h-5 text-gray-400 dark:text-gray-300" />
                                    <span className="font-medium text-gray-900 dark:text-gray-100">
                                        {orgData?.name}
                                    </span>
                                </div>
                                <div className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                    ID: {orgData?.id}
                                </div>
                            </div>
                            <nav className="p-2 space-y-1">
                                {sections.map(item => (
                                    <button
                                        key={item.id}
                                        onClick={() => setActiveSection(item.id)}
                                        className={`w-full flex items-center space-x-2 px-3 py-2 rounded-lg text-sm transition-colors
                                            ${activeSection === item.id
                                                ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 border-l-4 border-blue-500'
                                                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'}`}
                                    >
                                        <item.icon className="w-4 h-4" />
                                        <span>{item.label}</span>
                                    </button>
                                ))}
                            </nav>
                        </div>

                        {/* Plan Information */}
                        <div className="mt-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4">
                            <div className="flex justify-between items-center mb-2">
                                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                    Current Plan
                                </span>
                                <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 dark:bg-blue-900/40 text-blue-800 dark:text-blue-200">
                                    {orgData?.plan}
                                </span>
                            </div>
                            <div className="text-sm text-gray-500 dark:text-gray-400 mb-3">
                                {orgData?.trial_end_date ? (
                                    <>Trial ends: {new Date(orgData.trial_end_date).toLocaleDateString()}</>
                                ) : (
                                    'Active Subscription'
                                )}
                            </div>
                            <button
                                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
                                onClick={() => setActiveSection('billing')}
                            >
                                Manage Subscription
                            </button>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="flex-1 space-y-6">
                        {/* Organization Details */}
                        {activeSection === 'organization' && (
                            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                        Organization Details
                                    </h2>
                                </div>
                                <div className="p-6 space-y-6">
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Organization Name
                                            </label>
                                            <input
                                                type="text"
                                                value={orgData?.name}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-gray-200"
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Created On
                                            </label>
                                            <input
                                                type="text"
                                                value={new Date(orgData?.created_at).toLocaleDateString()}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-black dark:text-gray-200"
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                API Key
                                            </label>
                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    value="••••••••••••••••••••"
                                                    className="flex-1 block px-3 py-2 border border-r-0 border-gray-300 dark:border-gray-600 rounded-l-lg bg-white dark:bg-gray-700 text-black dark:text-gray-200"
                                                    readOnly
                                                />
                                                <button
                                                    onClick={handleShowApiKey}
                                                    className="px-4 py-2 border border-l-0 border-gray-300 dark:border-gray-600 rounded-r-lg bg-gray-50 dark:bg-gray-600 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-500"
                                                >
                                                    Show
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Usage Statistics */}
                        {activeSection === 'billing' && (
                            <div className="space-y-6">
                                <div className="grid grid-cols-4 gap-4">
                                    {[
                                        {
                                            label: "Total Resumes",
                                            value: usageStats?.total_resumes || 0,
                                            icon: FileText
                                        },
                                        {
                                            label: "This Month",
                                            value: usageStats?.monthly_parsed || 0,
                                            icon: Clock
                                        },
                                        {
                                            label: "Team Members",
                                            value: usageStats?.active_users || 0,
                                            icon: Users
                                        },
                                        {
                                            label: "Remaining Credits",
                                            value: usageStats?.remaining_credits || 'Unlimited',
                                            icon: DollarSign
                                        }
                                    ].map((stat, index) => (
                                        <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4">
                                            <div className="flex items-center justify-between">
                                                <stat.icon className="w-5 h-5 text-gray-400 dark:text-gray-300" />
                                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                                    {stat.label}
                                                </span>
                                            </div>
                                            <div className="mt-2 text-2xl font-semibold text-gray-900 dark:text-gray-100">
                                                {stat.value}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Billing History */}
                                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                                    <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                                        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                            Billing History
                                        </h2>
                                    </div>
                                    <div className="overflow-x-auto">
                                        <table className="w-full">
                                            <thead>
                                                <tr className="bg-gray-50 dark:bg-gray-700">
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                        Date
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                        Invoice
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                        Amount
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                        Status
                                                    </th>
                                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                                                {billingHistory.map(invoice => (
                                                    <tr key={invoice.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                                            {new Date(invoice.billing_date).toLocaleDateString()}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                                                            {invoice.id}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                                            ${invoice.amount}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium
                                                                ${invoice.status === 'paid'
                                                                    ? 'bg-green-100 dark:bg-green-900/40 text-green-800 dark:text-green-200'
                                                                    : 'bg-yellow-100 dark:bg-yellow-900/40 text-yellow-800 dark:text-yellow-200'
                                                                }`}>
                                                                {invoice.status}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                                                            {invoice.invoice_url && (
                                                                <a
                                                                    href={invoice.invoice_url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-600 dark:text-blue-400 hover:text-blue-900 dark:hover:text-blue-200"
                                                                >
                                                                    Download
                                                                </a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Team Members */}
                        {activeSection === 'team' && (
                            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                        Team Members
                                    </h2>
                                    <button
                                        onClick={() => setShowInviteModal(true)}
                                        className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700"
                                    >
                                        Add Member
                                    </button>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="w-full">
                                        <thead>
                                            <tr className="bg-gray-50 dark:bg-gray-700">
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                    Name/Email
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                    Role
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                    Status
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                    Joined
                                                </th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                                            {teamMembers.map(member => (
                                                <tr key={member.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="h-8 w-8 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                                                                <span className="text-sm font-medium text-blue-800 dark:text-blue-200">
                                                                    {member.user.email[0].toUpperCase()}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4">
                                                                <div className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                                                    {member.user.email}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <select
                                                            value={member.role}
                                                            onChange={(e) => handleUpdateMemberRole(member.id, e.target.value)}
                                                            className="text-sm text-gray-900 dark:text-gray-100 bg-transparent"
                                                        >
                                                            <option value="admin">Admin</option>
                                                            <option value="member">Member</option>
                                                        </select>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium
                                                            ${member.status === 'active'
                                                                ? 'bg-green-100 dark:bg-green-900/40 text-green-800 dark:text-green-200'
                                                                : 'bg-yellow-100 dark:bg-yellow-900/40 text-yellow-800 dark:text-yellow-200'
                                                            }`}>
                                                            {member.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                                                        {new Date(member.created_at).toLocaleDateString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                                                        <button
                                                            onClick={() => handleRemoveMember(member.id)}
                                                            className="text-red-600 hover:text-red-900"
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {/* API Settings */}
                        {activeSection === 'api' && (
                            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                        API Settings
                                    </h2>
                                </div>
                                <div className="p-6 space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            API Key
                                        </label>
                                        <div className="flex rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                value="••••••••••••••••••••"
                                                className="flex-1 block px-3 py-2 border border-r-0 border-gray-300 dark:border-gray-600 rounded-l-lg bg-white dark:bg-gray-700"
                                                readOnly
                                            />
                                            <button
                                                onClick={handleShowApiKey}
                                                className="px-4 py-2 border border-l-0 border-gray-300 dark:border-gray-600 rounded-r-lg bg-gray-50 dark:bg-gray-600 text-gray-700 dark:text-gray-200 hover:bg-gray-100"
                                            >
                                                Show
                                            </button>
                                        </div>
                                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                            Keep your API key secure. Do not share it publicly.
                                        </p>
                                    </div>

                                    <div className="mt-8">
                                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
                                            API Documentation
                                        </h3>
                                        <div className="prose dark:prose-invert max-w-none">
                                            <p>
                                                Use this API key to authenticate your requests to the Resumeably API.
                                                Check out our <a href="/docs" className="text-blue-600 dark:text-blue-400">documentation</a> for
                                                more information on how to use the API.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* API Key Modal */}
            <ApiKeyModal
                isOpen={showApiModal}
                onClose={() => setShowApiModal(false)}
                apiKey={orgData?.api_key}
            />
            {/* Invite Modal */}
            <InviteModal
                isOpen={showInviteModal}
                onClose={() => setShowInviteModal(false)}
                onInvite={handleInviteMember}
                email={inviteEmail}
                setEmail={setInviteEmail}
            />
        </div>
    );
};

export default SettingsDashboard;