import React from 'react';
import { AlertCircle } from 'lucide-react';

const ErrorMessage = ({ message }) => (
    <div className="flex items-center gap-2 mb-4 p-4 border border-red-300 bg-red-50 rounded">
        <AlertCircle className="h-5 w-5 text-red-600" />
        <span className="text-red-800 text-sm font-medium">{message}</span>
    </div>
);

export default ErrorMessage;
