import React, { useState } from 'react';
import { X } from 'lucide-react';

const SkillsSection = ({ skills = { tools: [], skills: [] }, onUpdate }) => {
    const [skillInput, setSkillInput] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('tools');

    // Safely access arrays, defaulting to empty arrays if not present
    const currentSkills = {
        tools: Array.isArray(skills.tools) ? skills.tools : [],
        skills: Array.isArray(skills.skills) ? skills.skills : []
    };

    const handleAddSkill = () => {
        const inputValue = skillInput.trim();
        if (inputValue) {
            const updatedSkills = {
                tools: [...currentSkills.tools],
                skills: [...currentSkills.skills]
            };

            if (selectedCategory === 'tools') {
                updatedSkills.tools.push(inputValue);
            } else {
                updatedSkills.skills.push(inputValue);
            }

            onUpdate(updatedSkills);
            setSkillInput('');
        }
    };

    const handleRemoveSkill = (category, index) => {
        const updatedSkills = {
            tools: [...currentSkills.tools],
            skills: [...currentSkills.skills]
        };
        updatedSkills[category].splice(index, 1);
        onUpdate(updatedSkills);
    };

    return (
        <div className="space-y-6">
            {/* Input Section */}
            <div className="flex gap-2">
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                >
                    <option value="tools">Technical Tools</option>
                    <option value="skills">Professional Skills</option>
                </select>
                <input
                    type="text"
                    value={skillInput}
                    onChange={(e) => setSkillInput(e.target.value)}
                    placeholder="Add new skill"
                    className="flex-1 p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAddSkill();
                        }
                    }}
                />
                <button
                    onClick={handleAddSkill}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Add
                </button>
            </div>

            {/* Technical Tools Section */}
            <div className="border rounded-lg p-4 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                <h3 className="font-medium mb-4 text-gray-900 dark:text-gray-100">Technical Tools</h3>
                <div className="flex flex-wrap gap-2">
                    {currentSkills.tools.map((tool, index) => (
                        <div
                            key={`tool-${index}`}
                            className="flex items-center gap-2 bg-blue-50 dark:bg-blue-900/30 px-3 py-1 rounded"
                        >
                            <span className="text-blue-800 dark:text-blue-200">{tool}</span>
                            <button
                                onClick={() => handleRemoveSkill('tools', index)}
                                className="text-blue-400 dark:text-blue-200 hover:text-red-500"
                            >
                                <X size={14} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {/* Professional Skills Section */}
            <div className="border rounded-lg p-4 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                <h3 className="font-medium mb-4 text-gray-900 dark:text-gray-100">Professional Skills</h3>
                <div className="flex flex-wrap gap-2">
                    {currentSkills.skills.map((skill, index) => (
                        <div
                            key={`skill-${index}`}
                            className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700 px-3 py-1 rounded"
                        >
                            <span className="text-gray-800 dark:text-gray-100">{skill}</span>
                            <button
                                onClick={() => handleRemoveSkill('skills', index)}
                                className="text-gray-400 dark:text-gray-300 hover:text-red-500"
                            >
                                <X size={14} />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SkillsSection;
