import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
    'https://mzhpmljuqkxxocbyruyy.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im16aHBtbGp1cWt4eG9jYnlydXl5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM2MzA5OTYsImV4cCI6MjA0OTIwNjk5Nn0.hRFLAjERKputEO5lXUeArcWe8Pk9YBg868FEFv3cLl0',
    {
        realtime: true,
        db: {
            schema: 'public'
        },
        auth: {
            persistSession: true
        }
    }
);

export default supabase;