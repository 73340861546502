import React from 'react';

const THEMES = [
    { id: 'stackoverflow', name: 'Stack Overflow' },

    { id: 'traditional', name: 'Traditional' },
    { id: 'toko', name: 'Tokyo' },
    { id: 'paper_w_certs', name: 'Paper with Certificates' },

    { id: 'onepageresume_alt', name: 'OnePageResume Alternative' },

    { id: 'standard_resume_vforesee', name: 'Standard Resume Vforesee' },
    { id: 'business_card_vforesee', name: 'Business Card Vforesee' },

    { id: 'elegant_sargefork', name: 'Elegant Sargefork' },
    { id: 'elegant_sargefork_no_volunteer', name: 'Elegant Sargefork No Volunteer' },

    { id: 'murtaza', name: 'Murtaza' },

    { id: 'eloquent_mod', name: 'Eloquent Mod' },

    { id: 'ace', name: 'Ace' }
];

const ThemeSelector = ({ selectedTheme, onThemeChange }) => {
    return (
        <div className="mb-4">
            <select
                className="w-full p-2 border rounded"
                value={selectedTheme}
                onChange={(e) => onThemeChange(e.target.value)}
            >
                <option value="">Select a Theme</option>
                {THEMES.map(theme => (
                    <option key={theme.id} value={theme.id}>
                        {theme.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default ThemeSelector;