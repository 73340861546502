// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, useLocation } from 'react-router-dom';
// // import supabase from '../../supabaseClient';
// // import {
// //     Search,
// //     AlertCircle,
// //     Filter,
// //     Download,
// //     Trash2,
// //     Upload,
// //     Loader2
// // } from 'lucide-react';

// // const ResumeDashboard = () => {
// //     const navigate = useNavigate();
// //     const location = useLocation();
// //     const [searchTerm, setSearchTerm] = useState('');
// //     const [selectedCompany, setSelectedCompany] = useState('all');
// //     const [loading, setLoading] = useState(true);
// //     const [error, setError] = useState(null);
// //     const [newUploadId, setNewUploadId] = useState(null);

// //     // State for user and agency data
// //     const [userData, setUserData] = useState(null);
// //     const [agencyData, setAgencyData] = useState(null);
// //     const [uploads, setUploads] = useState([]);
// //     const [teamMembers, setTeamMembers] = useState([]);

// //     useEffect(() => {
// //         fetchDashboardData();

// //         // Check for new upload param
// //         const params = new URLSearchParams(location.search);
// //         const newUpload = params.get('newUpload');
// //         if (newUpload) {
// //             setNewUploadId(newUpload);
// //             // Clean up URL
// //             navigate('/dashboard', { replace: true });

// //             // Clear the newUploadId after 3 seconds
// //             setTimeout(() => {
// //                 setNewUploadId(null);
// //             }, 3000);
// //         }
// //     }, [location, navigate]);

// //     const fetchDashboardData = async () => {
// //         try {
// //             setLoading(true);
// //             const { data: { user } } = await supabase.auth.getUser();

// //             if (!user) {
// //                 navigate('/');
// //                 return;
// //             }

// //             // Get user's agency membership and agency details
// //             const { data: membership, error: membershipError } = await supabase
// //                 .from('agency_members')
// //                 .select(`
// //                     *,
// //                     agency:agencies(*)
// //                 `)
// //                 .eq('user_id', user.id)
// //                 .single();

// //             if (membershipError) throw membershipError;

// //             setUserData({
// //                 id: user.id,
// //                 email: user.email,
// //                 first_name: membership.first_name,
// //                 last_name: membership.last_name,
// //                 role: membership.role
// //             });

// //             setAgencyData(membership.agency);

// //             // Get team members
// //             const { data: members, error: membersError } = await supabase
// //                 .from('agency_members')
// //                 .select('*')
// //                 .eq('agency_id', membership.agency.id);


// //             if (membersError) throw membersError;
// //             setTeamMembers(members);

// //             // Get resume uploads
// //             const { data: resumeUploads, error: uploadsError } = await supabase
// //                 .from('resume_uploads')
// //                 .select('*')
// //                 .eq('agency_id', membership.agency.id)
// //                 .order('created_at', { ascending: false });

// //             if (uploadsError) throw uploadsError;
// //             setUploads(resumeUploads || []);

// //         } catch (err) {
// //             console.error('Error fetching dashboard data:', err);
// //             setError(err.message);
// //         } finally {
// //             setLoading(false);
// //         }
// //     };

// //     const handleDeleteUpload = async (uploadId, filePath) => {
// //         try {
// //             // Delete from storage
// //             if (filePath) {
// //                 const { error: storageError } = await supabase.storage
// //                     .from('upload_resumes')
// //                     .remove([filePath]);

// //                 if (storageError) throw storageError;
// //             }

// //             // Delete from database
// //             const { error: dbError } = await supabase
// //                 .from('resume_uploads')
// //                 .delete()
// //                 .eq('id', uploadId);

// //             if (dbError) throw dbError;

// //             fetchDashboardData();
// //         } catch (err) {
// //             console.error('Error deleting upload:', err);
// //             setError('Failed to delete file');
// //         }
// //     };

// //     const handleDownload = async (fileUrl, fileName) => {
// //         try {
// //             const response = await fetch(fileUrl);
// //             const blob = await response.blob();
// //             const url = window.URL.createObjectURL(blob);
// //             const a = document.createElement('a');
// //             a.href = url;
// //             a.download = fileName;
// //             document.body.appendChild(a);
// //             a.click();
// //             window.URL.revokeObjectURL(url);
// //             document.body.removeChild(a);
// //         } catch (err) {
// //             console.error('Download error:', err);
// //             setError('Failed to download file');
// //         }
// //     };

// //     const handleFormatToggle = async (uploadId) => {
// //         try {
// //             const upload = uploads.find(u => u.id === uploadId);
// //             const { error } = await supabase
// //                 .from('resume_uploads')
// //                 .update({ formatted: !upload.formatted })
// //                 .eq('id', uploadId);

// //             if (error) throw error;
// //             fetchDashboardData();
// //         } catch (err) {
// //             console.error('Error updating formatted status:', err);
// //             setError('Failed to update formatted status');
// //         }
// //     };

// //     const handleUpdateUpload = async (uploadId, updates) => {
// //         try {
// //             const { error } = await supabase
// //                 .from('resume_uploads')
// //                 .update(updates)
// //                 .eq('id', uploadId);

// //             if (error) throw error;
// //             fetchDashboardData();
// //         } catch (err) {
// //             console.error('Error updating upload:', err);
// //             setError('Failed to update information');
// //         }
// //     };

// //     const filteredUploads = uploads.filter(upload => {
// //         const searchString = searchTerm.toLowerCase();
// //         const matchesCandidateName = upload.candidate_name?.toLowerCase().includes(searchString);
// //         const matchesFileName = upload.file_name?.toLowerCase().includes(searchString);
// //         const matchesRole = upload.role?.toLowerCase().includes(searchString);
// //         const matchesCompany = upload.company?.toLowerCase().includes(searchString);

// //         return matchesCandidateName || matchesFileName || matchesRole || matchesCompany;
// //     });

// //     if (loading) {
// //         return (
// //             <div className="flex items-center justify-center min-h-screen bg-slate-50 dark:bg-gray-900">
// //                 <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
// //             </div>
// //         );
// //     }

// //     if (error) {
// //         return (
// //             <div className="flex items-center justify-center min-h-screen bg-slate-50 dark:bg-gray-900 p-4">
// //                 <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-md w-full">
// //                     <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
// //                     <h3 className="text-lg font-medium text-center mb-2">Error Loading Dashboard</h3>
// //                     <p className="text-gray-600 dark:text-gray-300 text-center mb-4">{error}</p>
// //                     <button
// //                         onClick={fetchDashboardData}
// //                         className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
// //                     >
// //                         Try Again
// //                     </button>
// //                 </div>
// //             </div>
// //         );
// //     }

// //     return (
// //         <div className="flex flex-col min-h-screen bg-slate-50 dark:bg-gray-900 text-black dark:text-gray-200">
// //             {/* Header */}
// //             <header className="bg-white dark:bg-gray-800 border-b dark:border-gray-700 px-6 py-4">
// //                 <div className="flex justify-between items-center">
// //                     <div className="flex items-center space-x-4">
// //                         <span className="text-xl font-semibold text-blue-900 dark:text-blue-200">
// //                             {agencyData?.name || 'Resume Dashboard'}
// //                         </span>
// //                         <button
// //                             onClick={() => navigate('/dashboard/upload')}
// //                             className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
// //                         >
// //                             <Upload className="h-4 w-4" />
// //                             <span>Upload New Resume</span>
// //                         </button>
// //                     </div>
// //                     <div className="flex items-center space-x-4">
// //                         <span className="text-slate-600 dark:text-slate-300">
// //                             {agencyData?.website}
// //                         </span>
// //                         <span className="text-slate-800 dark:text-gray-100">
// //                             {userData?.first_name} {userData?.last_name}
// //                         </span>
// //                     </div>
// //                 </div>
// //             </header>

// //             <div className="flex-1 p-6">
// //                 {/* Subscription Warning if needed */}
// //                 {agencyData?.subscription_status === 'trial' && (
// //                     <div className="mb-6 bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-700 rounded-lg p-4 text-amber-800 dark:text-amber-200 flex items-center gap-2">
// //                         <AlertCircle className="h-4 w-4" />
// //                         <p>Your trial expires on {new Date(agencyData.trial_end_date).toLocaleDateString()}. Upgrade to continue using Resumeably.</p>
// //                     </div>
// //                 )}

// //                 <div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700">
// //                     {/* Filters and Search */}
// //                     <div className="p-6 border-b dark:border-gray-700">
// //                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
// //                             <div className="relative">
// //                                 <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 dark:text-gray-300 w-5 h-5" />
// //                                 <input
// //                                     type="text"
// //                                     placeholder="Search candidate name, file name, role, or company..."
// //                                     className="pl-10 w-96 border rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-black dark:text-gray-200"
// //                                     value={searchTerm}
// //                                     onChange={(e) => setSearchTerm(e.target.value)}
// //                                 />
// //                             </div>
// //                         </div>
// //                     </div>

// //                     {/* Table */}
// //                     <div className="overflow-x-auto">
// //                         <table className="w-full text-black dark:text-gray-200">
// //                             <thead>
// //                                 <tr className="bg-slate-50 dark:bg-gray-700">
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">View</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Formatted</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Role Info</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">File Name</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Role</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Uploaded By</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Uploaded On</th>
// //                                     <th className="text-left px-6 py-3 text-sm font-medium text-slate-500 dark:text-gray-300">Actions</th>
// //                                 </tr>
// //                             </thead>
// //                             <tbody>
// //                                 {filteredUploads.map((upload) => {
// //                                     const isNewUpload = upload.id === newUploadId;
// //                                     const uploader = teamMembers.find(m => m.user_id === upload.uploaded_by);
// //                                     return (
// //                                         <tr
// //                                             key={upload.id}
// //                                             className={`hover:bg-slate-50 dark:hover:bg-gray-800 transition-colors border-t border-gray-200 dark:border-gray-700 ${isNewUpload ? 'bg-blue-50 dark:bg-blue-900/20' : ''
// //                                                 }`}
// //                                         >
// //                                             <td className="px-6 py-4">
// //                                                 {isNewUpload ? (
// //                                                     <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
// //                                                         <Loader2 className="h-4 w-4 animate-spin" />
// //                                                         <span>Processing...</span>
// //                                                     </div>
// //                                                 ) : (
// //                                                     <div className="flex items-center gap-2">
// //                                                         <a
// //                                                             href={upload.file_url}
// //                                                             target="_blank"
// //                                                             rel="noopener noreferrer"
// //                                                             className="bg-blue-100 dark:bg-blue-900/20 hover:bg-blue-200 dark:hover:bg-blue-900/30 text-blue-700 dark:text-blue-200 px-3 py-1 rounded text-sm inline-block"
// //                                                         >
// //                                                             View
// //                                                         </a>
// //                                                         <button
// //                                                             onClick={() => navigate(`/dashboard/editor/${upload.id}`)}
// //                                                             className="bg-green-100 dark:bg-green-900/20 hover:bg-green-200 dark:hover:bg-green-900/30 text-green-700 dark:text-green-200 px-3 py-1 rounded text-sm inline-block"
// //                                                         >
// //                                                             Edit
// //                                                         </button>
// //                                                     </div>
// //                                                 )}
// //                                             </td>
// //                                             <td className="px-6 py-4">
// //                                                 <input
// //                                                     type="checkbox"
// //                                                     checked={upload.formatted}
// //                                                     className="w-4 h-4 text-blue-600 dark:text-blue-400 rounded border-slate-300 dark:border-gray-600 focus:ring-blue-500"
// //                                                     onChange={() => handleFormatToggle(upload.id)}
// //                                                     disabled={isNewUpload}
// //                                                 />
// //                                             </td>
// //                                             <td className="px-6 py-4 font-medium text-slate-900 dark:text-gray-100">
// //                                                 <input
// //                                                     type="text"
// //                                                     value={upload.candidate_name || ''}
// //                                                     onChange={(e) => handleUpdateUpload(upload.id, { candidate_name: e.target.value })}
// //                                                     className="w-full bg-transparent border-b border-transparent hover:border-gray-300 dark:hover:border-gray-600 focus:border-blue-500 dark:focus:border-blue-400 focus:outline-none"
// //                                                     placeholder="Enter candidate name"
// //                                                     disabled={isNewUpload}
// //                                                 />
// //                                                 {upload.company && (
// //                                                     <div className="text-xs text-slate-500 dark:text-gray-300 mt-1">
// //                                                         Company: {upload.company}
// //                                                     </div>
// //                                                 )}
// //                                             </td>
// //                                             <td className="px-6 py-4 text-slate-600 dark:text-gray-300">
// //                                                 {upload.file_name}
// //                                             </td>
// //                                             <td className="px-6 py-4">
// //                                                 <input
// //                                                     type="text"
// //                                                     value={upload.role || ''}
// //                                                     onChange={(e) => handleUpdateUpload(upload.id, { role: e.target.value })}
// //                                                     className="w-full bg-transparent border-b border-transparent hover:border-gray-300 dark:hover:border-gray-600 focus:border-blue-500 dark:focus:border-blue-400 focus:outline-none"
// //                                                     placeholder="Enter target role"
// //                                                     disabled={isNewUpload}
// //                                                 />
// //                                             </td>
// //                                             <td className="px-6 py-4 text-slate-600 dark:text-gray-300">
// //                                                 {uploader ? `${uploader.first_name} ${uploader.last_name}` : 'Unknown'}
// //                                             </td>
// //                                             <td className="px-6 py-4 text-slate-600 dark:text-gray-300">
// //                                                 {new Date(upload.created_at).toLocaleString()}
// //                                             </td>
// //                                             <td className="px-6 py-4">
// //                                                 <div className="flex space-x-2">
// //                                                     <button
// //                                                         onClick={() => handleDownload(upload.file_url, upload.file_name)}
// //                                                         className="text-slate-400 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-300"
// //                                                         title="Download"
// //                                                         disabled={isNewUpload}
// //                                                     >
// //                                                         <Download className="w-5 h-5" />
// //                                                     </button>
// //                                                     <button
// //                                                         onClick={() => handleDeleteUpload(upload.id, upload.file_path)}
// //                                                         className="text-slate-400 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-400"
// //                                                         title="Delete"
// //                                                         disabled={isNewUpload}
// //                                                     >
// //                                                         <Trash2 className="w-5 h-5" />
// //                                                     </button>
// //                                                 </div>
// //                                             </td>
// //                                         </tr>
// //                                     );
// //                                 })}
// //                                 {filteredUploads.length === 0 && (
// //                                     <tr>
// //                                         <td colSpan="8" className="px-6 py-8 text-center text-slate-500 dark:text-gray-400">
// //                                             {searchTerm ? (
// //                                                 <>
// //                                                     <p className="font-medium">No results found</p>
// //                                                     <p className="text-sm mt-1">Try adjusting your search terms</p>
// //                                                 </>
// //                                             ) : (
// //                                                 <>
// //                                                     <p className="font-medium">No resumes uploaded yet</p>
// //                                                     <p className="text-sm mt-1">Upload your first resume to get started</p>
// //                                                 </>
// //                                             )}
// //                                         </td>
// //                                     </tr>
// //                                 )}
// //                             </tbody>
// //                         </table>
// //                     </div>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default ResumeDashboard;


// // ResumeDashboard.jsx
// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { debounce } from 'lodash';
// import confetti from 'canvas-confetti';
// import { toast } from 'sonner';
// import {
//     Search,
//     AlertCircle,
//     Filter,
//     Download,
//     Trash2,
//     Upload,
//     Loader2,
//     Sparkles,
//     CheckCircle,
//     XCircle,
//     ChevronDown,
//     ChevronUp,
//     ExternalLink,
//     FileText
// } from 'lucide-react';
// import supabase from '../../supabaseClient';

// // Confetti animation configuration
// const launchConfetti = () => {
//     const duration = 3 * 1000;
//     const animationEnd = Date.now() + duration;
//     const defaults = {
//         startVelocity: 30,
//         spread: 360,
//         ticks: 60,
//         zIndex: 0,
//         shapes: ['square', 'circle'],
//         colors: ['#FFE033', '#3399FF', '#33FF99', '#FF33CC']
//     };

//     const randomInRange = (min, max) => {
//         return Math.random() * (max - min) + min;
//     };

//     const interval = setInterval(() => {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//             return clearInterval(interval);
//         }

//         const particleCount = 50 * (timeLeft / duration);

//         confetti({
//             ...defaults,
//             particleCount,
//             origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
//         });
//         confetti({
//             ...defaults,
//             particleCount,
//             origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
//         });
//     }, 250);
// };

// // Skeleton loading components
// const TableRowSkeleton = () => (
//     <tr className="animate-pulse">
//         <td className="px-6 py-4">
//             <div className="h-8 w-24 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="h-4 w-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="space-y-2">
//                 <div className="h-6 w-48 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                 <div className="h-4 w-32 bg-gray-200 dark:bg-gray-700 rounded"></div>
//             </div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="h-6 w-40 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="h-6 w-36 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="h-6 w-32 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="h-6 w-40 bg-gray-200 dark:bg-gray-700 rounded"></div>
//         </td>
//         <td className="px-6 py-4">
//             <div className="flex space-x-2">
//                 <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                 <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded"></div>
//             </div>
//         </td>
//     </tr>
// );

// const HeaderSkeleton = () => (
//     <div className="animate-pulse p-6 border-b dark:border-gray-700">
//         <div className="flex justify-between items-center">
//             <div className="flex items-center space-x-4">
//                 <div className="h-8 w-48 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                 <div className="h-10 w-40 bg-gray-200 dark:bg-gray-700 rounded"></div>
//             </div>
//             <div className="flex items-center space-x-4">
//                 <div className="h-6 w-32 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                 <div className="h-6 w-40 bg-gray-200 dark:bg-gray-700 rounded"></div>
//             </div>
//         </div>
//     </div>
// );

// // Editable cell component with keyboard navigation and validation
// const EditableCell = ({
//     initialValue,
//     onUpdate,
//     disabled,
//     validator,
//     placeholder
// }) => {
//     const [isEditing, setIsEditing] = useState(false);
//     const [value, setValue] = useState(initialValue);
//     const [error, setError] = useState(null);
//     const inputReference = useRef(null);

//     useEffect(() => {
//         setValue(initialValue);
//     }, [initialValue]);

//     const handleValueChange = (event) => {
//         setValue(event.target.value);
//         setError(null);
//     };

//     const validateAndUpdate = () => {
//         if (validator) {
//             const validationResult = validator(value);
//             if (validationResult !== true) {
//                 setError(validationResult);
//                 return false;
//             }
//         }
//         return true;
//     };

//     const handleKeyDown = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             if (value !== initialValue) {
//                 if (validateAndUpdate()) {
//                     onUpdate(value);
//                     setIsEditing(false);
//                 }
//             } else {
//                 setIsEditing(false);
//             }
//         } else if (event.key === 'Escape') {
//             setIsEditing(false);
//             setValue(initialValue);
//             setError(null);
//         }
//     };

//     const handleBlur = () => {
//         setIsEditing(false);
//         setValue(initialValue);
//         setError(null);
//     };

//     if (disabled) {
//         return (
//             <span className="block text-gray-600 dark:text-gray-400 py-1 px-2">
//                 {value || placeholder || ''}
//             </span>
//         );
//     }

//     return isEditing ? (
//         <div className="relative">
//             <input
//                 ref={inputReference}
//                 type="text"
//                 value={value || ''}
//                 onChange={handleValueChange}
//                 onKeyDown={handleKeyDown}
//                 onBlur={handleBlur}
//                 className={`w-full bg-white dark:bg-gray-700 border ${error ? 'border-red-500' : 'border-blue-500'
//                     } dark:border-blue-400 rounded px-2 py-1 focus:outline-none text-black dark:text-white`}
//                 placeholder={placeholder}
//                 autoFocus
//             />
//             {error && (
//                 <div className="absolute bottom-full left-0 mb-1 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 text-xs rounded px-2 py-1">
//                     {error}
//                 </div>
//             )}
//         </div>
//     ) : (
//         <div
//             onClick={() => setIsEditing(true)}
//             className="block w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 rounded px-2 py-1 transition-colors"
//         >
//             {value || placeholder || ''}
//         </div>
//     );
// };


// const ResumeDashboard = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [searchTerm, setSearchTerm] = useState('');
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [newUploadId, setNewUploadId] = useState(null);
//     const [sortConfig, setSortConfig] = useState({
//         key: 'created_at',
//         direction: 'desc'
//     });
//     const [parsingStatus, setParsingStatus] = useState({
//         isParsing: false,
//         recordId: null,
//         progress: 0,
//         startTime: null
//     });

//     const [userData, setUserData] = useState(null);
//     const [agencyData, setAgencyData] = useState(null);
//     const [uploads, setUploads] = useState([]);
//     const [teamMembers, setTeamMembers] = useState([]);
//     const [filters, setFilters] = useState({
//         role: '',
//         company: '',
//         status: 'all',
//         dateRange: 'all'
//     });

//     // Debounced search handler
//     const debouncedSearch = useCallback(
//         debounce((term) => {
//             setSearchTerm(term);
//         }, 300),
//         []
//     );


//     const renderTableRow = (upload) => {
//         const isNewUpload = upload.id === newUploadId;
//         const isParsing = parsingStatus.isParsing && parsingStatus.recordId === upload.id;
//         const uploader = teamMembers.find(member => member.user_id === upload.uploaded_by);
//         const isDisabled = isNewUpload || isParsing;

//         return (
//             <tr
//                 key={upload.id}
//                 className={`group hover:bg-slate-50 dark:hover:bg-gray-800 transition-colors border-t border-gray-200 dark:border-gray-700 ${isNewUpload || isParsing ? 'bg-blue-50 dark:bg-blue-900/20' : ''
//                     }`}
//             >
//                 {/* Actions Cell */}
//                 <td className="px-6 py-4">
//                     {isParsing ? (
//                         <div className="flex flex-col gap-2">
//                             <div className="flex items-center gap-2 text-purple-600 dark:text-purple-400">
//                                 <Sparkles className="h-4 w-4 animate-pulse" />
//                                 <span className="text-sm font-medium">AI Processing...</span>
//                             </div>
//                             <div className="w-full h-1 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
//                                 <div
//                                     className="h-full bg-purple-500 transition-all duration-1000 rounded-full"
//                                     style={{ width: `${parsingStatus.progress}%` }}
//                                 />
//                             </div>
//                         </div>
//                     ) : isNewUpload ? (
//                         <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
//                             <Loader2 className="h-4 w-4 animate-spin" />
//                             <span className="text-sm font-medium">Processing...</span>
//                         </div>
//                     ) : (
//                         <div className="flex items-center gap-2">
//                             <a
//                                 href={upload.file_url}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="bg-blue-100 dark:bg-blue-900/20 hover:bg-blue-200 dark:hover:bg-blue-900/30 text-blue-700 dark:text-blue-200 px-3 py-1 rounded text-sm inline-block transition-colors"
//                             >
//                                 View
//                             </a>
//                             <button
//                                 onClick={() => navigate(`/dashboard/editor/${upload.id}`)}
//                                 className="bg-green-100 dark:bg-green-900/20 hover:bg-green-200 dark:hover:bg-green-900/30 text-green-700 dark:text-green-200 px-3 py-1 rounded text-sm inline-block transition-colors"
//                                 disabled={isDisabled}
//                             >
//                                 Edit
//                             </button>
//                         </div>
//                     )}
//                 </td>

//                 {/* Status Cell */}
//                 <td className="px-6 py-4">
//                     <div className="flex items-center gap-2">
//                         <input
//                             type="checkbox"
//                             checked={upload.formatted}
//                             onChange={() => handleFormatToggle(upload.id)}
//                             disabled={isDisabled}
//                             className="w-4 h-4 text-blue-600 dark:text-blue-400 rounded border-gray-300 dark:border-gray-600 focus:ring-blue-500 disabled:opacity-50 cursor-pointer"
//                         />
//                         <span className="text-sm text-gray-600 dark:text-gray-400">
//                             Formatted
//                         </span>
//                     </div>
//                 </td>

//                 {/* Candidate Info Cell */}
//                 <td className="px-6 py-4">
//                     <EditableCell
//                         initialValue={upload.candidate_name}
//                         onUpdate={(value) => handleUpdateUpload(upload.id, { candidate_name: value })}
//                         disabled={isDisabled}
//                         placeholder="Enter candidate name"
//                         validator={(value) => {
//                             if (value && value.length < 2) return "Name must be at least 2 characters";
//                             if (value && value.length > 100) return "Name must be less than 100 characters";
//                             return true;
//                         }}
//                     />
//                     {upload.company && (
//                         <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
//                             Company: {upload.company}
//                         </div>
//                     )}
//                 </td>

//                 {/* File Info Cell */}
//                 <td className="px-6 py-4">
//                     <div className="flex flex-col">
//                         <span className="text-gray-900 dark:text-gray-100 font-medium">
//                             {upload.file_name}
//                         </span>
//                         <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">
//                             {upload.file_type.split('/')[1].toUpperCase()}
//                         </span>
//                     </div>
//                 </td>

//                 {/* Target Role Cell */}
//                 <td className="px-6 py-4">
//                     <EditableCell
//                         initialValue={upload.role}
//                         onUpdate={(value) => handleUpdateUpload(upload.id, { role: value })}
//                         disabled={isDisabled}
//                         placeholder="Enter target role"
//                     />
//                 </td>

//                 {/* Upload Date Cell */}
//                 <td className="px-6 py-4">
//                     <div className="flex flex-col">
//                         <span className="text-gray-900 dark:text-gray-100">
//                             {new Date(upload.created_at).toLocaleDateString()}
//                         </span>
//                         <span className="text-xs text-gray-500 dark:text-gray-400">
//                             {new Date(upload.created_at).toLocaleTimeString()}
//                         </span>
//                     </div>
//                     {uploader && (
//                         <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
//                             by {uploader.first_name} {uploader.last_name}
//                         </div>
//                     )}
//                 </td>

//                 {/* Options Cell */}
//                 <td className="px-6 py-4">
//                     <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
//                         <button
//                             onClick={() => handleDownload(upload.file_url, upload.file_name)}
//                             className="text-gray-400 hover:text-blue-600 dark:text-gray-500 dark:hover:text-blue-400 transition-colors"
//                             title="Download"
//                             disabled={isDisabled}
//                         >
//                             <Download className="w-5 h-5" />
//                         </button>
//                         <button
//                             onClick={() => handleDeleteUpload(upload.id, upload.file_path)}
//                             className="text-gray-400 hover:text-red-600 dark:text-gray-500 dark:hover:text-red-400 transition-colors"
//                             title="Delete"
//                             disabled={isDisabled}
//                         >
//                             <Trash2 className="w-5 h-5" />
//                         </button>
//                     </div>
//                 </td>
//             </tr>
//         );
//     };

//     const handleUpdateUpload = useCallback(async (uploadId, updates) => {
//         const upload = uploads.find(u => u.id === uploadId);
//         if (!upload) return;

//         // Optimistic update
//         setUploads(currentUploads =>
//             currentUploads.map(item =>
//                 item.id === uploadId
//                     ? { ...item, ...updates }
//                     : item
//             )
//         );

//         try {
//             const { error } = await supabase
//                 .from('resume_uploads')
//                 .update({
//                     ...updates,
//                     updated_at: new Date().toISOString()
//                 })
//                 .eq('id', uploadId);

//             if (error) throw error;

//             toast.success('Update saved successfully', {
//                 position: 'bottom-right',
//                 duration: 3000,
//             });

//             // Optionally refresh data to ensure consistency
//             await fetchDashboardData();
//         } catch (error) {
//             console.error('Error updating upload:', error);

//             // Revert optimistic update
//             setUploads(currentUploads =>
//                 currentUploads.map(item =>
//                     item.id === uploadId
//                         ? upload  // Revert to original state
//                         : item
//                 )
//             );

//             toast.error('Failed to save changes. Please try again.', {
//                 position: 'bottom-right',
//                 duration: 5000,
//             });
//         }
//     }, [uploads, fetchDashboardData]);

//     // Debounced update handler for table edits
//     const debouncedUpdate = useCallback(
//         debounce(async (uploadId, updates) => {
//             try {
//                 const { error: updateError } = await supabase
//                     .from('resume_uploads')
//                     .update(updates)
//                     .eq('id', uploadId);

//                 if (updateError) throw updateError;

//                 toast.success('Changes saved successfully', {
//                     duration: 3000,
//                     position: 'bottom-right'
//                 });
//             } catch (error) {
//                 console.error('Update error:', error);
//                 toast.error('Failed to save changes. Please try again.', {
//                     duration: 5000,
//                     position: 'bottom-right'
//                 });
//             }
//         }, 1000),
//         []
//     );

//     // Parse status polling effect
//     useEffect(() => {
//         const isParsing = localStorage.getItem('resumeably-parsing') === 'true';
//         const recordId = localStorage.getItem('resumeably-record-id');
//         const startTime = localStorage.getItem('resumeably-parse-start');

//         if (isParsing && recordId) {
//             setParsingStatus({
//                 isParsing: true,
//                 recordId,
//                 progress: 0,
//                 startTime: startTime || Date.now().toString()
//             });

//             toast.loading(
//                 <div className="flex items-center gap-2">
//                     <Sparkles className="h-5 w-5 text-purple-500" />
//                     <span>AI is analyzing your resume...</span>
//                 </div>,
//                 {
//                     id: `parsing-${recordId}`,
//                     duration: Infinity
//                 }
//             );

//             const pollInterval = setInterval(async () => {
//                 try {
//                     const { data, error } = await supabase
//                         .from('resume_uploads')
//                         .select(`
//                             id,
//                             parse_status,
//                             parse_error,
//                             candidate_name,
//                             email,
//                             phone,
//                             resume_text,
//                             resume_json
//                         `)
//                         .eq('id', recordId)
//                         .single();

//                     if (error) throw error;

//                     // Update progress based on time elapsed (max 90%)
//                     const timeElapsed = Date.now() - parseInt(startTime);
//                     const progressPercentage = Math.min(90, (timeElapsed / 30000) * 100);

//                     setParsingStatus(previous => ({
//                         ...previous,
//                         progress: progressPercentage
//                     }));

//                     if (data.parse_status === 'completed') {
//                         // Clear parsing state
//                         localStorage.removeItem('resumeably-parsing');
//                         localStorage.removeItem('resumeably-record-id');
//                         localStorage.removeItem('resumeably-parse-start');

//                         setParsingStatus({
//                             isParsing: false,
//                             recordId: null,
//                             progress: 100,
//                             startTime: null
//                         });

//                         // Dismiss loading toast and show success
//                         toast.dismiss(`parsing-${recordId}`);
//                         toast.success(
//                             <div className="flex items-center gap-2">
//                                 <CheckCircle className="h-5 w-5" />
//                                 <div className="flex flex-col">
//                                     <span className="font-medium">Resume parsed successfully!</span>
//                                     <span className="text-sm text-gray-500">
//                                         Found {Object.keys(data.resume_json || {}).length} data points
//                                     </span>
//                                 </div>
//                             </div>,
//                             { duration: 5000 }
//                         );

//                         // Launch celebration
//                         launchConfetti();

//                         // Refresh dashboard data
//                         fetchDashboardData();
//                         clearInterval(pollInterval);
//                     } else if (data.parse_status === 'failed') {
//                         // Handle failure
//                         localStorage.removeItem('resumeably-parsing');
//                         localStorage.removeItem('resumeably-record-id');
//                         localStorage.removeItem('resumeably-parse-start');

//                         setParsingStatus({
//                             isParsing: false,
//                             recordId: null,
//                             progress: 0,
//                             startTime: null
//                         });

//                         // Show error toast
//                         toast.dismiss(`parsing-${recordId}`);
//                         toast.error(
//                             <div className="flex items-center gap-2">
//                                 <XCircle className="h-5 w-5" />
//                                 <div className="flex flex-col">
//                                     <span className="font-medium">Failed to parse resume</span>
//                                     <span className="text-sm text-gray-500">
//                                         {data.parse_error || 'Please try again'}
//                                     </span>
//                                 </div>
//                             </div>,
//                             { duration: 5000 }
//                         );

//                         clearInterval(pollInterval);
//                     }
//                 } catch (error) {
//                     console.error('Error checking parse status:', error);
//                 }
//             }, 3000);

//             return () => clearInterval(pollInterval);
//         }
//     }, []);

//     // Initial data fetch effect
//     useEffect(() => {
//         fetchDashboardData();

//         // Handle new upload from location state
//         if (location.state?.resumeId) {
//             setNewUploadId(location.state.resumeId);
//             if (location.state?.message) {
//                 toast.info(location.state.message);
//             }
//             // Clean up location state
//             navigate('/dashboard', {
//                 replace: true,
//                 state: {}
//             });

//             // Clear newUploadId after delay
//             setTimeout(() => {
//                 setNewUploadId(null);
//             }, 3000);
//         }
//     }, [location, navigate]);

//     const fetchDashboardData = async () => {
//         try {
//             setLoading(true);
//             setError(null);

//             // Get current user
//             const { data: { user }, error: userError } = await supabase.auth.getUser();

//             if (userError) throw userError;

//             if (!user) {
//                 navigate('/');
//                 return;
//             }

//             // Get user's agency membership and agency details
//             const { data: membership, error: membershipError } = await supabase
//                 .from('agency_members')
//                 .select(`
//                     *,
//                     agency:agencies(
//                         id,
//                         name,
//                         website,
//                         subscription_status,
//                         trial_end_date,
//                         settings
//                     )
//                 `)
//                 .eq('user_id', user.id)
//                 .single();

//             if (membershipError) throw membershipError;

//             setUserData({
//                 id: user.id,
//                 email: user.email,
//                 first_name: membership.first_name,
//                 last_name: membership.last_name,
//                 role: membership.role,
//                 settings: membership.settings
//             });

//             setAgencyData(membership.agency);

//             // Get team members
//             const { data: members, error: membersError } = await supabase
//                 .from('agency_members')
//                 .select(`
//                     user_id,
//                     first_name,
//                     last_name,
//                     role,
//                     email,
//                     avatar_url
//                 `)
//                 .eq('agency_id', membership.agency.id);

//             if (membersError) throw membersError;
//             setTeamMembers(members);

//             // Get resume uploads with sort
//             const { data: resumeUploads, error: uploadsError } = await supabase
//                 .from('resume_uploads')
//                 .select(`
//                     id,
//                     file_url,
//                     file_name,
//                     file_path,
//                     file_type,
//                     candidate_name,
//                     company,
//                     role,
//                     notes,
//                     parse_requested,
//                     parse_status,
//                     formatted,
//                     created_at,
//                     uploaded_by,
//                     resume_json,
//                     skills,
//                     work_experience,
//                     education
//                 `)
//                 .eq('agency_id', membership.agency.id)
//                 .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });

//             if (uploadsError) throw uploadsError;
//             setUploads(resumeUploads || []);

//         } catch (error) {
//             console.error('Error fetching dashboard data:', error);
//             setError(error.message);
//             toast.error('Failed to load dashboard data');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDeleteUpload = async (uploadId, filePath) => {
//         if (!window.confirm('Are you sure you want to delete this resume? This action cannot be undone.')) {
//             return;
//         }

//         try {
//             // Show loading state
//             toast.loading('Deleting resume...', { id: `delete-${uploadId}` });

//             // Delete from storage if file path exists
//             if (filePath) {
//                 const { error: storageError } = await supabase.storage
//                     .from('resume_uploads')
//                     .remove([filePath]);

//                 if (storageError) throw storageError;
//             }

//             // Delete from database
//             const { error: dbError } = await supabase
//                 .from('resume_uploads')
//                 .delete()
//                 .eq('id', uploadId);

//             if (dbError) throw dbError;

//             // Update local state
//             setUploads(currentUploads =>
//                 currentUploads.filter(upload => upload.id !== uploadId)
//             );

//             // Show success message
//             toast.success('Resume deleted successfully', { id: `delete-${uploadId}` });
//         } catch (error) {
//             console.error('Error deleting upload:', error);
//             toast.error('Failed to delete resume', { id: `delete-${uploadId}` });
//         }
//     };

//     const handleDownload = async (fileUrl, fileName) => {
//         try {
//             toast.loading('Preparing download...', { id: `download-${fileName}` });

//             const response = await fetch(fileUrl);
//             if (!response.ok) throw new Error('Failed to fetch file');

//             const blob = await response.blob();
//             const url = window.URL.createObjectURL(blob);
//             const downloadLink = document.createElement('a');
//             downloadLink.href = url;
//             downloadLink.download = fileName;
//             document.body.appendChild(downloadLink);
//             downloadLink.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(downloadLink);

//             toast.success('Download started', { id: `download-${fileName}` });
//         } catch (error) {
//             console.error('Download error:', error);
//             toast.error('Failed to download file', { id: `download-${fileName}` });
//         }
//     };

//     const handleFormatToggle = async (uploadId) => {
//         const upload = uploads.find(upload => upload.id === uploadId);
//         if (!upload) return;

//         try {
//             // Optimistic update
//             setUploads(currentUploads =>
//                 currentUploads.map(item =>
//                     item.id === uploadId
//                         ? { ...item, formatted: !item.formatted }
//                         : item
//                 )
//             );

//             const { error } = await supabase
//                 .from('resume_uploads')
//                 .update({ formatted: !upload.formatted })
//                 .eq('id', uploadId);

//             if (error) throw error;

//             toast.success('Format status updated');
//         } catch (error) {
//             console.error('Error updating format status:', error);
//             toast.error('Failed to update format status');

//             // Revert optimistic update
//             setUploads(currentUploads =>
//                 currentUploads.map(item =>
//                     item.id === uploadId
//                         ? { ...item, formatted: upload.formatted }
//                         : item
//                 )
//             );
//         }
//     };

//     const handleSort = (key) => {
//         setSortConfig(currentConfig => ({
//             key,
//             direction:
//                 currentConfig.key === key && currentConfig.direction === 'asc'
//                     ? 'desc'
//                     : 'asc'
//         }));

//         const sortedUploads = [...uploads].sort((a, b) => {
//             if (a[key] === null) return 1;
//             if (b[key] === null) return -1;

//             const comparison = a[key].toString().localeCompare(b[key].toString());
//             return sortConfig.direction === 'asc' ? comparison : -comparison;
//         });

//         setUploads(sortedUploads);
//     };

//     const handleFilterChange = (filterKey, value) => {
//         setFilters(currentFilters => ({
//             ...currentFilters,
//             [filterKey]: value
//         }));
//     };

//     const filteredUploads = uploads.filter(upload => {
//         const searchLower = searchTerm.toLowerCase();
//         const matchesSearch =
//             (upload.candidate_name?.toLowerCase().includes(searchLower)) ||
//             (upload.file_name?.toLowerCase().includes(searchLower)) ||
//             (upload.role?.toLowerCase().includes(searchLower)) ||
//             (upload.company?.toLowerCase().includes(searchLower));

//         const matchesRole = filters.role === '' || upload.role === filters.role;
//         const matchesCompany = filters.company === '' || upload.company === filters.company;
//         const matchesStatus = filters.status === 'all' ||
//             (filters.status === 'parsed' && upload.parse_status === 'completed') ||
//             (filters.status === 'pending' && upload.parse_status === 'pending') ||
//             (filters.status === 'failed' && upload.parse_status === 'failed');

//         let matchesDate = true;
//         if (filters.dateRange !== 'all') {
//             const uploadDate = new Date(upload.created_at);
//             const now = new Date();
//             const daysDiff = (now - uploadDate) / (1000 * 60 * 60 * 24);

//             matchesDate = filters.dateRange === 'today' ? daysDiff < 1 :
//                 filters.dateRange === 'week' ? daysDiff < 7 :
//                     filters.dateRange === 'month' ? daysDiff < 30 : true;
//         }

//         return matchesSearch && matchesRole && matchesCompany && matchesStatus && matchesDate;
//     });

//     const renderTableHeader = () => (
//         <thead>
//             <tr className="bg-slate-50 dark:bg-gray-700 text-left">
//                 <th className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300">
//                     Actions
//                 </th>
//                 <th className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300">
//                     Status
//                 </th>
//                 <th
//                     className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300 cursor-pointer hover:text-slate-700 dark:hover:text-gray-100"
//                     onClick={() => handleSort('candidate_name')}
//                 >
//                     <div className="flex items-center gap-2">
//                         Candidate Info
//                         {sortConfig.key === 'candidate_name' && (
//                             sortConfig.direction === 'asc' ?
//                                 <ChevronUp className="h-4 w-4" /> :
//                                 <ChevronDown className="h-4 w-4" />
//                         )}
//                     </div>
//                 </th>
//                 <th className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300">
//                     File Info
//                 </th>
//                 <th
//                     className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300 cursor-pointer hover:text-slate-700 dark:hover:text-gray-100"
//                     onClick={() => handleSort('role')}
//                 >
//                     <div className="flex items-center gap-2">
//                         Target Role
//                         {sortConfig.key === 'role' && (
//                             sortConfig.direction === 'asc' ?
//                                 <ChevronUp className="h-4 w-4" /> :
//                                 <ChevronDown className="h-4 w-4" />
//                         )}
//                     </div>
//                 </th>
//                 <th
//                     className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300 cursor-pointer hover:text-slate-700 dark:hover:text-gray-100"
//                     onClick={() => handleSort('created_at')}
//                 >
//                     <div className="flex items-center gap-2">
//                         Upload Date
//                         {sortConfig.key === 'created_at' && (
//                             sortConfig.direction === 'asc' ?
//                                 <ChevronUp className="h-4 w-4" /> :
//                                 <ChevronDown className="h-4 w-4" />
//                         )}
//                     </div>
//                 </th>
//                 <th className="px-6 py-3 text-sm font-semibold text-slate-500 dark:text-gray-300">
//                     Options
//                 </th>
//             </tr>
//         </thead>
//     );

//     if (loading) {
//         return (
//             <div className="min-h-screen bg-slate-50 dark:bg-gray-900 p-8">
//                 <div className="max-w-7xl mx-auto">
//                     <HeaderSkeleton />
//                     <div className="mt-8">
//                         <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
//                             <div className="border-b border-gray-200 dark:border-gray-700">
//                                 <div className="p-6">
//                                     <div className="animate-pulse flex justify-between items-center">
//                                         <div className="h-10 w-96 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                                         <div className="h-10 w-48 bg-gray-200 dark:bg-gray-700 rounded"></div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="overflow-x-auto">
//                                 <table className="w-full">
//                                     <tbody>
//                                         {[...Array(5)].map((_, index) => (
//                                             <TableRowSkeleton key={index} />
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="min-h-screen bg-slate-50 dark:bg-gray-900 flex items-center justify-center p-4">
//                 <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 max-w-md w-full">
//                     <div className="flex flex-col items-center text-center">
//                         <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
//                         <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2">
//                             Error Loading Dashboard
//                         </h3>
//                         <p className="text-gray-600 dark:text-gray-400 mb-6">
//                             {error}
//                         </p>
//                         <button
//                             onClick={fetchDashboardData}
//                             className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-colors"
//                         >
//                             Try Again
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-slate-50 dark:bg-gray-900">
//             {/* Header */}
//             <header className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
//                 <div className="max-w-7xl mx-auto px-6 py-4">
//                     <div className="flex justify-between items-center">
//                         <div className="flex items-center space-x-4">
//                             <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
//                                 {agencyData?.name || 'Resume Dashboard'}
//                             </h1>
//                             <button
//                                 onClick={() => navigate('/dashboard/upload')}
//                                 className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
//                             >
//                                 <Upload className="h-4 w-4" />
//                                 <span>Upload New</span>
//                             </button>
//                         </div>
//                         <div className="flex items-center space-x-4">
//                             {agencyData?.website && (
//                                 <a
//                                     href={agencyData.website}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                     className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 flex items-center gap-1"
//                                 >
//                                     <span>{agencyData.website}</span>
//                                     <ExternalLink className="h-4 w-4" />
//                                 </a>
//                             )}
//                             <div className="flex items-center gap-2 text-gray-900 dark:text-gray-100">
//                                 <FileText className="h-4 w-4" />
//                                 <span>
//                                     {userData?.first_name} {userData?.last_name}
//                                 </span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>

//             <main className="max-w-7xl mx-auto px-6 py-8">
//                 {/* Subscription Warning */}
//                 {agencyData?.subscription_status === 'trial' && (
//                     <div className="mb-6 bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-700 rounded-xl p-4">
//                         <div className="flex items-center gap-2 text-amber-800 dark:text-amber-200">
//                             <AlertCircle className="h-5 w-5 flex-shrink-0" />
//                             <p>
//                                 Your trial expires on{' '}
//                                 {new Date(agencyData.trial_end_date).toLocaleDateString()}
//                                 . Upgrade to continue using all features.
//                             </p>
//                         </div>
//                     </div>
//                 )}

//                 {/* AI Processing Banner */}
//                 {parsingStatus.isParsing && (
//                     <div className="mb-6 bg-purple-50 dark:bg-purple-900/20 border border-purple-200 dark:border-purple-700 rounded-xl p-4">
//                         <div className="flex items-center gap-4">
//                             <div className="flex-1">
//                                 <div className="flex items-center gap-2 text-purple-800 dark:text-purple-200">
//                                     <Sparkles className="h-5 w-5 animate-pulse" />
//                                     <h3 className="font-medium">AI Resume Analysis in Progress</h3>
//                                 </div>
//                                 <p className="text-purple-700 dark:text-purple-300 mt-1 text-sm">
//                                     Our AI is analyzing your resume to extract key information and insights...
//                                 </p>
//                             </div>
//                             <div className="text-right text-sm font-medium text-purple-800 dark:text-purple-200">
//                                 {Math.round(parsingStatus.progress)}%
//                             </div>
//                         </div>
//                         <div className="mt-3 w-full h-2 bg-purple-100 dark:bg-purple-900 rounded-full overflow-hidden">
//                             <div
//                                 className="h-full bg-purple-500 transition-all duration-1000 rounded-full"
//                                 style={{ width: `${parsingStatus.progress}% ` }}
//                             />
//                         </div>
//                     </div>
//                 )}

//                 {/* Main Content */}
//                 <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
//                     {/* Search and Filters */}
//                     <div className="border-b border-gray-200 dark:border-gray-700 p-6">
//                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
//                             <div className="relative flex-1">
//                                 <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
//                                 <input
//                                     type="text"
//                                     placeholder="Search by name, role, company..."
//                                     className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                     onChange={(event) => debouncedSearch(event.target.value)}
//                                 />
//                             </div>
//                             <div className="flex items-center gap-4">
//                                 <select
//                                     value={filters.status}
//                                     onChange={(event) => handleFilterChange('status', event.target.value)}
//                                     className="border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                 >
//                                     <option value="all">All Status</option>
//                                     <option value="parsed">Parsed</option>
//                                     <option value="pending">Pending</option>
//                                     <option value="failed">Failed</option>
//                                 </select>
//                                 <select
//                                     value={filters.dateRange}
//                                     onChange={(event) => handleFilterChange('dateRange', event.target.value)}
//                                     className="border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                 >
//                                     <option value="all">All Time</option>
//                                     <option value="today">Today</option>
//                                     <option value="week">This Week</option>
//                                     <option value="month">This Month</option>
//                                 </select>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Table */}
//                     <div className="overflow-x-auto">
//                         <table className="w-full">
//                             {renderTableHeader()}
//                             <tbody>
//                                 {filteredUploads.length > 0 ? (
//                                     filteredUploads.map((upload) => renderTableRow(upload))
//                                 ) : (
//                                     <tr>
//                                         <td colSpan="8" className="px-6 py-8 text-center">
//                                             <div className="text-gray-500 dark:text-gray-400">
//                                                 <p className="text-lg font-medium">
//                                                     {searchTerm ? 'No matching resumes found' : 'No resumes uploaded yet'}
//                                                 </p>
//                                                 <p className="text-sm mt-1">
//                                                     {searchTerm
//                                                         ? 'Try adjusting your search or filters'
//                                                         : 'Upload your first resume to get started'}
//                                                 </p>
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// };

// export default ResumeDashboard;


import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Search, AlertCircle, Filter, Download, Trash2,
    Upload, Loader2, Sparkles, CheckCircle, XCircle,
    ChevronDown, ChevronUp, ExternalLink, FileText, Clock, Eye
} from 'lucide-react';
import { format, formatDistanceToNow } from 'date-fns';
import supabase from '../../supabaseClient';
import ResumeActionButton from '../../Components/ResumeActionButton';

const UPLOAD_STATUS = {
    UPLOADING: 'uploading',
    PARSING: 'parsing',
    COMPLETED: 'completed',
    FAILED: 'failed',
    PENDING: 'pending'
};

const ResumeDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({
        key: 'created_at',
        direction: 'desc'
    });
    const [uploads, setUploads] = useState([]);
    const [filters, setFilters] = useState({
        status: 'all',
        dateRange: 'all',
        parseStatus: 'all'
    });

    const fetchUploads = useCallback(async () => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                navigate('/login');
                return;
            }

            const { data: membership } = await supabase
                .from('agency_members')
                .select('agency_id')
                .eq('user_id', user.id)
                .single();

            // Simplified query without relationships for now
            let query = supabase
                .from('resume_uploads')
                .select('*')
                .eq('agency_id', membership.agency_id)
                .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });

            const { data: resumeUploads, error: uploadsError } = await query;

            if (uploadsError) throw uploadsError;
            setUploads(resumeUploads || []);

        } catch (error) {
            console.error('Error fetching uploads:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [navigate, sortConfig]);

    useEffect(() => {
        fetchUploads();

        const channel = supabase.channel('dashboard-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'resume_uploads'
                },
                (payload) => {
                    console.log('Change received!', payload);
                    if (payload.eventType === 'INSERT') {
                        setUploads(current => [payload.new, ...current]);
                    } else if (payload.eventType === 'UPDATE') {
                        setUploads(current =>
                            current.map(upload =>
                                upload.id === payload.new.id
                                    ? { ...upload, ...payload.new }
                                    : upload
                            )
                        );
                    }
                }
            )
            .subscribe();

        return () => {
            channel.unsubscribe();
        };
    }, [fetchUploads]);

    const getUploadStatus = (upload) => {
        const isActive = upload.id === location.state?.activeUploadId;
        const isUploading = isActive && upload.status === 'uploading';
        const isParsing = isActive && upload.status === 'parsing';
        const isPending = isActive && upload.status === 'pending' && upload.parse_requested;

        if (isPending || isParsing) {
            return (
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 text-purple-600 dark:text-purple-400">
                        <Sparkles className="h-4 w-4 animate-pulse" />
                        <span className="text-sm font-medium">AI Processing...</span>
                    </div>
                    <div className="w-full h-1 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-purple-500 animate-pulse rounded-full w-4/5" />
                    </div>
                </div>
            );
        }

        if (isUploading) {
            return (
                <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span className="text-sm font-medium">Uploading...</span>
                </div>
            );
        }

        if (upload.parse_status === 'completed') {
            return (
                <div className="flex items-center gap-2 text-green-600 dark:text-green-400">
                    <CheckCircle className="h-4 w-4" />
                    <span className="text-sm font-medium">Processed</span>
                </div>
            );
        }

        return (
            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                <Clock className="h-4 w-4" />
                <span className="text-sm font-medium">
                    {upload.parse_requested ? 'Queued for Processing' : 'Basic Upload'}
                </span>
            </div>
        );
    };

    const renderResumeDetails = (upload) => {
        const details = [];

        if (upload.resume_json) {
            const json = typeof upload.resume_json === 'string'
                ? JSON.parse(upload.resume_json)
                : upload.resume_json;

            if (json.basics?.location?.city) {
                details.push(json.basics.location.city);
            }

            if (json.basics?.email) {
                details.push(json.basics.email);
            }

            if (json.work?.[0]?.position) {
                details.push(json.work[0].position);
            }
        }

        return details.length > 0 ? (
            <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                {details.join(' • ')}
            </div>
        ) : null;
    };

    const handleDownload = async (upload) => {
        try {
            const response = await fetch(upload.file_url);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = upload.file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download error:', error);
            setError('Failed to download file');
        }
    };

    const handleDelete = async (upload) => {
        if (!window.confirm('Are you sure you want to delete this resume?')) {
            return;
        }

        try {
            if (upload.file_path) {
                await supabase.storage
                    .from('resume_uploads')
                    .remove([upload.file_path]);
            }

            const { error } = await supabase
                .from('resume_uploads')
                .delete()
                .eq('id', upload.id);

            if (error) throw error;

            setUploads(current => current.filter(u => u.id !== upload.id));
        } catch (error) {
            console.error('Delete error:', error);
            setError('Failed to delete resume');
        }
    };

    const renderTableRow = (upload) => (
        <tr key={upload.id} className="group hover:bg-gray-50 dark:hover:bg-gray-700/50">
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-2">
                    <a
                        href={upload.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                        title="View Original"
                    >
                        <ExternalLink className="w-5 h-5" />
                    </a>
                    <ResumeActionButton resume={upload} />
                </div>
            </td>
            <td className="px-6 py-4">
                {getUploadStatus(upload)}
            </td>
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900 dark:text-gray-100">
                    {upload.candidate_name || upload.name || 'Unnamed Candidate'}
                </div>
                {renderResumeDetails(upload)}
            </td>
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900 dark:text-gray-100">
                    {upload.role || 'No Role Specified'}
                </div>
                {upload.company && (
                    <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {upload.company}
                    </div>
                )}
            </td>
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900 dark:text-gray-100">
                    {format(new Date(upload.created_at), 'MMM d, yyyy')}
                </div>
                <div className="text-xs text-gray-500 dark:text-gray-400">
                    {formatDistanceToNow(new Date(upload.created_at), { addSuffix: true })}
                </div>
            </td>
            <td className="px-6 py-4">
                <div className="opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                    <button
                        onClick={() => handleDownload(upload)}
                        className="text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                    >
                        <Download className="h-5 w-5" />
                    </button>
                    <button
                        onClick={() => handleDelete(upload)}
                        className="text-gray-400 hover:text-red-600 dark:hover:text-red-400"
                    >
                        <Trash2 className="h-5 w-5" />
                    </button>
                </div>
            </td>
        </tr>
    );

    const filteredUploads = uploads.filter(upload => {
        const searchLower = searchTerm.toLowerCase();
        return (
            (upload.candidate_name?.toLowerCase().includes(searchLower) ||
                upload.role?.toLowerCase().includes(searchLower) ||
                upload.company?.toLowerCase().includes(searchLower)) &&
            (filters.status === 'all' || upload.status === filters.status) &&
            (filters.parseStatus === 'all' || upload.parse_status === filters.parseStatus)
        );
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            <header className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
                            Resume Dashboard
                        </h1>
                        <button
                            onClick={() => navigate('/dashboard/upload')}
                            className="inline-flex items-center px-4 py-2 border border-transparent 
                                     rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 
                                     hover:bg-blue-700"
                        >
                            <Upload className="h-4 w-4 mr-2" />
                            Upload Resume
                        </button>
                    </div>
                </div>
            </header>

            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="mb-6">
                    <div className="flex gap-4 items-center">
                        <div className="flex-1 relative">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                            <input
                                type="text"
                                placeholder="Search resumes..."
                                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <select
                            value={filters.parseStatus}
                            onChange={(e) => setFilters(prev => ({ ...prev, parseStatus: e.target.value }))}
                            className="border border-gray-300 rounded-lg px-3 py-2"
                        >
                            <option value="all">All Statuses</option>
                            <option value="completed">Processed</option>
                            <option value="pending">Processing</option>
                            <option value="failed">Failed</option>
                        </select>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Actions
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Candidate
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Upload Date
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                    Options
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                            {filteredUploads.length > 0 ? (
                                filteredUploads.map(renderTableRow)
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-12 text-center">
                                        <div className="flex flex-col items-center justify-center gap-2">
                                            <Search className="h-8 w-8 text-gray-400 dark:text-gray-500" />
                                            {searchTerm ? (
                                                <>
                                                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                                                        No matching resumes found
                                                    </h3>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                                        Try adjusting your search or filters
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                                                        No resumes uploaded yet
                                                    </h3>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                                        Upload your first resume to get started
                                                    </p>
                                                    <button
                                                        onClick={() => navigate('/dashboard/upload')}
                                                        className="mt-4 inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                                                    >
                                                        <Upload className="h-4 w-4" />
                                                        <span>Upload Resume</span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {filteredUploads.length > 0 && (
                    <div className="mt-4 flex justify-between items-center text-sm text-gray-500 dark:text-gray-400">
                        <span>
                            Showing {filteredUploads.length} of {uploads.length} resumes
                        </span>
                    </div>
                )}
            </main>
        </div>
    );
};

export default ResumeDashboard;