import React, { createContext, useContext, useState } from 'react';
import { getAgencyTheme } from '../config/AgencyConfigurations';

const ThemeContext = createContext();

export function ThemeProvider({ children, initialAgency = 'intelgant' }) {
    const [currentAgency, setCurrentAgency] = useState(initialAgency);
    const currentTheme = getAgencyTheme(currentAgency);

    const value = {
        currentAgency,
        setCurrentAgency,
        theme: currentTheme
    };

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

export default ThemeContext;