import React, { useState } from 'react';
import { Trash2, Sparkles, Loader2 } from 'lucide-react';
import { API_BASE, API_ENDPOINTS } from '../apiConfig';
import DateInput from './DateInput';
import TextEditor from './TextEditor';

const WorkExperienceItem = ({ experience, onUpdate, onDelete }) => {
    const [isEnhancing, setIsEnhancing] = useState(false);

    const handleAIEnhance = async () => {
        setIsEnhancing(true);
        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.ENHANCE}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text: experience.description
                })
            });
            const data = await response.json();
            onUpdate('description', data.enhanced_text);
        } catch (error) {
            console.error('Error enhancing text:', error);
        } finally {
            setIsEnhancing(false);
        }
    };

    const handleLocationUpdate = (field, value) => {
        onUpdate('location', {
            ...experience.location,
            [field]: value
        });
    };

    return (
        <div className="border rounded-lg p-4 mb-4 bg-white dark:bg-gray-800 shadow-sm border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-start mb-4">
                <div className="flex-1">
                    <input
                        type="text"
                        value={experience.company}
                        onChange={(e) => onUpdate('company', e.target.value)}
                        placeholder="Company Name"
                        className="w-full p-2 border rounded mb-2 font-medium text-lg bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    />
                    <input
                        type="text"
                        value={experience.title}
                        onChange={(e) => onUpdate('title', e.target.value)}
                        placeholder="Job Title"
                        className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    />
                </div>
                <button
                    onClick={onDelete}
                    className="p-1 hover:bg-red-100 dark:hover:bg-red-800 hover:text-red-500 text-gray-500 dark:text-gray-300 rounded ml-2"
                >
                    <Trash2 size={18} />
                </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <DateInput
                    label="Start Date"
                    value={experience.startDate}
                    onChange={(value) => onUpdate('startDate', value)}
                    placeholder="MM/YYYY"
                />
                <DateInput
                    label="End Date"
                    value={experience.endDate}
                    onChange={(value) => onUpdate('endDate', value)}
                    placeholder="MM/YYYY or Present"
                />
            </div>

            <div className="grid grid-cols-3 gap-4 mb-4">
                <input
                    type="text"
                    value={experience.location?.city || ''}
                    onChange={(e) => handleLocationUpdate('city', e.target.value)}
                    placeholder="City"
                    className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                />
                <input
                    type="text"
                    value={experience.location?.region || ''}
                    onChange={(e) => handleLocationUpdate('region', e.target.value)}
                    placeholder="State/Region"
                    className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                />
                <input
                    type="text"
                    value={experience.location?.countryCode || ''}
                    onChange={(e) => handleLocationUpdate('countryCode', e.target.value)}
                    placeholder="Country Code"
                    className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                />
            </div>

            <TextEditor
                content={experience.description}
                onChange={(value) => onUpdate('description', value)}
                onAIApply={handleAIEnhance}
            />

            <div className="mt-4 flex justify-end">
                <button
                    onClick={handleAIEnhance}
                    disabled={isEnhancing}
                    className={`px-4 py-2 rounded flex items-center gap-2 ${isEnhancing
                            ? 'bg-purple-200 text-purple-700 dark:bg-purple-900/30 dark:text-purple-300'
                            : 'bg-purple-500 hover:bg-purple-600 text-white'
                        }`}
                >
                    {isEnhancing ? (
                        <Loader2 className="animate-spin" size={18} />
                    ) : (
                        <Sparkles size={18} />
                    )}
                    Enhance with AI based on JD
                </button>
            </div>
        </div>
    );
};

export default WorkExperienceItem;