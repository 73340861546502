import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    FileText,
    Upload,
    AlertCircle,
    Loader2,
    X,
    Info,
    ArrowLeft
} from 'lucide-react';
import supabase from '../../supabaseClient';
import JobDescriptionModal from './JobDescriptionModal';

const BASE_ROLES = [
    "Software Engineer",
    "Senior Software Engineer",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "DevOps Engineer",
    "Data Scientist",
    "Data Analyst",
    "Machine Learning Engineer",
    "Product Manager",
    "Project Manager",
    "UI/UX Designer",
    "Quality Assurance Engineer",
    "Business Analyst",
    "Systems Analyst",
    "Cloud Engineer",
    "Network Engineer",
    "Security Engineer",
    "Technical Writer",
    "Web Developer",
    "Mobile Developer",
    "Game Developer",
    "Data Engineer",
    "DevSecOps Engineer",
    "Research Scientist",
    "Blockchain Developer",
    "AI Engineer",
    "Embedded Systems Engineer",
    "Site Reliability Engineer",
    "Game Designer",
    "IT Support Specialist",
    "Chief Technology Officer (CTO)",
    "Technical Project Manager",
    "Product Designer",
    "Digital Marketing Manager",
    "SEO Specialist",
    "Other (Custom)"
];

const BASE_COMPANIES = [
    "Google",
    "Microsoft",
    "Apple",
    "Amazon",
    "Meta",
    "Netflix",
    "Uber",
    "Airbnb",
    "Twitter",
    "LinkedIn",
    "Oracle",
    "IBM",
    "Intel",
    "NVIDIA",
    "Adobe",
    "Salesforce",
    "Other (Custom)"
];

const ResumeUpload = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [uploadType, setUploadType] = useState('simple');
    const [showJobModal, setShowJobModal] = useState(false);
    const [existingRoles, setExistingRoles] = useState([]);
    const [currentRecordId, setCurrentRecordId] = useState(null);

    useEffect(() => {
        const fetchExistingRoles = async () => {
            try {
                const { data: { user } } = await supabase.auth.getUser();
                const { data: membership } = await supabase
                    .from('agency_members')
                    .select('agency_id')
                    .eq('user_id', user.id)
                    .single();

                if (membership) {
                    const { data: roles } = await supabase
                        .from('open_roles')
                        .select('*')
                        .eq('agency_id', membership.agency_id);
                    setExistingRoles(roles || []);
                }
            } catch (err) {
                console.error('Error fetching roles:', err);
                setError('Failed to fetch existing roles');
            }
        };

        fetchExistingRoles();
    }, []);

    const handleFileSelect = useCallback((file) => {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            setError('File size must be less than 10MB');
            return;
        }

        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (!allowedTypes.includes(file.type)) {
            setError('Only PDF, DOC, and DOCX files are allowed');
            return;
        }

        setSelectedFile(file);
        setError(null);
        // Show job modal immediately
        setShowJobModal(true);
    }, []);

    const handleJobModalSave = async (jobData) => {
        let recordId;
        try {
            setUploadProgress(25);

            const { data: { user } } = await supabase.auth.getUser();
            const { data: membership } = await supabase
                .from('agency_members')
                .select('*, agency:agencies(*)')
                .eq('user_id', user.id)
                .single();

            // Create initial record
            recordId = uuidv4();
            setCurrentRecordId(recordId);

            const initialData = {
                id: recordId,
                agency_id: membership.agency.id,
                uploaded_by: user.id,
                file_name: selectedFile.name,
                file_type: selectedFile.type,
                status: 'uploading',
                created_at: new Date().toISOString()
            };

            await supabase.from('resume_uploads').insert(initialData);
            setUploadProgress(50);

            // Upload file
            const filePath = `agencies/${membership.agency.id}/uploads/${recordId}/${selectedFile.name}`;
            const { error: uploadError } = await supabase.storage
                .from('resume_uploads')
                .upload(filePath, selectedFile);

            if (uploadError) throw uploadError;

            const { data: { publicUrl } } = supabase.storage
                .from('resume_uploads')
                .getPublicUrl(filePath);

            // Update resume record
            await supabase
                .from('resume_uploads')
                .update({
                    status: 'completed',
                    file_url: publicUrl,
                    file_path: filePath,
                    role: jobData.roleTitle,
                    company: jobData.companyName
                })
                .eq('id', recordId);

            setUploadProgress(75);

            // Create job posting
            const { data: role, error: roleError } = await supabase
                .from('open_roles')
                .insert({
                    uuid: uuidv4(),
                    agency_id: membership.agency.id,
                    company_name: jobData.companyName,
                    role_title: jobData.roleTitle,
                    department: jobData.department,
                    location: jobData.location,
                    salary_range: jobData.salaryRange,
                    job_description: jobData.jobDescription,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                })
                .select()
                .single();

            if (roleError) throw roleError;

            // Link resume to job posting
            await supabase
                .from('resume_job_matches')
                .insert({
                    resume_id: recordId,
                    job_id: role.id,
                    status: 'new',
                    created_at: new Date().toISOString()
                });

            setUploadProgress(100);
            navigate('/dashboard', {
                state: { message: 'Resume uploaded and job posting created successfully' }
            });

        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'Failed to process resume and create job posting');

            if (recordId) {
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'failed',
                        error_message: error.message
                    })
                    .eq('id', recordId);
            }
        }
    };

    const renderUploadArea = () => (
        <div
            className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl p-12 
                       bg-gray-50 dark:bg-gray-800/50 transition-all duration-300
                       hover:border-blue-500 dark:hover:border-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20"
            onDrop={(e) => {
                e.preventDefault();
                handleFileSelect(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => e.preventDefault()}
        >
            <input
                type="file"
                id="file-upload"
                className="hidden"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileSelect(e.target.files[0])}
            />
            <div className="flex flex-col items-center justify-center gap-4">
                {selectedFile ? (
                    <>
                        <div className="relative">
                            <FileText className="h-16 w-16 text-blue-600 dark:text-blue-400" />
                            <button
                                onClick={() => setSelectedFile(null)}
                                className="absolute -top-2 -right-2 rounded-full bg-gray-100 dark:bg-gray-700 p-1
                                           hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                            >
                                <X className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                            </button>
                        </div>
                        <div className="text-center">
                            <p className="text-gray-900 dark:text-gray-100 text-lg font-medium">
                                {selectedFile.name}
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                            </p>
                        </div>

                        {/* JobDescriptionModal in place of buttons */}
                        <JobDescriptionModal
                            isVisible={showJobModal}
                            onClose={() => {
                                setShowJobModal(false);
                                setSelectedFile(null);
                            }}
                            onSave={handleJobModalSave}
                            existingRoles={existingRoles}
                        />
                    </>
                ) : (
                    <>
                        <Upload className="h-16 w-16 text-gray-400 dark:text-gray-500" />
                        <div className="text-center space-y-2">
                            <p className="text-gray-600 dark:text-gray-300 text-lg">
                                Drag and drop your resume, or
                            </p>
                            <label
                                htmlFor="file-upload"
                                className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg
                                         hover:bg-blue-700 transition-colors cursor-pointer"
                            >
                                Browse Files
                            </label>
                        </div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            PDF, DOCX, DOC (Max 10MB)
                        </p>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-slate-50 dark:bg-gray-900 p-8">
            <div className="max-w-3xl mx-auto">
                {/* Header */}
                <div className="flex items-center gap-4 mb-8">
                    <button
                        onClick={() => navigate('/dashboard')}
                        className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
                    >
                        <ArrowLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                    </button>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                            Upload Resume
                        </h1>
                        <p className="text-gray-600 dark:text-gray-400">
                            Add a new resume to your collection
                        </p>
                    </div>
                </div>

                {error && (
                    <div className="mb-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-700 
                                  rounded-xl p-4 text-red-800 dark:text-red-200 flex items-center gap-2">
                        <AlertCircle className="h-5 w-5 flex-shrink-0" />
                        <p>{error}</p>
                    </div>
                )}

                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
                    <div className="p-6">
                        {renderUploadArea()}
                    </div>
                </div>

                {/* Upload Progress Dialog */}
                {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
                        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4">
                            <div className="space-y-4">
                                <div className="flex items-center gap-3">
                                    <Loader2 className="h-5 w-5 animate-spin text-blue-600 dark:text-blue-400" />
                                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                                        Processing Resume...
                                    </h3>
                                </div>
                                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                                    <div
                                        className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                                        style={{ width: `${uploadProgress}%` }}
                                    />
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    Creating job posting and uploading resume...
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResumeUpload;


// import React, { useState, useCallback, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import {
//     FileText, Upload, AlertCircle, Loader2, X, Info, ArrowLeft,
//     Forward, FileEdit, Users, ArrowRight, Camera
// } from 'lucide-react';
// import supabase from '../../supabaseClient';

// const JobSiteIcon = ({ site }) => {
//     const icons = {
//         LinkedIn: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                 <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//             </svg>
//         ),
//         Indeed: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#2164F3" />
//                 <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                 <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//             </svg>
//         ),
//         Glassdoor: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                 <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//             </svg>
//         ),
//         Dice: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                 <path d="M10 10h20v20H10z" fill="white" />
//                 <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                 <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                 <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                 <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//             </svg>
//         ),
//         Monster: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#6E45A5" />
//                 <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
//             </svg>
//         ),
//         'Talent.com': (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#1D2C4D" />
//                 <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
//                 <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
//             </svg>
//         ),
//         Greenhouse: (
//             <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                 <rect width="40" height="40" rx="8" fill="#3AB549" />
//                 <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
//                 <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
//             </svg>
//         )
//     };
//     return icons[site] || null;
// };

// export const Card = ({ className = '', children, ...props }) => {
//     return (
//         <div
//             className={`bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm ${className}`}
//             {...props}
//         >
//             {children}
//         </div>
//     );
// };

// export const CardHeader = ({ className = '', children, ...props }) => {
//     return (
//         <div
//             className={`p-6 border-b border-gray-200 dark:border-gray-700 ${className}`}
//             {...props}
//         >
//             {children}
//         </div>
//     );
// };

// export const CardTitle = ({ className = '', children, ...props }) => {
//     return (
//         <h3
//             className={`text-2xl font-bold text-gray-900 dark:text-gray-100 ${className}`}
//             {...props}
//         >
//             {children}
//         </h3>
//     );
// };

// export const CardDescription = ({ className = '', children, ...props }) => {
//     return (
//         <p
//             className={`mt-2 text-sm text-gray-600 dark:text-gray-400 ${className}`}
//             {...props}
//         >
//             {children}
//         </p>
//     );
// };

// export const CardContent = ({ className = '', children, ...props }) => {
//     return (
//         <div
//             className={`p-6 ${className}`}
//             {...props}
//         >
//             {children}
//         </div>
//     );
// };

// export const CardFooter = ({ className = '', children, ...props }) => {
//     return (
//         <div
//             className={`p-6 border-t border-gray-200 dark:border-gray-700 ${className}`}
//             {...props}
//         >
//             {children}
//         </div>
//     );
// };

// const JobDescriptionModal = ({ isVisible, onClose, onSave, existingRoles = [] }) => {
//     const [showSelection, setShowSelection] = useState(true);
//     const [showMainContent, setShowMainContent] = useState(false);
//     const [jobData, setJobData] = useState({
//         companyName: '',
//         roleTitle: '',
//         department: '',
//         location: '',
//         salaryRange: '',
//         jobDescription: ''
//     });
//     const [url, setUrl] = useState('');
//     const [isScraping, setIsScraping] = useState(false);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [error, setError] = useState(null);
//     const [selectedSite, setSelectedSite] = useState(null);
//     const [matchingRoles, setMatchingRoles] = useState([]);

//     const jobSites = [
//         { name: 'LinkedIn', domain: 'linkedin.com' },
//         { name: 'Indeed', domain: 'indeed.com' },
//         { name: 'Glassdoor', domain: 'glassdoor.com' },
//         { name: 'Dice', domain: 'dice.com' },
//         { name: 'Monster', domain: 'monster.com' },
//         { name: 'Talent.com', domain: 'talent.com' },
//         { name: 'Greenhouse', domain: 'greenhouse.io' }
//     ];

//     useEffect(() => {
//         const checkExistingRoles = () => {
//             if (jobData.companyName && jobData.roleTitle) {
//                 const matches = existingRoles.filter(role =>
//                     role.company_name.toLowerCase().includes(jobData.companyName.toLowerCase()) &&
//                     role.role_title.toLowerCase().includes(jobData.roleTitle.toLowerCase())
//                 );
//                 setMatchingRoles(matches);
//             } else {
//                 setMatchingRoles([]);
//             }
//         };

//         checkExistingRoles();
//     }, [jobData.companyName, jobData.roleTitle, existingRoles]);

//     const handleQuickSubmit = async () => {
//         if (!jobData.companyName || !jobData.roleTitle) {
//             setError('Company name and role title are required');
//             return;
//         }

//         setIsSubmitting(true);
//         try {
//             const response = await fetch('https://your-api-endpoint/open-roles', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     uuid: crypto.randomUUID(),
//                     company_name: jobData.companyName,
//                     role_title: jobData.roleTitle,
//                     department: jobData.department,
//                     location: jobData.location,
//                     salary_range: jobData.salaryRange,
//                     job_description: jobData.jobDescription,
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to save job posting');
//             }

//             const data = await response.json();
//             onSave({ ...jobData, uuid: data.uuid });
//             onClose();
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     const handleScrapeJob = async () => {
//         setIsScraping(true);
//         setError(null);
//         try {
//             const scrapeResponse = await fetch('https://your-api-endpoint/scrape-job', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     url,
//                     site: selectedSite?.domain
//                 })
//             });

//             if (!scrapeResponse.ok) {
//                 const errorData = await scrapeResponse.json();
//                 throw new Error(errorData.message || 'Failed to scrape job description');
//             }

//             const scrapeData = await scrapeResponse.json();

//             const parseResponse = await fetch('https://your-api-endpoint/parse-text', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     text: scrapeData.raw_text || scrapeData.description || ''
//                 })
//             });

//             if (!parseResponse.ok) {
//                 const errorData = await parseResponse.json();
//                 throw new Error(errorData.detail || 'Failed to parse job description');
//             }

//             const parsedData = await parseResponse.json();

//             setJobData(prev => ({
//                 ...prev,
//                 companyName: parsedData.company_name || '',
//                 roleTitle: parsedData.role_title || '',
//                 department: parsedData.department || '',
//                 location: parsedData.location || '',
//                 salaryRange: parsedData.salary_range || '',
//                 jobDescription: parsedData.job_description || scrapeData.raw_text || ''
//             }));

//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setIsScraping(false);
//         }
//     };

//     const handleInputChange = (field, value) => {
//         setJobData(prev => ({ ...prev, [field]: value }));
//         setError(null);
//     };

//     const handleOptionSelect = (option) => {
//         setShowSelection(false);
//         setShowMainContent(true);
//         if (option === 'custom') {
//             setSelectedSite(null);
//         }
//     };

//     if (!isVisible) return null;

//     return (
//         <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//             <div className="bg-white rounded-xl shadow-2xl w-full max-w-6xl min-h-[80vh] max-h-[95vh] flex flex-col">
//                 <div className="p-6 border-b flex-shrink-0">
//                     <div className="flex justify-between items-center">
//                         <h2 className="text-2xl font-bold">New Job Opening</h2>
//                         <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
//                     </div>
//                 </div>

//                 <div className="flex-1 overflow-y-auto p-6">
//                     {showSelection ? (
//                         <div className="space-y-8">
//                             <div className="grid grid-cols-2 gap-4">
//                                 <button
//                                     onClick={() => handleOptionSelect('import')}
//                                     className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
//                                 >
//                                     <div className="flex flex-col items-center gap-3">
//                                         <Forward className="w-12 h-12 text-blue-600" />
//                                         <h3 className="text-xl font-semibold">Import from Job Site</h3>
//                                         <p className="text-sm text-gray-500 text-center">
//                                             Import job details from LinkedIn, Indeed, and other platforms
//                                         </p>
//                                     </div>
//                                 </button>

//                                 <button
//                                     onClick={() => handleOptionSelect('custom')}
//                                     className="p-8 rounded-lg border border-gray-200 hover:border-blue-500 hover:bg-blue-50 transition-all"
//                                 >
//                                     <div className="flex flex-col items-center gap-3">
//                                         <FileEdit className="w-12 h-12 text-blue-600" />
//                                         <h3 className="text-xl font-semibold">Custom Entry</h3>
//                                         <p className="text-sm text-gray-500 text-center">
//                                             Create a job posting from scratch
//                                         </p>
//                                     </div>
//                                 </button>
//                             </div>

//                             <div className="flex items-center gap-4">
//                                 <div className="flex-1 border-t border-gray-200"></div>
//                                 <span className="text-sm font-medium text-gray-500">OR</span>
//                                 <div className="flex-1 border-t border-gray-200"></div>
//                             </div>

//                             <div className="border rounded-lg border-gray-200">
//                                 <div className="p-4 border-b border-gray-200 bg-gray-50">
//                                     <h3 className="font-semibold text-gray-700">Existing Open Roles</h3>
//                                 </div>
//                                 <div className="divide-y divide-gray-200 max-h-[300px] overflow-y-auto">
//                                     {existingRoles.map((role) => (
//                                         <button
//                                             key={role.uuid}
//                                             onClick={() => handleOptionSelect('existing', role)}
//                                             className="w-full p-4 hover:bg-gray-50 flex items-center justify-between group"
//                                         >
//                                             <div className="flex-1">
//                                                 <h4 className="font-medium text-gray-900">{role.role_title}</h4>
//                                                 <div className="flex items-center gap-2 text-sm text-gray-500">
//                                                     <span>{role.company_name}</span>
//                                                     {role.location && (
//                                                         <>
//                                                             <span>{role.location}</span>
//                                                         </>
//                                                     )}
//                                                 </div>
//                                             </div>
//                                             <ArrowRight className="w-5 h-5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
//                                         </button>
//                                     ))}
//                                     {existingRoles.length === 0 && (
//                                         <div className="p-4 text-sm text-gray-500 text-center">
//                                             No open roles found
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     ) : showMainContent && (
//                         <div className="space-y-8">
//                             <div className="border-b pb-6">
//                                 <h3 className="text-lg font-semibold mb-4">Import from Job Sites</h3>
//                                 <div className="grid grid-cols-4 gap-4">
//                                     {jobSites.map(site => (
//                                         <button
//                                             key={site.name}
//                                             onClick={() => setSelectedSite(site)}
//                                             className={`p-4 rounded-lg border transition-all ${selectedSite?.name === site.name
//                                                 ? 'border-blue-500 bg-blue-50'
//                                                 : 'border-gray-200 hover:border-gray-300'
//                                                 }`}
//                                         >
//                                             <div className="flex flex-col items-center gap-2">
//                                                 <JobSiteIcon site={site.name} />
//                                                 <span className="text-sm font-medium">{site.name}</span>
//                                             </div>
//                                         </button>
//                                     ))}
//                                 </div>
//                             </div>

//                             <div className="space-y-6">
//                                 {matchingRoles.length > 0 && (
//                                     <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
//                                         <div className="flex items-start gap-3">
//                                             <Users className="w-5 h-5 text-yellow-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-yellow-800">
//                                                     Similar Roles Found
//                                                 </h4>
//                                                 <p className="text-sm text-yellow-700 mt-1">
//                                                     There are {matchingRoles.length} similar open roles:
//                                                 </p>
//                                                 <ul className="mt-2 space-y-1">
//                                                     {matchingRoles.map((role) => (
//                                                         <li key={role.uuid} className="text-sm text-yellow-700">
//                                                             {role.company_name} - {role.role_title}
//                                                         </li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {selectedSite && (
//                                     <div className="space-y-4">
//                                         <div>
//                                             <label className="text-sm font-medium mb-1 block">
//                                                 Job URL from {selectedSite.name}
//                                             </label>
//                                             <div className="flex gap-2">
//                                                 <input
//                                                     type="url"
//                                                     className="flex-1 px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                                     value={url}
//                                                     onChange={(e) => setUrl(e.target.value)}
//                                                     placeholder={`Paste ${selectedSite.name} job URL`}
//                                                 />
//                                                 <button
//                                                     onClick={handleScrapeJob}
//                                                     disabled={!url || isScraping}
//                                                     className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                                                 >
//                                                     {isScraping ? (
//                                                         <>
//                                                             <Loader2 className="w-5 h-5 animate-spin" />
//                                                             <span>Importing...</span>
//                                                         </>
//                                                     ) : (
//                                                         <>
//                                                             <ArrowRight className="w-5 h-5" />
//                                                             <span>Import Job</span>
//                                                         </>
//                                                     )}
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="grid grid-cols-2 gap-6">
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Company Name</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.companyName}
//                                             onChange={(e) => handleInputChange('companyName', e.target.value)}
//                                             placeholder="Enter company name"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Role Title</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.roleTitle}
//                                             onChange={(e) => handleInputChange('roleTitle', e.target.value)}
//                                             placeholder="Enter role title"
//                                         />
//                                     </div>
//                                 </div>

//                                 <div className="grid grid-cols-2 gap-6">
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Department</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.department}
//                                             onChange={(e) => handleInputChange('department', e.target.value)}
//                                             placeholder="Enter department"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="text-sm font-medium mb-1 block">Location</label>
//                                         <input
//                                             type="text"
//                                             className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                             value={jobData.location}
//                                             onChange={(e) => handleInputChange('location', e.target.value)}
//                                             placeholder="Enter location"
//                                         />
//                                     </div>
//                                 </div>

//                                 <div>
//                                     <label className="text-sm font-medium mb-1 block">Salary Range</label>
//                                     <input
//                                         type="text"
//                                         className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500"
//                                         value={jobData.salaryRange}
//                                         onChange={(e) => handleInputChange('salaryRange', e.target.value)}
//                                         placeholder="e.g., $50k - $70k"
//                                     />
//                                 </div>

//                                 <div>
//                                     <label className="text-sm font-medium mb-1 block">Job Description</label>
//                                     <textarea
//                                         className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 h-96"
//                                         value={jobData.jobDescription}
//                                         onChange={(e) => handleInputChange('jobDescription', e.target.value)}
//                                         placeholder="Enter job description"
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 <div className="p-6 border-t flex-shrink-0">
//                     <div className="flex justify-between items-center">
//                         <div>
//                             {error && (
//                                 <div className="text-red-600 text-sm flex items-center gap-2">
//                                     <AlertCircle className="w-4 h-4" />
//                                     {error}
//                                 </div>
//                             )}
//                         </div>

//                         <div className="flex gap-4">
//                             {showMainContent && (
//                                 <button
//                                     onClick={() => {
//                                         setShowSelection(true);
//                                         setShowMainContent(false);
//                                     }}
//                                     className="px-6 py-3 border rounded-lg hover:bg-gray-50"
//                                 >
//                                     Back
//                                 </button>
//                             )}
//                             <button
//                                 onClick={handleQuickSubmit}
//                                 disabled={isSubmitting}
//                                 className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
//                             >
//                                 {isSubmitting ? (
//                                     <>
//                                         <Loader2 className="w-5 h-5 animate-spin" />
//                                         <span>Saving...</span>
//                                     </>
//                                 ) : (
//                                     <span>{showMainContent ? 'Post Job' : 'Continue'}</span>
//                                 )}
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const ResumeUpload = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [error, setError] = useState(null);
//     const [uploadType, setUploadType] = useState('simple');
//     const [step, setStep] = useState('upload');
//     const [showJobModal, setShowJobModal] = useState(false);
//     const [existingRoles, setExistingRoles] = useState([]);
//     const [candidateInfo, setCandidateInfo] = useState({
//         role: '',
//         company: '',
//         notes: ''
//     });

//     useEffect(() => {
//         const fetchExistingRoles = async () => {
//             const { data: { user } } = await supabase.auth.getUser();
//             const { data: membership } = await supabase
//                 .from('agency_members')
//                 .select('agency_id')
//                 .eq('user_id', user.id)
//                 .single();

//             if (membership) {
//                 const { data: roles } = await supabase
//                     .from('open_roles')
//                     .select('*')
//                     .eq('agency_id', membership.agency_id);
//                 setExistingRoles(roles || []);
//             }
//         };

//         fetchExistingRoles();
//     }, []);

//     useEffect(() => {
//         const channel = supabase.channel('upload_status')
//             .on(
//                 'postgres_changes',
//                 {
//                     event: '*',
//                     schema: 'public',
//                     table: 'resume_uploads',
//                 },
//                 (payload) => {
//                     if (payload.new.status === 'completed') {
//                         setUploadProgress(100);
//                         setShowJobModal(true);
//                     } else if (payload.new.status === 'parsing') {
//                         setUploadProgress(75);
//                     } else if (payload.new.status === 'uploading') {
//                         setUploadProgress(50);
//                     }
//                 }
//             )
//             .subscribe();

//         return () => {
//             channel.unsubscribe();
//         };
//     }, []);

//     const createInitialRecord = async (fileDetails) => {
//         const { data: { user } } = await supabase.auth.getUser();
//         const { data: membership } = await supabase
//             .from('agency_members')
//             .select('*, agency:agencies(*)')
//             .eq('user_id', user.id)
//             .single();

//         const recordId = uuidv4();
//         const initialData = {
//             id: recordId,
//             agency_id: membership.agency.id,
//             uploaded_by: user.id,
//             file_name: fileDetails.name,
//             file_type: fileDetails.type,
//             candidate_name: fileDetails.name.split('.')[0],
//             status: uploadType === 'parse' ? 'parsing' : 'uploading',
//             parse_requested: uploadType === 'parse',
//             parse_status: uploadType === 'parse' ? 'pending' : null,
//             created_at: new Date().toISOString()
//         };

//         await supabase.from('resume_uploads').insert(initialData);
//         return { recordId, membership };
//     };

//     const handleFileSelect = useCallback((file) => {
//         if (!file) return;

//         if (file.size > 10 * 1024 * 1024) {
//             setError('File size must be less than 10MB');
//             return;
//         }

//         const allowedTypes = [
//             'application/pdf',
//             'application/msword',
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//         ];

//         if (!allowedTypes.includes(file.type)) {
//             setError('Only PDF, DOC, and DOCX files are allowed');
//             return;
//         }

//         setSelectedFile(file);
//         setError(null);
//         setStep('details');
//     }, []);

//     const handleUpload = async () => {
//         let recordId;
//         setUploadProgress(25);

//         try {
//             const { recordId: newRecordId, membership } = await createInitialRecord(selectedFile);
//             recordId = newRecordId;

//             setUploadProgress(50);

//             const filePath = `agencies/${membership.agency_id}/uploads/${recordId}/${selectedFile.name}`;

//             const { error: uploadError } = await supabase.storage
//                 .from('resume_uploads')
//                 .upload(filePath, selectedFile);

//             if (uploadError) throw uploadError;

//             const { data: { publicUrl } } = supabase.storage
//                 .from('resume_uploads')
//                 .getPublicUrl(filePath);

//             await supabase
//                 .from('resume_uploads')
//                 .update({
//                     status: 'completed',
//                     file_url: publicUrl,
//                     file_path: filePath
//                 })
//                 .eq('id', recordId);

//             setUploadProgress(100);
//             setShowJobModal(true);

//         } catch (err) {
//             console.error('Upload error:', err);
//             setError(err.message || 'Failed to upload resume');
//             setUploadProgress(0);

//             if (recordId) {
//                 await supabase
//                     .from('resume_uploads')
//                     .update({
//                         status: 'failed',
//                         error_message: err.message
//                     })
//                     .eq('id', recordId);
//             }
//         }
//     };
//     const handleJobModalSave = async (jobData) => {
//         try {
//             const { data: { user } } = await supabase.auth.getUser();
//             const { data: membership } = await supabase
//                 .from('agency_members')
//                 .select('*, agency:agencies(*)')
//                 .eq('user_id', user.id)
//                 .single();

//             // Create job posting
//             const { data: role, error: roleError } = await supabase
//                 .from('open_roles')
//                 .insert({
//                     uuid: uuidv4(),
//                     agency_id: membership.agency.id,
//                     company_name: jobData.companyName,
//                     role_title: jobData.roleTitle,
//                     department: jobData.department,
//                     location: jobData.location,
//                     salary_range: jobData.salaryRange,
//                     job_description: jobData.jobDescription,
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//                 .select()
//                 .single();

//             if (roleError) throw roleError;

//             // Link resume to job posting if we have a selected file
//             if (selectedFile?.uploadId && role) {
//                 await supabase
//                     .from('resume_job_matches')
//                     .insert({
//                         resume_id: selectedFile.uploadId,
//                         job_id: role.id,
//                         status: 'new',
//                         created_at: new Date().toISOString()
//                     });
//             }

//             navigate('/dashboard', {
//                 state: { message: 'Resume uploaded and job posting created successfully' }
//             });
//         } catch (err) {
//             setError(err.message);
//         }
//     };

//     const renderUploadArea = () => (
//         <div
//             className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl p-12 
//                        bg-gray-50 dark:bg-gray-800/50 transition-all duration-300
//                        hover:border-blue-500 dark:hover:border-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20"
//             onDrop={(e) => {
//                 e.preventDefault();
//                 handleFileSelect(e.dataTransfer.files[0]);
//             }}
//             onDragOver={(e) => e.preventDefault()}
//         >
//             <input
//                 type="file"
//                 id="file-upload"
//                 className="hidden"
//                 accept=".pdf,.doc,.docx"
//                 onChange={(e) => handleFileSelect(e.target.files[0])}
//             />
//             <div className="flex flex-col items-center justify-center gap-4">
//                 {selectedFile ? (
//                     <>
//                         <div className="relative">
//                             <FileText className="h-16 w-16 text-blue-600 dark:text-blue-400" />
//                             <button
//                                 onClick={() => setSelectedFile(null)}
//                                 className="absolute -top-2 -right-2 rounded-full bg-gray-100 dark:bg-gray-700 p-1
//                                            hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
//                             >
//                                 <X className="h-4 w-4 text-gray-600 dark:text-gray-400" />
//                             </button>
//                         </div>
//                         <div className="text-center">
//                             <p className="text-gray-900 dark:text-gray-100 text-lg font-medium">
//                                 {selectedFile.name}
//                             </p>
//                             <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
//                                 {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
//                             </p>
//                         </div>
//                     </>
//                 ) : (
//                     <>
//                         <Upload className="h-16 w-16 text-gray-400 dark:text-gray-500" />
//                         <div className="text-center space-y-2">
//                             <p className="text-gray-600 dark:text-gray-300 text-lg">
//                                 Drag and drop your resume, or
//                             </p>
//                             <label
//                                 htmlFor="file-upload"
//                                 className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg
//                                          hover:bg-blue-700 transition-colors cursor-pointer"
//                             >
//                                 Browse Files
//                             </label>
//                         </div>
//                         <p className="text-sm text-gray-500 dark:text-gray-400">
//                             PDF, DOCX, DOC (Max 10MB)
//                         </p>
//                     </>
//                 )}
//             </div>
//         </div>
//     );

//     return (
//         <div className="min-h-screen bg-slate-50 dark:bg-gray-900 p-8">
//             <div className="max-w-3xl mx-auto">
//                 {/* Header */}
//                 <div className="flex items-center gap-4 mb-8">
//                     <button
//                         onClick={() => navigate('/dashboard')}
//                         className="p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
//                     >
//                         <ArrowLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
//                     </button>
//                     <div>
//                         <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
//                             Upload Resume
//                         </h1>
//                         <p className="text-gray-600 dark:text-gray-400">
//                             Add a new resume to your collection
//                         </p>
//                     </div>
//                 </div>

//                 {error && (
//                     <div className="mb-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-700 
//                                   rounded-xl p-4 text-red-800 dark:text-red-200 flex items-center gap-2">
//                         <AlertCircle className="h-5 w-5 flex-shrink-0" />
//                         <p>{error}</p>
//                     </div>
//                 )}

//                 <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700">
//                     <div className="p-6 border-b border-gray-200 dark:border-gray-700">
//                         <div className="flex gap-4">
//                             <button
//                                 type="button"
//                                 onClick={() => setUploadType('simple')}
//                                 className={`flex-1 px-6 py-3 rounded-lg font-medium transition-all
//                                           ${uploadType === 'simple'
//                                         ? 'bg-blue-600 text-white shadow-sm'
//                                         : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
//                                     }`}
//                             >
//                                 Basic Upload
//                             </button>
//                             <button
//                                 type="button"
//                                 onClick={() => setUploadType('parse')}
//                                 className={`flex-1 px-6 py-3 rounded-lg font-medium transition-all
//                                           ${uploadType === 'parse'
//                                         ? 'bg-blue-600 text-white shadow-sm'
//                                         : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
//                                     }`}
//                             >
//                                 AI Parse
//                             </button>
//                         </div>
//                     </div>

//                     <div className="p-6">
//                         {step === 'upload' ? (
//                             renderUploadArea()
//                         ) : (
//                             <div className="space-y-6">
//                                 <div className="grid grid-cols-2 gap-6">
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                                             Target Role
//                                         </label>
//                                         <input
//                                             type="text"
//                                             value={candidateInfo.role}
//                                             onChange={(e) => setCandidateInfo(prev => ({ ...prev, role: e.target.value }))}
//                                             className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600"
//                                             placeholder="Enter target role"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                                             Target Company
//                                         </label>
//                                         <input
//                                             type="text"
//                                             value={candidateInfo.company}
//                                             onChange={(e) => setCandidateInfo(prev => ({ ...prev, company: e.target.value }))}
//                                             className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600"
//                                             placeholder="Enter target company"
//                                         />
//                                     </div>
//                                 </div>

//                                 <div>
//                                     <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
//                                         Notes (Optional)
//                                     </label>
//                                     <textarea
//                                         value={candidateInfo.notes}
//                                         onChange={(e) => setCandidateInfo(prev => ({ ...prev, notes: e.target.value }))}
//                                         className="w-full p-3 rounded-lg border border-gray-300 dark:border-gray-600 h-32"
//                                         placeholder="Add any additional notes about this resume..."
//                                     />
//                                 </div>

//                                 <div className="flex justify-between items-center">
//                                     <button
//                                         onClick={() => setStep('upload')}
//                                         className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
//                                     >
//                                         Back to Upload
//                                     </button>
//                                     <button
//                                         onClick={handleUpload}
//                                         className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700
//                                                  transition-colors flex items-center gap-2"
//                                     >
//                                         {uploadType === 'parse' ? 'Upload & Parse' : 'Upload Resume'}
//                                     </button>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>

//                 {uploadType === 'parse' && (
//                     <div className="mt-6 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 
//                                   dark:border-blue-700 rounded-xl p-6">
//                         <div className="flex items-start gap-3">
//                             <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 flex-shrink-0 mt-1" />
//                             <div>
//                                 <h3 className="text-blue-900 dark:text-blue-200 font-medium">
//                                     About AI Parse
//                                 </h3>
//                                 <div className="mt-2 space-y-3 text-sm text-blue-800 dark:text-blue-300">
//                                     <p>
//                                         Our AI will automatically analyze your resume and extract:
//                                     </p>
//                                     <ul className="space-y-2 ml-4">
//                                         <li className="flex items-center gap-2">
//                                             <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
//                                             Contact details and personal information
//                                         </li>
//                                         <li className="flex items-center gap-2">
//                                             <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
//                                             Work experience and employment history
//                                         </li>
//                                         <li className="flex items-center gap-2">
//                                             <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
//                                             Education background and qualifications
//                                         </li>
//                                         <li className="flex items-center gap-2">
//                                             <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
//                                             Skills, certifications, and achievements
//                                         </li>
//                                         <li className="flex items-center gap-2">
//                                             <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
//                                             Projects and volunteer experience
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 {/* Upload Progress Dialog */}
//                 {uploadProgress > 0 && uploadProgress < 100 && (
//                     <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
//                         <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4">
//                             <div className="space-y-4">
//                                 <div className="flex items-center gap-3">
//                                     <Loader2 className="h-5 w-5 animate-spin text-blue-600 dark:text-blue-400" />
//                                     <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
//                                         {uploadType === 'parse' ? 'Uploading & Parsing...' : 'Uploading...'}
//                                     </h3>
//                                 </div>
//                                 <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
//                                     <div
//                                         className="bg-blue-600 h-2 rounded-full transition-all duration-300"
//                                         style={{ width: `${uploadProgress}%` }}
//                                     />
//                                 </div>
//                                 <p className="text-sm text-gray-600 dark:text-gray-400">
//                                     {uploadType === 'parse'
//                                         ? 'Your resume is being processed by our AI...'
//                                         : 'Uploading your resume...'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 {/* Job Description Modal */}
//                 <JobDescriptionModal
//                     isVisible={showJobModal}
//                     onClose={() => {
//                         setShowJobModal(false);
//                         navigate('/dashboard');
//                     }}
//                     onSave={handleJobModalSave}
//                     existingRoles={existingRoles}
//                 />
//             </div>
//         </div>
//     );
// };

// export default ResumeUpload;