import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import DateConverter from './DateConverter';

const DATE_FORMATS = [
    {
        label: 'Short Date',
        value: 'short',
        example: 'Mar 2024'
    },
    {
        label: 'Long Date',
        value: 'long',
        example: 'March 2024'
    }
];

const DateInput = ({ value, onChange, label, placeholder, allowPresent = true }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateFormat, setDateFormat] = useState(DATE_FORMATS[0].value);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);

        if (DateConverter.isValidDate(newValue)) {
            const formattedDate = DateConverter.formatDate(newValue, dateFormat);
            onChange(formattedDate);
        } else {
            onChange(newValue);
        }
    };

    const handleFormatChange = (newFormat) => {
        setDateFormat(newFormat);
        if (DateConverter.isValidDate(inputValue)) {
            const formattedDate = DateConverter.formatDate(inputValue, newFormat);
            setInputValue(formattedDate);
            onChange(formattedDate);
        }
    };

    return (
        <div className="w-full">
            <label className="block text-sm text-gray-600 mb-1">{label}</label>
            <div className="relative">
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={`${placeholder} (e.g., ${DATE_FORMATS.find(f => f.value === dateFormat)?.example})${allowPresent ? ' or "Present"' : ''}`}
                    className="w-full p-2 border rounded pr-32 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                />
                <div className="absolute right-2 top-2 flex items-center gap-2">
                    <select
                        className="text-sm border rounded bg-white py-1 px-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                        value={dateFormat}
                        onChange={(e) => handleFormatChange(e.target.value)}
                    >
                        {DATE_FORMATS.map(format => (
                            <option key={format.value} value={format.value}>
                                {format.label}
                            </option>
                        ))}
                    </select>
                    <Calendar
                        className="text-gray-400 cursor-pointer hover:text-blue-500"
                        size={20}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                    />
                </div>
            </div>
            {inputValue && !DateConverter.isValidDate(inputValue) && (
                <p className="text-xs text-red-500 mt-1">
                    Please enter a valid date (e.g., ${DATE_FORMATS.find(f => f.value === dateFormat)?.example})
                    {allowPresent ? ' or "Present"' : ''}
                </p>
            )}
        </div>
    );
};

export default DateInput;