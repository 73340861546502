import React from 'react';
import { Trash2, X } from 'lucide-react';
import DateInput from './DateInput';
import TextEditor from './TextEditor';

const ProjectItem = ({ project, onUpdate, onDelete, handleAIApply }) => {
    return (
        <div className="border rounded-lg p-4 mb-4 bg-white dark:bg-gray-800 shadow-sm border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-start mb-4">
                <div className="flex-1">
                    <input
                        type="text"
                        value={project.name}
                        onChange={(e) => onUpdate('name', e.target.value)}
                        placeholder="Project Name"
                        className="w-full p-2 border rounded mb-2 font-medium text-lg bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    />
                    <div className="flex gap-2 mb-4">
                        <input
                            type="text"
                            value={project.url}
                            onChange={(e) => onUpdate('url', e.target.value)}
                            placeholder="Project URL"
                            className="flex-1 p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        />
                        <input
                            type="text"
                            value={project.githubUrl}
                            onChange={(e) => onUpdate('githubUrl', e.target.value)}
                            placeholder="GitHub URL"
                            className="flex-1 p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        />
                    </div>
                </div>
                <button
                    onClick={onDelete}
                    className="p-1 hover:bg-red-100 dark:hover:bg-red-800 hover:text-red-500 text-gray-500 dark:text-gray-300 rounded ml-2"
                >
                    <Trash2 size={18} />
                </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <DateInput
                    label="Start Date"
                    value={project.startDate}
                    onChange={(value) => onUpdate('startDate', value)}
                    placeholder="MM/YYYY"
                />
                <DateInput
                    label="End Date"
                    value={project.endDate}
                    onChange={(value) => onUpdate('endDate', value)}
                    placeholder="MM/YYYY or Ongoing"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">Technologies Used</label>
                <div className="flex flex-wrap gap-2">
                    {project.technologies.map((tech, index) => (
                        <div key={index} className="flex items-center gap-1 bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">
                            <span className="text-gray-900 dark:text-gray-100">{tech}</span>
                            <button
                                onClick={() => {
                                    const newTech = [...project.technologies];
                                    newTech.splice(index, 1);
                                    onUpdate('technologies', newTech);
                                }}
                                className="text-gray-500 dark:text-gray-300 hover:text-red-500"
                            >
                                <X size={14} />
                            </button>
                        </div>
                    ))}
                    <input
                        type="text"
                        placeholder="Add technology"
                        className="px-2 py-1 border rounded flex-grow min-w-[150px] bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && e.target.value.trim()) {
                                onUpdate('technologies', [...project.technologies, e.target.value.trim()]);
                                e.target.value = '';
                            }
                        }}
                    />
                </div>
            </div>

            <TextEditor
                content={project.description}
                onChange={(value) => onUpdate('description', value)}
                onAIApply={(preset) => handleAIApply(
                    project.description,
                    preset,
                    'project',
                    project.id,
                    (updatedText) => {
                        onUpdate('description', updatedText);
                    }
                )}
            />
        </div>
    );
};

export default ProjectItem;
