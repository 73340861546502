import { generateCompleteTheme } from './ThemeGenerator';

export const agencyConfigurations = {
    intelgant: {
        agencyName: 'Intelgant',
        primaryColor: '#4F46E5',
        secondaryColor: '#7C3AED',
        logoPath: '/assets/logos/intelgant.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    blueCrossBlueShield: {
        agencyName: 'Blue Cross Blue Shield',
        primaryColor: '#2563EB',
        secondaryColor: '#0EA5E9',
        logoPath: '/assets/logos/bcbs.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    humana: {
        agencyName: 'Humana',
        primaryColor: '#059669',
        secondaryColor: '#16A34A',
        logoPath: '/assets/logos/humana.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    aetna: {
        agencyName: 'Aetna',
        primaryColor: '#D4001A',
        secondaryColor: '#000000',
        logoPath: '/assets/logos/aetna.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    unitedHealthcare: {
        agencyName: 'UnitedHealthcare',
        primaryColor: '#FF6F1E',
        secondaryColor: '#124E8C',
        logoPath: '/assets/logos/uhc.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    },

    kaiser: {
        agencyName: 'Kaiser Permanente',
        primaryColor: '#0073C6',
        secondaryColor: '#84329B',
        logoPath: '/assets/logos/kaiser.svg',
        fontFamily: 'Inter, system-ui, sans-serif'
    }
};

// Generate complete themes for all agencies
export const agencyThemes = Object.keys(agencyConfigurations).reduce((themes, agencyKey) => {
    themes[agencyKey] = generateCompleteTheme(agencyConfigurations[agencyKey]);
    return themes;
}, {});

// Function to get a specific agency's theme
export function getAgencyTheme(agencyKey) {
    return agencyThemes[agencyKey] || agencyThemes.intelgant;
}

export default {
    agencyConfigurations,
    agencyThemes,
    getAgencyTheme
};