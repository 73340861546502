import React from 'react';
import { Trash2 } from 'lucide-react';
import DateInput from './DateInput';
import TextEditor from './TextEditor';

const EducationItem = ({ education, onUpdate, onDelete, handleAIApply }) => {
    return (
        <div className="border rounded-lg p-4 mb-4 bg-white dark:bg-gray-800 shadow-sm border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-start mb-4">
                <div className="flex-1">
                    <input
                        type="text"
                        value={education.institution}
                        onChange={(e) => onUpdate('institution', e.target.value)}
                        placeholder="Institution Name"
                        className="w-full p-2 border rounded mb-2 font-medium text-lg bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    />
                    <input
                        type="text"
                        value={education.degree}
                        onChange={(e) => onUpdate('degree', e.target.value)}
                        placeholder="Degree"
                        className="w-full p-2 border rounded bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    />
                </div>
                <button
                    onClick={onDelete}
                    className="p-1 hover:bg-red-100 dark:hover:bg-red-800 hover:text-red-500 text-gray-500 dark:text-gray-300 rounded ml-2"
                >
                    <Trash2 size={18} />
                </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <DateInput
                    label="Start Date"
                    value={education.startDate}
                    onChange={(value) => onUpdate('startDate', value)}
                    placeholder="MM/YYYY"
                />
                <DateInput
                    label="End Date"
                    value={education.endDate}
                    onChange={(value) => onUpdate('endDate', value)}
                    placeholder="MM/YYYY or Expected"
                />
            </div>

            <input
                type="text"
                value={education.location}
                onChange={(e) => onUpdate('location', e.target.value)}
                placeholder="Location"
                className="w-full p-2 border rounded mb-4 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
            />

            <input
                type="text"
                value={education.gpa}
                onChange={(e) => onUpdate('gpa', e.target.value)}
                placeholder="GPA (optional)"
                className="w-full p-2 border rounded mb-4 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
            />

            <TextEditor
                content={education.description}
                onChange={(value) => onUpdate('description', value)}
                onAIApply={(preset) => handleAIApply(
                    education.description,
                    preset,
                    'education',
                    education.id,
                    (updatedText) => {
                        onUpdate('description', updatedText);
                    }
                )}
            />
        </div>
    );
};

export default EducationItem;
