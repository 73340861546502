import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import supabase from './supabaseClient';
import SideBar from './Components/SideBar';
import LandingPage from './Pages/LandingPage';
import Dashboard from './Pages/Recruiter/Dashboard';
import ResumeUpload from './Pages/Recruiter/ResumeUpload';
import SettingsDashboard from './Pages/Recruiter/Settings';
import ResumeEditor from './Pages/Recruiter/ResumeEditor';
import RecruiterOnboarding from './Pages/Recruiter/RecruiterOnboarding';

const SignOutButton = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('has_agency');
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <button
      onClick={handleSignOut}
      className="px-4 py-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
    >
      Sign Out
    </button>
  );
};

// inject({
//   mode: process.env.NODE_ENV === "development" ? "development" : "production",
//   debug: process.env.NODE_ENV === "development",
//   beforeSend: (event) => {
//     // Exclude any events with '/private' in the URL
//     if (event.url.includes("/localhost")) {
//       return null;
//     }
//     return event;
//   }
// });

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();

        if (!session?.user) {
          navigate('/', { replace: true });
          return;
        }

        // Check for agency membership
        const { data: membership } = await supabase
          .from('agency_members')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        const currentPath = window.location.pathname;

        if (!membership && currentPath !== '/onboarding') {
          // No agency membership and not on onboarding - redirect to onboarding
          navigate('/onboarding', { replace: true });
          return;
        }

        if (membership && currentPath === '/onboarding') {
          // Has agency membership but on onboarding - redirect to dashboard
          navigate('/dashboard', { replace: true });
          return;
        }

        // Set user for valid cases
        setUser(session.user);
      } catch (error) {
        console.error('Access check error:', error);
        navigate('/', { replace: true });
      } finally {
        setLoading(false);
      }
    };

    checkAccess();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        navigate('/', { replace: true });
      } else {
        checkAccess();
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-300">Loading...</p>
        </div>
      </div>
    );
  }

  if (!user) return null;

  return children;
};

function App() {
  const [themeState, setThemeState] = useState('light');
  const [user, setUser] = useState(null);
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    if (themeState === 'dark' || (themeState === 'system' && prefersDark)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [themeState, prefersDark]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUser(session?.user || null);
      } catch (error) {
        console.error('Session check error:', error);
        setUser(null);
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const DashboardLayout = () => {
    const navigate = useNavigate();
    const [activeView, setActiveView] = useState('dashboard');
    const [userData, setUserData] = useState(null);
    const [agencyData, setAgencyData] = useState(null);

    useEffect(() => {
      const fetchUserAndAgencyData = async () => {
        try {
          const { data: { user } } = await supabase.auth.getUser();

          if (!user) return;

          // Get user's agency membership and agency details
          const { data: membership, error: membershipError } = await supabase
            .from('agency_members')
            .select(`
                        *,
                        agency:agencies(*)
                    `)
            .eq('user_id', user.id)
            .single();

          if (membershipError) throw membershipError;

          // Set user data
          setUserData({
            id: user.id,
            email: user.email,
            first_name: membership.first_name,
            last_name: membership.last_name,
            role: membership.role
          });

          // Set agency data
          setAgencyData({
            id: membership.agency.id,
            name: membership.agency.name
          });

        } catch (error) {
          console.error('Error fetching user and agency data:', error);
        }
      };

      fetchUserAndAgencyData();
    }, []);

    const handleViewChange = (view) => {
      setActiveView(view);
      switch (view) {
        case 'dashboard':
          navigate('/dashboard');
          break;
        case 'resume-upload':
          navigate('/dashboard/upload');
          break;
        case 'resume-editor':
          navigate('/dashboard/editor');
          break;
        case 'settings':
        case 'organization':
        case 'billing':
        case 'team':
        case 'security':
        case 'api':
          navigate('/dashboard/settings');
          break;
        default:
          navigate('/dashboard');
      }
    };

    return (
      <div className="flex min-h-screen bg-gray-50 dark:bg-gray-900 text-black dark:text-white">
        <SideBar
          activeView={activeView}
          onViewChange={handleViewChange}
          userData={userData}
          agencyData={agencyData}
          themeState={themeState}
          onThemeChange={setThemeState}
        />
        <main className="flex-1 overflow-auto">
          <div className="p-4 flex justify-end">
            <SignOutButton />
          </div>
          <Routes>
            <Route path="/" element={<Dashboard onTabChange={handleViewChange} />} />
            <Route path="/upload" element={<ResumeUpload />} />
            <Route path="/editor" element={<ResumeEditor />} />
            <Route path="/settings/*" element={<SettingsDashboard />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </main>
      </div>
    );
  };

  return (
    <BrowserRouter>
      <ThemeProvider initialAgency="resumely">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute>
                <RecruiterOnboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;