import React, { useState, useEffect } from 'react';
import { Mail, ArrowRight, RefreshCw, CheckCircle2, Loader2 } from 'lucide-react';

const EmailVerificationModal = ({ isOpen, onClose, email, onResendEmail }) => {
    const [resending, setResending] = useState(false);
    const [justResent, setJustResent] = useState(false);

    // Clear the "just resent" status after 3 seconds
    useEffect(() => {
        let timer;
        if (justResent) {
            timer = setTimeout(() => {
                setJustResent(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [justResent]);

    const handleResend = async () => {
        setResending(true);
        await onResendEmail();
        setResending(false);
        setJustResent(true);
    };

    if (!isOpen) return null;

    // Get email provider URL
    const getEmailProviderUrl = () => {
        const domain = email.split('@')[1].toLowerCase();
        const providers = {
            'gmail.com': 'https://gmail.com',
            'outlook.com': 'https://outlook.com',
            'hotmail.com': 'https://outlook.live.com',
            'yahoo.com': 'https://mail.yahoo.com',
        };
        return providers[domain] || `https://${domain}`;
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="verification-modal" role="dialog" aria-modal="true">
            <div className="flex min-h-screen items-center justify-center p-4">
                <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" aria-hidden="true" onClick={onClose} />

                <div className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-8 shadow-xl transition-all">
                    {/* Loading overlay */}
                    {resending && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white/80">
                            <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
                        </div>
                    )}

                    {/* Close button */}
                    <button
                        onClick={onClose}
                        className="absolute right-4 top-4 rounded-lg p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
                    >
                        <span className="sr-only">Close</span>
                        ✕
                    </button>

                    {/* Icon */}
                    <div className="mb-6">
                        <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-blue-100">
                            <Mail className="h-8 w-8 text-blue-600" strokeWidth={1.5} />
                        </div>
                    </div>

                    {/* Content */}
                    <div className="text-center">
                        <h2 className="text-2xl font-bold text-gray-900">Check your email</h2>
                        <p className="mt-3 text-sm text-gray-500">
                            We've sent a verification link to{' '}
                            <span className="font-medium text-gray-900">{email}</span>
                        </p>
                    </div>

                    {/* Action Buttons */}
                    <div className="mt-8 space-y-4">
                        <a
                            href={getEmailProviderUrl()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex w-full items-center justify-center space-x-2 rounded-lg bg-blue-600 px-4 py-3 text-sm font-semibold text-white hover:bg-blue-700"
                        >
                            <span>Open email provider</span>
                            <ArrowRight className="h-4 w-4" />
                        </a>

                        <button
                            onClick={handleResend}
                            disabled={resending || justResent}
                            className="flex w-full items-center justify-center space-x-2 rounded-lg border border-gray-300 px-4 py-3 text-sm font-semibold text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                        >
                            <RefreshCw className={`h-4 w-4 ${resending ? 'animate-spin' : ''}`} />
                            <span>
                                {justResent
                                    ? 'Email resent!'
                                    : resending
                                        ? 'Sending...'
                                        : 'Resend verification email'}
                            </span>
                        </button>
                    </div>

                    {/* Instructions */}
                    <div className="mt-8 space-y-4 rounded-lg bg-gray-50 p-4">
                        <h3 className="flex items-center space-x-2 text-sm font-medium text-gray-900">
                            <CheckCircle2 className="h-5 w-5 text-green-500" />
                            <span>Next steps:</span>
                        </h3>
                        <ol className="ml-5 list-decimal space-y-2 text-sm text-gray-500">
                            <li>Check your email inbox for the verification link</li>
                            <li>Click the link to verify your email address</li>
                            <li>Return here to continue setting up your account</li>
                        </ol>
                    </div>

                    {/* Help text */}
                    <p className="mt-6 text-center text-sm text-gray-500">
                        Didn't receive the email? Check your spam folder first, then try{' '}
                        <button
                            onClick={handleResend}
                            disabled={resending || justResent}
                            className="font-medium text-blue-600 hover:text-blue-500 disabled:opacity-50"
                        >
                            resending the verification email
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmailVerificationModal;